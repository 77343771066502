import { styled } from 'styled-components';

export const DivUserPanel = styled.div`
    
    display: flex;
    flex-direction: row;
    padding: 10px;
    background-color: ${props => props.theme.colors.background};
    min-height: calc(100vh - 110px);

    .inputs-area{
        width: 100%;
    }

    @media screen and (max-width: 600px) {

    }
    
`

/*
.inputs-area{
    display: none;
}
*/