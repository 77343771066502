import apiAuth from '../utils/apiAuth';

async function dashboardData(){
    try{
        const api = apiAuth();
        const response = await api.get('/dashboards/generate-dashboard-pcds');
        return response.data;
    } catch(error){
        throw error.response.data.message;
    }
}

const DashboardService = {
    dashboardData
};

export default DashboardService;