// Styles
import { DivLoading } from './styles';

export function Loading() {

    return(
        <DivLoading>
            <div className="spinner-border text-primary" role="status"></div>
        </DivLoading>
    )

}
