import { styled } from 'styled-components';

export const DivInstitutions = styled.div`

    border: 1px solid #D8D8D8;
    border-radius: 10px;
    height: fit-content;
    margin-bottom: 20px;

    @media screen and (max-width: 995px) {

        max-width: none;
        margin: 0 0 25px 0;

        position: static;
    }

    @media screen and (max-width: 600px) {
        margin-top: 10px;
    }
`;

export const DivHeader = styled.div`

  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px 10px 0 0;
  background-color: ${props => props.theme.colors.senary};
  color: white;
  font-size: 1.1em;
  font-weight: 700;
  padding: 10px 15px;
  min-width: 250px;

  i {
   margin-right: 10px;
  }

  @media screen and (max-width: 600px) {

      font-size: 1em;
      font-weight: 700;

  }
`;

export const DivBody = styled.div`

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    .item-in-list {
        list-style-type: none;
        border-bottom: 2px solid #D8D8D8;
        font-weight: 500;
        height: fit-content;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0px;
        padding: 10px 0px;
        padding-left: 15px;
        width: 100%;
    }

    .list .item-in-list:last-of-type {
        border-bottom: none;
    }

    .num-in-list {
        margin-right: 15px;
        font-weight: 800;
    }


    .img-logo {
        width: 35px;
        height: 35px;
        object-fit: cover;
        border-radius: 100%;
        border: 1px solid #D8D8D8;
        margin: 0 10px 5px 0;
    }

    .name {
        max-width: calc(100% - 50px); /* ajuste conforme necessário */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0px;
        padding: 0px;
    }

    @media screen and (max-width: 995px) {
        
        .item-in-list {
            justify-content: flex-start;
            padding-left: 20px;
        }

        max-width: none;
    }

`
