import { createContext, useState } from "react";
import { useAuth } from "../hook/useAuth";

export const AuthContext = createContext();

export const AuthContextProvider = ({children}) => {

    const { auth, loading } = useAuth();

    return (
        <AuthContext.Provider value={{ auth, loading }}>
            {children}
        </AuthContext.Provider>
    );
};
