import { styled } from 'styled-components';

export const Title = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 25px 20px 25px;

    i {
        font-size: 22px;
        color: #000083;
        margin-right: 30px;
        cursor: pointer;
    }

    h3 {
        font-size: 1.5em;
        color:  ${props => props.theme.colors.primary};
        font-weight: 800;
        margin: 0px;
    }

`

export const DivAccessibility = styled.div`

    border: 1px solid  ${props => props.theme.colors.borders};
    border-radius: 25px;
    margin: 0 25px;
    padding: 25px;

    .input-select-custom{
        margin: 25px 0;
    }

    .btn-custom {
        margin-top: 20px;
        width: 250px;
    }

    @media screen and (max-width: 600px) {

        border: none;
        padding: 0px;
        margin: 10px;

        form div{
            display: flex;
            margin-bottom: 10px;
        }

        .btn-custom {
            width: 100%;
        }

    }

`