import { useState, useRef, useEffect } from 'react';

// Styles
import { DivRegister } from './styles';

// Components
import { ShowStep } from '../../components/ShowStep'
import { Button } from '../Button';

// Inputs
import { Input, InputSelect, InputMask, WarningInput } from '../Input';

// Utils
import { isObjectComplete } from '../../utils/validationFunction';

// Services
import AddressService from '../../services/AddressService';

export function RegisterAddressPcd({ handleNextStep, formData }) {

  const [cep, setCep] = useState(formData.cep ? formData.cep : '');
  const [country, setCountry] = useState(formData.country ? formData.country : 'BR');
  const [state, setState] = useState(formData.state ? formData.state : 'SC');
  const [city, setCity] = useState(formData.city ? formData.city : 'Jaraguá do Sul');
  const [neighborhood, setNeighborhood] = useState(formData.neighborhood ? formData.neighborhood : '');
  const [street, setStreet] = useState(formData.street ? formData.street : '');
  const [streetNumber, setStreetNumber] = useState(formData.streetNumber ? formData.streetNumber : '');
  const [streetComplement, setStreetComplement] = useState(formData.streetComplement ? formData.streetComplement : '');

  const [isCepFilled, setIsCepFilled] = useState(false);
  const [isCountryFilled, setIsCountryFilled] = useState(false);
  const [isStateFilled, setIsStateFilled] = useState(false);
  const [isCityFilled, setIsCityFilled] = useState(false);
  const [isNeighborhoodFilled, setIsNeighborhoodFilled] = useState(false);
  const [isStreetFilled, setIsStreetFilled] = useState(false);
  const [isStreetNumberFilled, setIsStreetNumberFilled] = useState(false);
  const [isStreetComplementFilled, setIsStreetComplementFilled] = useState(false);

  const cepRef = useRef(null);
  const countryRef = useRef(null);
  const stateRef = useRef(null);
  const cityRef = useRef(null);
  const neighborhoodRef = useRef(null);
  const streetRef = useRef(null);
  const streetNumberRef = useRef(null);
  const streetComplementRef = useRef(null);
  const continueBtnRef = useRef(null)

  const [error, setError] = useState('')

  const [optionsCountry, setOptionsCountry] = useState([]);
  const [optionsState, setOptionsState] = useState([]);
  const [optionsCity, setOptionsCity] = useState([]);

  const [stateSelected, setStateSelected] = useState('SC');

  const resetInputAddress = () => {
    setNeighborhood('');
    setStreet('');
    setStreetComplement('');
    setStreetNumber('');
  }

  useEffect(() => {

    const getOptions = async () => {

      try {
        const responseStates = await AddressService.states();
        setOptionsState(responseStates);

        const responseCitys = await AddressService.citys(stateSelected);
        setOptionsCity(responseCitys);

        const responseCountry = await AddressService.country();
        setOptionsCountry(responseCountry);

      } catch (err) {
        setError(err);
      }

    }

    getOptions();
  }, [])

  useEffect(() => {
    const getStateCity = async () => {

      if (state !== stateSelected) {
        try {

          const responseCitys = await AddressService.citys(state);

          setOptionsCity(responseCitys)
          setStateSelected(state);

          resetInputAddress();

        } catch (err) {
          setError(err);
        }
      }
    };

    getStateCity();
  }, [state]);

  const handleDataCep = async (e) => {
    setError('')
    const cepInput = e.target.value

    if (!cepInput) {
      return resetInputAddress();
    }

    try {
      const responseAddress = await AddressService.cep(cepInput.replace("-", ""));

      if (responseAddress.localidade !== "Jaraguá do Sul") {
        setCep('')
        throw "Liberado apenas para a cidade de Jaraguá do Sul-SC!"
      }

      setNeighborhood(responseAddress.bairro);
      setStreet(responseAddress.logradouro);
      setStreetComplement(responseAddress.complemento);
      setStreetNumber('');
      setCep(cepInput);

    } catch (err) {
      setError(err);
      return resetInputAddress();
    }
  }

  const handleNextStepRegister = async (e) => {
    e.preventDefault()

    if (!validationInputs()) {
      return
    }

    try {
      const responseAddress = await AddressService.cep(cep.replace("-", ""));

      if (responseAddress.localidade !== "Jaraguá do Sul") {
        setCep('')
        throw "Liberado apenas para a cidade de Jaraguá do Sul-SC!"
      }

      setNeighborhood(responseAddress.bairro);
      setStreet(responseAddress.logradouro);
      setStreetComplement(responseAddress.complemento);
      setStreetNumber('');
      setCep(cep);
    } catch (err) {
      setError(err);
      return resetInputAddress();
    }

    let data = {
      cep: cep.replace(/[()-\s]/g, ""),
      country: country,
      state: state,
      city: city,
      neighborhood: neighborhood,
      street: street,
      streetNumber: streetNumber
    }

    if (!isObjectComplete(data)) {
      return setError("Preencha todos os campos!");
    }

    data = {
      ...data,
      streetComplement: streetComplement
    }

    handleNextStep(data)

  }

  const validationInputs = () => {
    setError('')

    !cep ? setIsCepFilled(true) : setIsCepFilled(false);
    if (!cep) return cepRef.current.focus();

    !country ? setIsCountryFilled(true) : setIsCountryFilled(false);
    if (!country) return countryRef.current.focus();

    !state ? setIsStateFilled(true) : setIsStateFilled(false);
    if (!state) return stateRef.current.focus();

    !city ? setIsCityFilled(true) : setIsCityFilled(false);
    if (!city) return cityRef.current.focus();

    !neighborhood ? setIsNeighborhoodFilled(true) : setIsNeighborhoodFilled(false);
    if (!neighborhood) return neighborhoodRef.current.focus();

    !street ? setIsStreetFilled(true) : setIsStreetFilled(false);
    if (!street) return streetRef.current.focus();

    !streetNumber || streetNumber.length !== 4 ? setIsStreetNumberFilled(true) : setIsStreetNumberFilled(false);
    if (!streetNumber || streetNumber.length > 4) return streetNumberRef.current.focus();

    return true
  }

  const handleBeforeStep = (e) => {
    e.preventDefault()

    handleNextStep('', 1)
  }

  return (
    <DivRegister>
      <ShowStep
        stepsModal={[{ title: '' }, { title: '' }, { title: '' }]}
        stageRegister={1}
      />
      <h3 className="title-form">Não se preocupe, seus dados estão seguros e protegidos.</h3>
      <form onSubmit={handleNextStepRegister}>
        <InputMask
          maskInput='00000-000'
          name='cep'
          valueInput={cep}
          setData={setCep}
          placeholder='CEP'
          ariaDescribedby='CEP'
          refInput={cepRef}
          isValid={isCepFilled}
          onBlur={handleDataCep}
        />
        <InputSelect
          className='select-custom'
          name='country'
          valueInput={country}
          setData={setCountry}
          placeholder='País'
          ariaDescribedby='País'
          refInput={countryRef}
          options={optionsCountry}
          isValid={isCountryFilled}
          isDisabled={true}
        />
        <InputSelect
          className='select-custom'
          name='state'
          valueInput={state}
          setData={setState}
          placeholder='Estado'
          ariaDescribedby='Estado'
          refInput={stateRef}
          options={optionsState}
          isValid={isStateFilled}
          isDisabled={true}
        />
        <InputSelect
          className='select-custom'
          name='city'
          valueInput={city}
          setData={setCity}
          placeholder='Cidade'
          ariaDescribedby='Cidade'
          refInput={cityRef}
          options={optionsCity}
          isValid={isCityFilled}
          isDisabled={true}
        />
        <Input
          type='text'
          name='neighborhood'
          valueInput={neighborhood}
          setData={setNeighborhood}
          placeholder='Bairro'
          ariaDescribedby='Bairro'
          refInput={neighborhoodRef}
          isValid={isNeighborhoodFilled}
        />
        <div className='double-input'>
          <Input
            type='text'
            name='street'
            valueInput={street}
            setData={setStreet}
            placeholder='Rua'
            ariaDescribedby='Rua'
            refInput={streetRef}
            isValid={isStreetFilled}
          />
          <Input
            type='number'
            name='streetNumber'
            valueInput={streetNumber}
            setData={setStreetNumber}
            placeholder='N°'
            ariaDescribedby='Número da Casa'
            refInput={streetNumberRef}
            isValid={isStreetNumberFilled}
            className='input-number'
          />
        </div>
        <Input
          type='text'
          name='streetComplement'
          valueInput={streetComplement}
          setData={setStreetComplement}
          placeholder='Complemento'
          ariaDescribedby='Complemento'
          refInput={streetComplementRef}
          isValid={isStreetComplementFilled}
        />
        <WarningInput valueWarning={error} />
        <div className='area-btn'>
          <Button
            type={2}
            placeholder='Continuar Cadastro'
            ariaDescribedby='Continuar Cadastro'
            classDivButton='btn-custom'
            btnRef={continueBtnRef}
          />
        </div>
      </form>
      <Button
        type={1}
        placeholder='Voltar'
        ariaDescribedby='Voltar'
        classDivButton='btn-custom-white'
        className='btn-white'
        handleFunction={handleBeforeStep}
      />
    </DivRegister>
  )

}



async function getCitys(UF) {

  try {
    const response = await AddressService.citys(UF);

    return response

  } catch (err) {
    throw err
  }

}