import axios from 'axios';
import cookie from 'cookie';

const parseTokenCookie = () => {
  const cookies = cookie.parse(document.cookie);
  return cookies.token || null;
};

const API_URL = process.env.REACT_APP_API_URL;

const apiAuth = () => {
  const token = parseTokenCookie();
  return axios.create({
    baseURL: API_URL,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
};

export default apiAuth;
