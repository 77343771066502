import { styled } from 'styled-components';

export const Title = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 25px 20px 25px;

    i {
        font-size: 22px;
        color: #000083;
        margin-right: 30px;
        cursor: pointer;
    }

    h3 {
        font-size: 1.5em;
        color:  ${props => props.theme.colors.primary};
        font-weight: 800;
        margin: 0px;
    }

`

export const DivChangePassword = styled.div`

    border: 1px solid  ${props => props.theme.colors.borders};
    border-radius: 25px;
    margin: 25px;
    padding: 15px;
    display: flex;

    .inputs-area{
        width: 50%;
        padding-right: 25px;
        border-right: 1px solid ${props => props.theme.colors.borders};
    }

    .bi{
        margin-right: 10px;
    }

    .info-change-password.check {
        color: #32CD32;
    }

    .title-info-change-password {
        font-size: 1.2em;
        font-weight: 800;
    }

    .info-change-password {
        font-size: .9em;
        margin-bottom: .5rem;
        color: red;
        font-weight: 600;
    }

    .btn-custom {
        margin-top: 20px;
    }

    .info-area{
        padding-left: 25px;
    }

    @media screen and (max-width: 1000px) {

        flex-direction: column;

        .inputs-area{
            width: 100%;
            padding: 0 0 25px 0;
            border-right: none;
            border-bottom: 1px solid ${props => props.theme.colors.borders};
        }

        .title-info-change-password{
            font-weight: 800;
        }
    
        .info-change-password{
            margin-bottom: .5rem;
            color: red;
            font-size: .9em;
            font-weight: 600;
        }

        .info-area{
            padding: 25px 0 0 0;
        }

    }

    @media screen and (max-width: 600px) {

        border: none;
        padding: 0px;
        margin: 10px;

        .btn-custom {
            width: 100%;
        }

    }

`