import { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import validator from 'email-validator';

// Styles
import { DivRegister } from './styles';

// Components
import { Button } from '../Button';

// Input
import { Input, InputMask, InputPassword, InputCheckBox, WarningInput } from '../Input';

// Utils
import { validatePassword, isObjectComplete } from '../../utils/validationFunction';

// Services
import ValidationService from '../../services/ValidationService';
import AuthService from '../../services/AuthService';

export function RegisterInstitutionUser() {

    const navigate = useNavigate();

    const [cookieToken, setCookieToken] = useCookies(['token']);

    const [ cnpj, setCnpj ] = useState('');
    const [ telephone, setTelephone ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ termsUse, setTermsUse ] = useState('');
    const [ institucionalName, setInstitucionalName ] = useState('');

    const { tokenRegister } = useParams();

    const [token, setToken] = useState(tokenRegister);

    const cnpjRef = useRef(null);
    const telephoneRef = useRef(null);
    const tokenRef = useRef(null);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);
    const termsUseRef = useRef(null);
    const institucionalNameRef = useRef(null);

    const [ isCnpjFilled, setIsCnpjFilled ] = useState(false);
    const [ isTelephoneFilled, setIsTelephoneFilled ] = useState(false);
    const [ isTokenFilled, setIsTokenFilled ] = useState(false);
    const [ isEmailFilled, setIsEmailFilled ] = useState(false);
    const [ isPasswordFilled, setIsPasswordFilled ] = useState(false);
    const [ isConfirmPasswordFilled, setIsConfirmPasswordFilled ] = useState(false);
    const [ isTermsUseFilled, setIsTermsUseFilled ] = useState(false);
    const [ isInstitucionalNameFilled, setIsInstitucionalNameFilled ] = useState(false);

    const [ error, setError ] = useState('');

    const handleRegister = async (e) => {
        e.preventDefault();

        if(!validationInputs()){
            return;
        }

        let formData = {
            cnpj,
            institutionName: institucionalName,
            telephone,
            passCode: tokenRegister,
            email,
            password,
            confirmPassword,
            termsUse
        }

        if (!isObjectComplete(formData)) {
            return setError("Preencha todos os campos!");
        }

        const validation = await handleValidationCnpj();
        if (!validation) {

            setError("CNPJ informado é inválido!");
            return cnpjRef.current.focus();
        } 

        try {

            console.log(formData)
            const responseInstitution = await AuthService.registerInstitutionUser(formData)

            const token = responseInstitution.access_token

            setCookieToken('token', token, { path: '/' })

        } catch (error) {
            console.log(error)
            setError(error);
        }

    }

    const validationInputs = () => {
        setError('');

        !cnpj ? setIsCnpjFilled(true) : setIsCnpjFilled(false);
        if (!cnpj) return cnpjRef.current.focus();

        !institucionalName ? setIsInstitucionalNameFilled(true) : setIsInstitucionalNameFilled(false);
        if (!institucionalName) return institucionalNameRef.current.focus();

        !telephone ? setIsTelephoneFilled(true) : setIsTelephoneFilled(false);
        if (!telephone) return telephoneRef.current.focus();
    
        !token ? setIsTokenFilled(true) : setIsTokenFilled(false);
        if (!token) return tokenRef.current.focus();
        
        if (!email) {
            setIsEmailFilled(true);
            return emailRef.current.focus();
        } else {
            if (!validator.validate(email)) {
                setError("Informe um e-mail válido!");
                setIsEmailFilled(true);
                return  emailRef.current.focus();
            }

            setIsEmailFilled(false);
        }

        !password ? setIsPasswordFilled(true) : setIsPasswordFilled(false);
        if (!password) return passwordRef.current.focus();

        !confirmPassword ? setIsConfirmPasswordFilled(true) : setIsConfirmPasswordFilled(false);
        if (!confirmPassword) return confirmPasswordRef.current.focus();

        let message = validatePassword(password, confirmPassword)
        if (message !== "") {
            setError(message);
            setIsConfirmPasswordFilled(true);
            setIsPasswordFilled(true);
            passwordRef.current.focus();
            return  
        }

        !termsUse ? setIsTermsUseFilled(true) : setIsTermsUseFilled(false);
        if (!termsUse) return termsUseRef.current.focus();
    
        return true;
    }

    const handleValidationCnpj = async () => {

        if (!cnpj) {
            setCnpj('')
            setInstitucionalName('')
            return false;
        }

        setCnpj(cnpj)

        try {
            const response = await ValidationService.validateCnpj(cnpj.replace(/\D/g, ""))
            setInstitucionalName(response.company.name)

            return true;

        } catch (error) {
            setIsCnpjFilled(true);
            setError(error);
            return false;
        }
    }

    return (
        <DivRegister>
            <h3 className="title-form">Não se preocupe, seus dados estão seguros e protegidos.</h3>
            <form onSubmit={handleRegister} noValidate>
                <InputMask 
                    maskInput='00.000.000/0000-00'
                    name='cnpj'
                    valueInput={cnpj}
                    setData={setCnpj}
                    placeholder='CNPJ'
                    ariaDescribedby='CNPJ'
                    refInput={cnpjRef}
                    isValid={isCnpjFilled}
                    onBlur={handleValidationCnpj}
                />
                <Input 
                    type='text'
                    name='institucionalName'
                    valueInput={institucionalName}
                    setData={setInstitucionalName}
                    placeholder='Nome da Instituição'
                    ariaDescribedby='Nome da Instituição'
                    isValid={isInstitucionalNameFilled}
                    refInput={institucionalNameRef}
                />
                <InputMask 
                    maskInput='(00) 00000-0000'
                    name='telephone'
                    valueInput={telephone}
                    setData={setTelephone}
                    placeholder='Telefone'
                    ariaDescribedby='Telefone'
                    refInput={telephoneRef}
                    isValid={isTelephoneFilled}
                />
                <Input 
                    type='text'
                    name='token'
                    valueInput={token}
                    setData={setToken}
                    placeholder='Código de Acesso'
                    ariaDescribedby='Código de Acesso'
                    refInput={tokenRef}
                    isValid={isTokenFilled}
                />
                <Input 
                    type='email'
                    name='email'
                    valueInput={email}
                    setData={setEmail}
                    placeholder='E-mail'
                    ariaDescribedby='E-mail'
                    isValid={isEmailFilled}
                    refInput={emailRef}
                />
                <div className='double-input'>
                    <InputPassword 
                        name='password'
                        valueInput={password}
                        setData={setPassword}
                        placeholder='Senha'
                        ariaDescribedby='Senha'
                        refInput={passwordRef}
                        isValid={isPasswordFilled}
                    />
                    <InputPassword 
                        name='confirmPassword'
                        valueInput={confirmPassword}
                        setData={setConfirmPassword}
                        placeholder='Confirmar Senha'
                        ariaDescribedby='Confirmar Senha'
                        refInput={confirmPasswordRef}
                        isValid={isConfirmPasswordFilled}
                    />
                </div>
                <InputCheckBox 
                    className='checkbox-custom'
                    name='termsUse'
                    valueInput={termsUse}
                    setData={setTermsUse}
                    placeholder='Eu concordo com os termos de uso'
                    ariaDescribedby='Eu concordo com os termos de uso'
                    refInput={termsUseRef}
                    isValid={isTermsUseFilled}
                />
                <WarningInput valueWarning={error} />
                <Button 
                    type={2}
                    placeholder='Finalizar Cadastro'
                    ariaDescribedby='Finalizar Cadastro'
                    className='btn-custom'
                />
            </form>
        </DivRegister>
    )

}