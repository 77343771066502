import { styled } from 'styled-components';

export const DivCarousel = styled.div`
    .areaSlider {
        width: 100%;
        overflow: hidden;
    }

    .areaSlider img {
        width: 100%;
        object-fit: cover;
        object-position: center center;
    }

    .slick-arrow, .slick-next, .slick-next:before, .slick-prev {
        display: none !important;
    }

    div.slick-arrow.slick-next {
        display: none !important;
    }

    div[data-role="none"].slick-next {
        display: none !important;
    }

    div.slick-arrow.slick-next {
        display: none !important;
    }

    .area-img {
        margin-bottom: 10px;
    }

    .slick-dots {
        //background-color: #FFFFFF;
        background-color: ${props => props.theme.colors.background};
        position: relative;
        top: -30px;
        margin: 0 auto;
        width: 100px;
        height: 20px;
        //border: 2px solid #4424D3;
        border: 2px solid ${props => props.theme.colors.senary};
        border-radius: 25px;
    }

    .slick-dots li button:before {
        font-size: 13px;
        //color: #4424D3;
        color: ${props => props.theme.colors.senary};
        opacity: .5;
        margin-top: -7px;
    }

    .slick-dots li.slick-active button:before {
        //color: #4424D3;
        color: ${props => props.theme.colors.senary};
        opacity: 1;
    }

`

export const DivLoading = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`