import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

// Styles
import { DivUserPanel } from './styles';

// Components
import { SideBar } from '../../components/SideBar';
import { ChangeAddress } from '../../components/ChangeAddress';
import { ChangePassword } from '../../components/ChangePassword';
import { Accessibility } from '../../components/Accessibility';

// Utils
import { getWidthHeightWindow } from '../../utils/utilsFunction';

export function UserPanel() {
  const { user } = useSelector((state) => state.auth);

  const [ typeUser ] = useState(user.userType.type ? user.userType.type : '')

  const [selectedComponent, setSelectedComponent] = useState('ChangeAddress');
  const [showMenu, setShowMenu] = useState(true);

  const { screenWidth } = getWidthHeightWindow();
  const [windowWidth, setWindowWidth] = useState(screenWidth);

  useEffect(() => {
    const handleResize = () => {
        const { screenWidth } = getWidthHeightWindow();

        setWindowWidth(screenWidth);

        if ( screenWidth < 600 ) {
            setShowMenu(true)
            setSelectedComponent('menu')
        } else {
            setSelectedComponent('ChangeAddress')
        }

    };

    window.addEventListener('resize', handleResize);

    if (windowWidth < 600) setSelectedComponent('menu')

    return () => {
        window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderComponent = () => {
    switch (selectedComponent) {
    case 'ChangeAddress':
      if (typeUser === 'default') {
        return <ChangeAddress handleGoBack={handleGoBack} />;
      } else {
        return <ChangePassword handleGoBack={handleGoBack} />;
      }
    case 'ChangePassword':
      return <ChangePassword handleGoBack={handleGoBack} />;
    case 'Accessibility':
      return <Accessibility handleGoBack={handleGoBack} />;
    default:
        return null;
    }
  };

  const handleComponentSelect = (componentName) => {
      setSelectedComponent(componentName);

      if(windowWidth < 600) setShowMenu(false)
  };

  const handleGoBack = () => {
      setShowMenu(true)
      setSelectedComponent('menu')
  }

  return (
      <DivUserPanel>

          {showMenu && (
              <SideBar setSelectedComponent={handleComponentSelect} typeUser={typeUser} />
          )}

          {selectedComponent != 'menu' && (
              <div className='inputs-area'>{renderComponent()}</div>
          )}
          
      </DivUserPanel>
  );
}