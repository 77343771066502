import apiAuth from "../utils/apiAuth";

const api = apiAuth();

async function optionsMaritalStatus() {
    try {
    
        const response = [
            { id: 1, value: "Solteiro(a)" },
            { id: 2, value: "Casado(a)" },
            { id: 3, value: "Divorciado(a)" },
            { id: 4, value: "Viúvo(a)" },
            { id: 5, value: "Separado(a)" }
        ];

        return  response;

    } catch (error) {
        throw { message: "Não foi possível carregar as opções!" };
    }
}

async function optionsGender() {
    try {

        const response = [
            { id: 1, value: "Masculino" },
            { id: 2, value: "Feminino" },
        ];

        return  response;

    } catch (error) {

        throw { message: "Não foi possível carregar as opções!" };

    }
}

async function optionsYesOrNo() {
    try {

        const response = [
            { id: 0, value: 'Não' },
            { id: 1, value: 'Sim' }
        ];

        return  response;

    } catch (error) {
        throw { message: "Não foi possível carregar as opções!" };
    }
}

async function optionsRaces() {
    try {

        const response = [
            { id: 1, value: "Branco" },
            { id: 2, value: "Preto" },
            { id: 3, value: "Pardo" },
            { id: 4, value: "Indígena" },
            { id: 5, value: "Amarelo" }
        ]

        return  response;
    } catch (error) {
        throw "Não foi possível carregar as opções!";
    }
}

async function optionsTextSize(){
    const api = apiAuth();

    try {
        const response = await api.get('/configuration/get-config-options');

        // const response = [
        //     { id: 1, value: "Texto Muito pequeno" },
        //     { id: 2, value: "Texto Pequeno" },
        //     { id: 3, value: "Texto Normal" },
        //     { id: 4, value: "Texto Grande" },
        //     { id: 5, value: "Texto Muito grande" }
        // ]

        return  response.data.text_sizes;
    } catch (error) {
        throw "Não foi possível carregar as opções!";
    }
}

async function getConfigOptions() {
    const api = apiAuth();
    
    try {
        const response = await api.get('/configuration/get-config-options');
        const arraySizes = response.data.text_sizes;
        const arrayColors = response.data.color_types;

        const sizes = arraySizes.map((size, index) => {
            return {
                id: index,
                value: size
            }
        });

        const colors = arrayColors.map((color, index) => {
            return {
                id: index,
                value: color
            }
        })

        return {
            sizes,
            colors
        };
    } catch (error) {
        console.log(error)
    }
}

async function optionsColorBlindness(){
    try {

        const response = [
            { id: 0, value: "Tema padrão" },
            { id: 1, value: "Deuteranopia" },
            { id: 2, value: "Protanopia" },
            { id: 3, value: "Tritanopia" },
        ]

        return  response;
    } catch (error) {
        throw "Não foi possível carregar as opções!";
    }
}

const OptionsService = {
    optionsMaritalStatus,
    optionsGender,
    optionsYesOrNo,
    optionsRaces,
    optionsTextSize,
    optionsColorBlindness,
    getConfigOptions
};

export default OptionsService;