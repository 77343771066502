import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from 'js-cookie';
import AuthService from "../services/AuthService";

const initialState = {
  user: null,
  error: null,
  success: false,
  loading: false,
  isAuthenticated: Cookies.get('token') ? true : false,
  isRegistered: null,
  address: null
};

export const registerDisabledPerson = createAsyncThunk(
  "auth/registerDisabledPerson",
  async (userData, thunkAPI) => {
    try {
      const response = await AuthService.register(userData);
      
      const user = {
        userType: response.user
      }

      setTokenCookie(response.access_token)

      return {
        user,
        isAuthenticated: getTokenCookie()
      }

    } catch (error) {
      removeTokenCookie()
      return thunkAPI.rejectWithValue(error.error);
    }
  }
);

export const getDataUser = createAsyncThunk(
  "auth/getDataUser",
  async (_, thunkAPI) => {
    try {

      const responseUser = await AuthService.typeUser()

      const user = responseUser.user

      return user
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.error);
    }
  }
);

export const registerPerson = createAsyncThunk(
  "auth/registerPerson",
  async (userData, thunkAPI) => {
    try {
      const response = await AuthService.registerPerson(userData);

      const user = {
        userType: response.user
      }

      setTokenCookie(response.access_token)

      return {
        user,
        isAuthenticated: getTokenCookie()
      }

    } catch (error) {
      removeTokenCookie()
      return thunkAPI.rejectWithValue(error.error);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (userData, thunkAPI) => {
    try {
      const password = userData.password;
      const email = userData.email;

      const response = await AuthService.login(email, password);

      setTokenCookie(response.access_token)

      const user = {
        config: response.config,
        userType: response.user
      }

      return {
        user,
        isAuthenticated: getTokenCookie()
      }
    } catch (error) {
      removeTokenCookie();
      return thunkAPI.rejectWithValue(error.error);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, thunkAPI) => {
    try {
      await AuthService.logout();

      removeTokenCookie()

      return null;
    } catch (error) {
      removeTokenCookie()
      return thunkAPI.rejectWithValue(error.error);
    }
  }
);

export const userValidate = createAsyncThunk(
  "auth/userValidate",
  async (userData, thunkAPI) => {
    try {
      await AuthService.userValidate(userData);

      return false;
    } catch (error) {

      const errorValidate = {
        isRegistered: true,
        error
      }

      return thunkAPI.rejectWithValue(errorValidate);
    }
  }
);

export const updateAddressUser = createAsyncThunk(
  "auth/updateAddressUser",
  async (userData, thunkAPI) => {
    try {
      await AuthService.updateAddressUser(userData);

    } catch (error) {

      return thunkAPI.rejectWithValue(error);
    }
  }
);


const setTokenCookie = (token) => {
  Cookies.set('token', token, { path: '/' });
};

const removeTokenCookie = () => {
  Cookies.remove('token');
};

const getTokenCookie = () => {
  return Cookies.get('token') ? true : false
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.error = null;
      state.success = false;
      state.loading = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerDisabledPerson.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerDisabledPerson.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.user = action.payload.user;
        state.isAuthenticated = action.payload.isAuthenticated
      })
      .addCase(registerDisabledPerson.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.user = action.payload.user;
        state.isAuthenticated = action.payload.isAuthenticated
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.isRegistered = null
      })
      .addCase(logout.fulfilled, (state) => {
        state.success = true;
        state.token = null;
        state.user = null
        state.isAuthenticated = false;
        state.isRegistered = null
      })
      .addCase(registerPerson.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerPerson.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.user = action.payload.user;
        state.isAuthenticated = action.payload.isAuthenticated
      })
      .addCase(registerPerson.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(userValidate.pending, (state) => {
        state.loading = true;
      })
      .addCase(userValidate.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.isRegistered = action.payload
      })
      .addCase(userValidate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
        state.isRegistered = action.payload.isRegistered;
      })
      .addCase(getDataUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDataUser.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.address = action.payload
      })
      .addCase(getDataUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateAddressUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAddressUser.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(updateAddressUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;