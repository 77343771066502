import apiAuth from '../utils/apiAuth';

async function callApi(method, endpoint, data = null) {
    const api = apiAuth();
    try {
        const response = await (data ? api[method](endpoint, data) : api[method](endpoint));
        return response.data;
    } catch (error) {
        // Se o backend retornar uma mensagem de erro específica, a use; caso contrário, use uma mensagem genérica
        const errorMessage = error.response?.data?.message;
        throw new Error(errorMessage);
 
    }
}

export async function RegisterSuggestion(content) {
    return callApi('post', '/suggestions', { content });
}

export async function GetSuggestion() {
    return callApi('get', '/suggestions');
}

export async function ApproveSuggestion(id) {
    return callApi('put', `/suggestions/approve/${id}`);
}

export async function ReproveSuggestion(id) {
    return callApi('delete', `/suggestions/delete/${id}`);
}

const SuggestionService = {
    RegisterSuggestion,
    GetSuggestion,
    ApproveSuggestion,
    ReproveSuggestion
};

export default SuggestionService;
