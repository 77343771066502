import { useState } from 'react'

// Styles
import { SectionFooter } from './styles'

export function Footer({flagFooter}){
    const [ flag ] = useState(flagFooter ? flagFooter : false)

    const footerRegister =  {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0
    }

    return(
        <SectionFooter style={flag ? footerRegister : {}}>
            Copyright © 2024 AllCance - Todos direitos reservados.
        </SectionFooter>
    )

}