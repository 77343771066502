import { useState, useRef } from "react";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from 'react-router-dom'
import validator from 'email-validator';

// Inputs
import { Input, WarningInput } from '../../components/Input'
import { Button } from '../../components/Button'

// Styles
import { DivForgotPassword } from "./styles";

// Utils

export function ForgotPassword() {

    const navigate = useNavigate();

    const [ error, setError ] = useState('');
    const [ email, setEmail ] = useState('');

    const emailRef = useRef(null)

    const [ isEmailFilled, setIsEmailFilled ] = useState('');

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setError('');

        if (!email) {
            setIsEmailFilled(true);
            return emailRef.current.focus();
        }

        if (!validator.validate(email)) {
            setError("Informe um e-mail válido!");
            setIsEmailFilled(true);
            return emailRef.current.focus();
        }

        navigate('/change-password')
    }

    return(
        <DivForgotPassword>
            <div className="area-email">
                <div className="area-title">
                    <h3>Esqueceu sua senha</h3>
                    <p className="text-email">Insira seu e-mail, iremos enviar um link para recuperação.</p>
                </div>
                <form onSubmit={handleForgotPassword} noValidate>
                    <Input 
                        type='email'
                        name='email'
                        valueInput={email}
                        setData={setEmail}
                        placeholder='E-mail'
                        ariaDescribedby='Email'
                        refInput={emailRef}
                        isValid={isEmailFilled}
                        
                    />
                    <WarningInput valueWarning={error} />
                    <Button 
                        type={2}
                        placeholder='Recuperar Senha'
                        ariaDescribedby='Recuperar Senha'
                        classDivButton='btn-custom'
                    />
                </form>
                <p>Lembrou a senha? <HashLink to='/'>Faça Login!</HashLink></p>
            </div>
        </DivForgotPassword>    
    )

}