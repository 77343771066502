import { styled } from 'styled-components';

export const DivBarGraph = styled.div`
    min-height: 80vh;
    padding: 0px;
    overflow-x: hidden;

    .areaGraph {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        width: 100%;
        min-height: 80vh;
    }

    .graphContainer {
        height: 300px;
        width: 100%;
        margin-right: 40px;
    }

    .recharts-legend-wrapper {
      display: none;
    }

    .titleGraph {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: ${props => props.theme.colors.senary};
      height: 40px;
      padding-left: 20px;
      border-radius: 10px 10px 0px 0px;
    }

    .titleGraph h4 {
        font-size: 1.3em;
        margin: 0px;
        font-weight: 700;
        padding-left: 20px;
        color: #FFFFFF;
    }

    .container {
      padding: 0px;
    }

    .subtitle {
      margin-left: 80px;
      min-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .subtitle ul {
      padding: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    .subtitle li {
      display: flex;
      justify-content: flex-start;
      margin-top: 5px;
      align-items: center;
      font-size: 1.2em;
      font-weight: 600;
      width: calc(50% - 10px);
    }

    .subtitle div {
        margin-right: 10px;
        border-radius: 25px;
        height: 20px;
        width: 20px;
    }

    .second-color.subtitle {
        color: ${props => props.theme.colors.background};
    }

    @media screen and (max-width: 1200px) {
      .subtitle li {
        font-size: 1em;
      }
    }

    @media screen and (max-width: 770px) {
      .subtitle {
        margin-left: 50px;
      }

      .subtitle li {
          font-size: 1em;
          min-width: 100%;
      }

      .subtitle ul {
        justify-content: center;
        max-width: 400px;
      }

    }

    @media screen and (max-width: 380px) {
      .titleGraph h4 {
          font-size: 1.1em;
      }

      .subtitle li {
        font-size: .8em;
      }
    }
`

export const DivTooltip = styled.div`
  background-color: rgba(255, 255, 255, .8);
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;

  p {
    font-weight: 600;
  }
`