import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FeedService from "../services/FeedService";

const initialState = {
    errorBanner: null,
    successBanner: false,
    loadingBanner: true,
    bannerDesktop: [],
    bannerMobile: []
};

export const getBannersFeed = createAsyncThunk(
    "auth/getBannersFeed",
    async (_, thunkAPI) => {
        try {
            const { images, imagesMobile } = await FeedService.getBannersFeed()
    
            return {
                bannerDesktop: images,
                bannerMobile: imagesMobile
            };
        } catch(error) {
            return thunkAPI.rejectWithValue(error.error);
        }
    }
);

const bannerSlice = createSlice({
    name: "banner",
    initialState,
    reducers: {
        reset: (state) => {
            state.errorBanner = null;
            state.successBanner = false;
            state.loadingBanner = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBannersFeed.pending, (state) => {
                state.loadingBanner = true;
            })
            .addCase(getBannersFeed.fulfilled, (state, action) => {
                state.loadingBanner = false;
                state.successBanner = true;
                state.errorBanner = null;
                state.bannerDesktop = action.payload.bannerDesktop;
                state.bannerMobile = action.payload.bannerMobile;
            })
            .addCase(getBannersFeed.rejected, (state, action) => {
                state.loadingBanner = false;
                state.errorBanner = action.payload;
            })
    }
});

export const { reset } = bannerSlice.actions;
export default bannerSlice.reducer;
