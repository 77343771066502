import apiAuth from '../utils/apiAuth';

async function GetColorBlindness() {
    try {

        const api = apiAuth();
        if (!api || typeof api.get !== 'function') {
            return 'Padrao'; 
        }
        const response = await api.get('/configuration/get-user');
        return response.data.configuration.color_blindness;
    } catch (error) {
        return 'Padrao'; 
    }
}


const ColorBlindnessService = { GetColorBlindness };

export default ColorBlindnessService;

