import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

// Styles
import { DivRegister, Title } from './styles';

// Components
import { Button } from '../Button';

// Input
import { Input, InputSelect, InputMask, WarningInput, SuccessInput } from '../Input';

// Utils
import { isObjectComplete } from '../../utils/validationFunction';
import { getWidthHeightWindow } from '../../utils/utilsFunction';

// Redux
import { getDataUser, updateAddressUser } from '../../slices/authSlices';
import { getOptionsCitys, getOptionsStates, getOptionsCountry, getCep } from '../../slices/addressSlices';

export function ChangeAddress({ handleGoBack }) {
  const { address = {} } = useSelector((state) => state.auth);
  const { countryOptions, statesOptions, citysOptions, errorAddress, loadingDataCep, dataCep } = useSelector((state) => state.address);

  const dispatch = useDispatch()

  const [cep, setCep] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [street, setStreet] = useState('');
  const [streetNumber, setStreetNumber] = useState();
  const [streetComplement, setStreetComplement] = useState('');

  const [isCepFilled, setIsCepFilled] = useState(false);
  const [isCountryFilled, setIsCountryFilled] = useState(false);
  const [isStateFilled, setIsStateFilled] = useState(false);
  const [isCityFilled, setIsCityFilled] = useState(false);
  const [isNeighborhoodFilled, setIsNeighborhoodFilled] = useState(false);
  const [isStreetFilled, setIsStreetFilled] = useState(false);
  const [isStreetNumberFilled, setIsStreetNumberFilled] = useState(false);
  const [isStreetComplementFilled, setIsStreetComplementFilled] = useState(false);

  const cepRef = useRef(null);
  const countryRef = useRef(null);
  const stateRef = useRef(null);
  const cityRef = useRef(null);
  const neighborhoodRef = useRef(null);
  const streetRef = useRef(null);
  const streetNumberRef = useRef(null);
  const streetComplementRef = useRef(null);

  const [stateSelected, setStateSelected] = useState(address?.state || '');

  const [error, setError] = useState('')
  const [sucessMsg, setSucessMsg] = useState('');

  const { screenWidth } = getWidthHeightWindow();

  const isCepUpdate = useRef(false);

  useEffect(() => {
    dispatch(getDataUser())
    dispatch(getOptionsCountry());
    dispatch(getOptionsStates());
    dispatch(getOptionsCitys(state));
  }, [])

  const resetInputAddress = () => {
    setCep('');
    setCity('');
    setNeighborhood('');
    setStreet('');
    setStreetComplement('');
    setStreetNumber('');
  }

  useEffect(() => {
    if (state !== stateSelected && !isCepUpdate.current) {
      dispatch(getOptionsCitys(state));

      setStateSelected(state);
      resetInputAddress();
    }
    isCepUpdate.current = false;
  }, [ state ]);

  useEffect(() => {

    if(!loadingDataCep && dataCep) {
      isCepUpdate.current = true;
      setState(dataCep.uf)
      setCity(dataCep.localidade)
      setNeighborhood(dataCep.bairro);
      setStreet(dataCep.logradouro);
      setStreetComplement(dataCep.complemento);
      setStreetNumber('');
      dispatch(getOptionsCitys(dataCep.uf));
    }

  }, [ loadingDataCep ])

  useEffect(() => {

    if (address) {
      setCep(address?.cep);
      setCountry(address?.country);
      setState(address?.state);
      setCity(address?.city);
      setNeighborhood(address?.neighborhood);
      setStreet(address?.street);
      setStreetNumber(parseInt(address?.street_number));
      setStreetComplement(address?.street_complement);
    }

  }, [ address ]);


  const handleDataCep = async (e) => {

    const cepInput = e.target.value

    if (!cepInput) {
      return resetInputAddress();
    }

    dispatch(getCep(cep))
    setCep(cepInput);
  }

  const saveChanges = async (e) => {
    e.preventDefault()

    if (!validationInputs()) {
      return
    }

    let formData = {
      cep: cep.replace(/[()-\s]/g, ""),
      country: country,
      state: state,
      city: city,
      neighborhood: neighborhood,
      street: street,
      streetNumber: streetNumber.toString()
    }

    if (!isObjectComplete(formData)) {
      return setError("Preencha todos os campos!");
    }

    formData = {
      ...formData,
      streetComplement: streetComplement
    }

    dispatch(updateAddressUser(formData))

  }

  const validationInputs = () => {
    setSucessMsg('');
    setError('')

    !cep ? setIsCepFilled(true) : setIsCepFilled(false);
    if (!cep) return cepRef.current.focus();

    !country ? setIsCountryFilled(true) : setIsCountryFilled(false);
    if (!country) return countryRef.current.focus();

    !state ? setIsStateFilled(true) : setIsStateFilled(false);
    if (!state) return stateRef.current.focus();

    !city ? setIsCityFilled(true) : setIsCityFilled(false);
    if (!city) return cityRef.current.focus();

    !neighborhood ? setIsNeighborhoodFilled(true) : setIsNeighborhoodFilled(false);
    if (!neighborhood) return neighborhoodRef.current.focus();

    !street ? setIsStreetFilled(true) : setIsStreetFilled(false);
    if (!street) return streetRef.current.focus();

    !streetNumber || streetNumber.length !== 4 ? setIsStreetNumberFilled(true) : setIsStreetNumberFilled(false);
    if (!streetNumber || streetNumber.length > 4) return streetNumberRef.current.focus();

    return true
  }

  return (
    <div>

      <Title>
        {screenWidth && screenWidth < 600 && (
          <i className="bi bi-arrow-left-circle-fill" onClick={handleGoBack}></i>
        )}
        <h3>Alterar Endereço</h3>
      </Title>


      <DivRegister>

        <form onSubmit={saveChanges}>
          <InputMask
            maskInput='00000-000'
            name='cep'
            valueInput={cep}
            setData={setCep}
            placeholder='CEP'
            ariaDescribedby='CEP'
            refInput={cepRef}
            isValid={isCepFilled}
            onBlur={handleDataCep}
          />
          <InputSelect
            className='select-custom'
            name='country'
            valueInput={country}
            setData={setCountry}
            placeholder='País'
            ariaDescribedby='País'
            refInput={countryRef}
            options={countryOptions}
            isValid={isCountryFilled}
            isDisabled={true}
          />
          <InputSelect
            className='select-custom'
            name='state'
            valueInput={state}
            setData={setState}
            placeholder='Estado'
            ariaDescribedby='Estado'
            refInput={stateRef}
            options={statesOptions}
            isValid={isStateFilled}
          />
          <InputSelect
            className='select-custom'
            name='city'
            valueInput={city}
            setData={setCity}
            placeholder='Cidade'
            ariaDescribedby='Cidade'
            refInput={cityRef}
            options={citysOptions}
            isValid={isCityFilled}
          />
          <Input
            type='text'
            name='neighborhood'
            valueInput={neighborhood}
            setData={setNeighborhood}
            placeholder='Bairro'
            ariaDescribedby='Bairro'
            refInput={neighborhoodRef}
            isValid={isNeighborhoodFilled}
          />
          <div className='double-input'>
            <Input
              type='text'
              name='street'
              valueInput={street}
              setData={setStreet}
              placeholder='Rua'
              ariaDescribedby='Rua'
              refInput={streetRef}
              isValid={isStreetFilled}
            />
            <Input
              type='number'
              name='streetNumber'
              valueInput={streetNumber}
              setData={setStreetNumber}
              placeholder='N°'
              ariaDescribedby='Número da Casa'
              refInput={streetNumberRef}
              isValid={isStreetNumberFilled}
              className='input-number'
            />
          </div>
          <Input
            type='text'
            name='streetComplement'
            valueInput={streetComplement}
            setData={setStreetComplement}
            placeholder='Complemento'
            ariaDescribedby='Complemento'
            refInput={streetComplementRef}
            isValid={isStreetComplementFilled}
          />
          <WarningInput valueWarning={errorAddress || error} />
          <Button
            type={2}
            placeholder='Salvar'
            ariaDescribedby='Salvar'
            className='btn-custom'
          />
          <SuccessInput valueSucess={sucessMsg} />
        </form>

      </DivRegister>

    </div>
  )
}