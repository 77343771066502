import { useState, useRef, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';

// Styles
import { DivPostRegister, DivLoading } from './styles';

// Components
import { Post } from '../../components/Post';

// Reduxe
import { getPostsIntitution, reset } from '../../slices/postsSlices';
import { getInstitution } from '../../slices/institutionSlices';

export function ProfileInstitution() {

  const dispatch = useDispatch()

  const { loadingPosts, posts } = useSelector((state) => state.posts);
  const { loadingInstitution, institution } = useSelector((state) => state.institution);
  
  const { idInstitution } = useParams();

  const [ institutionName, setInstitutionName ] = useState('')

  useEffect(() => {
    dispatch(reset())
    dispatch(getInstitution(idInstitution))
  }, [ idInstitution ])

  useEffect(() => {
    if (!loadingInstitution && institution) {
      dispatch(getPostsIntitution(institution.institution_name));
      setInstitutionName(institution.institution_name)
    }
  }, [ loadingInstitution, institution ])

  return (
      <DivPostRegister className='container'>
          <div className='area-info-institucion'>
              <div className='img-institucion'>
                  <img src='/image/user-profile-default.png' alt="Imagem da Instituição" />
                  <h4>{institutionName}</h4>
              </div>
              <div className='area-institucion' style={{ display: 'none' }}>
                  <h5><i className="bi bi-info-circle-fill"></i>Informações:</h5>
                  <div className='info'>
                      <p>    
                          123, Avenida das Indústrias 
                          Galpão 5, Parque Industrial Sul -
                          Jaraguá do Sul / SC
                      </p>
                  </div>
              </div>
              <div className='area-info-post' style={{display: 'none'}}>
                  <h5><i className="bi bi-postcard-fill"></i>Postagens:</h5>
                  <div className='info'>
                      <ul>
                          <li>Total Vagas de Emprego: <br /><span>5</span></li>
                      </ul>
                      <ul>
                          <li>Total Postagens: <br /><span>5</span></li>
                      </ul>
                      <ul>
                          <li>Total Publicações: <br /><span>5</span></li>
                      </ul>
                  </div>
              </div>
              <div className='options' style={{ display: 'none' }}>
                  <h5><i className="bi bi-gear-fill"></i>Opções:</h5>
                  <ul>
                      <li>
                          <HashLink to='/'>Visualizar Solicitações</HashLink>
                      </li>
                  </ul>
              </div>
          </div>
          <div  className='area-register-post'>
              <div className='area-posts'>
                {(loadingPosts || loadingInstitution) ? (
                  <DivLoading>
                    <div className="spinner-border text-primary" role="status"></div>
                  </DivLoading>
                  ) : (
                      <>
                          { posts.length > 0 && !loadingPosts ? (
                              <Post publications={posts} />
                          ) : (
                              <div className='message-posts'>
                                  <p>Nenhuma postagem encontrada!</p>
                              </div>
                          )}
                      </>
                  )}
              </div>
          </div>  
      </DivPostRegister>
  )

}