import { useState, useRef, useEffect } from 'react';

// Styles
import { DivChangePassword, Title } from './styles';

// Components
import { Button } from '../Button';

// Input
import { InputPassword, WarningInput, SuccessInput } from '../Input';

// Utils
import { validatePassword, isObjectComplete } from '../../utils/validationFunction';
import { getWidthHeightWindow } from '../../utils/utilsFunction';

// Services
import ConfigService from '../../services/ConfigService';

export function ChangePassword({ handleGoBack }) {

    const [ password, setPassword ] = useState('');
    const [ newPassword, setNewPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');

    // Validate Password
    const [ validateUpperCase, setValidateUpperCase ] = useState(false)
    const [ validateNumber, setNumber ] = useState(false)
    const [ validateSymbol, setSymbol ] = useState(false)
    const [ validateLength, setLength ] = useState(false)
    const [ validateIqual, setIqual ] = useState(false)

    const passwordRef = useRef(null);
    const newPasswordRef = useRef(null);
    const confirmPasswordRef = useRef(null);

    const [ isPasswordFilled, setIsPasswordFilled ] = useState(false);
    const [ isNewPasswordFilled, setIsNewPasswordFilled ] = useState(false);
    const [ isConfirmPasswordFilled, setIsConfirmPasswordFilled ] = useState(false);

    const [ error, setError ] = useState('');
    const [ sucessMsg, setSucessMsg ] = useState('');

    const { screenWidth } = getWidthHeightWindow();

    useEffect(() => {

        setValidateUpperCase(/[A-Z]/.test(newPassword));
        setNumber(/[0-9]/.test(newPassword));
        setSymbol(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(newPassword));
        setLength(newPassword.length >= 8 && newPassword.length <= 20);
        setIqual((newPassword === confirmPassword) && (newPassword !== "" && confirmPassword !== "")); 

    }, [newPassword, confirmPassword])

    const saveChange = async (e) => {
        e.preventDefault();

        if(!validationInputs()){
            return;
        }

        let formData = {
            password,
            newPassword,
            confirmPassword
        }

        if (!isObjectComplete(formData)) {
            return setError("Preencha todos os campos!");
        }

        try {
            const response = await ConfigService.changePassword(formData);
            
            setSucessMsg(response.data.message);

        } catch (error) {
            setError(error);
        }

    }

    const validationInputs = () => {
        setSucessMsg('');
        setError('');

        !password ? setIsPasswordFilled(true) : setIsPasswordFilled(false);
        if (!password) return passwordRef.current.focus();

        !newPassword ? setIsNewPasswordFilled(true) : setIsNewPasswordFilled(false);
        if (!newPassword) return newPasswordRef.current.focus();

        !confirmPassword ? setIsConfirmPasswordFilled(true) : setIsConfirmPasswordFilled(false);
        if (!confirmPassword) return confirmPasswordRef.current.focus();

        let message = validatePassword(newPassword, confirmPassword)
        if (message !== "") {
            //setError(message);
            setIsNewPasswordFilled(true);
            setIsConfirmPasswordFilled(true);
            newPasswordRef.current.focus();
            return  
        }
    
        return true;
    }

    return(
        <div>

            <Title>
                {screenWidth && screenWidth < 600 && (
                    <i className="bi bi-arrow-left-circle-fill" onClick={handleGoBack}></i>
                ) }
                <h3>Redefinir Senha</h3>
            </Title>
            
            <DivChangePassword>

                <form className='inputs-area'>
                    
                    <InputPassword 
                        name='password'
                        valueInput={password}
                        setData={setPassword}
                        placeholder='Senha Atual'
                        ariaDescribedby='Senha Atual'
                        refInput={passwordRef}
                        isValid={isPasswordFilled}
                    />

                    <InputPassword 
                        name='NewPassword'
                        valueInput={newPassword}
                        setData={setNewPassword}
                        placeholder='Nova Senha'
                        ariaDescribedby='Nova Senha'
                        refInput={newPasswordRef}
                        isValid={isNewPasswordFilled}
                    />

                    <InputPassword 
                        name='confirmPassword'
                        valueInput={confirmPassword}
                        setData={setConfirmPassword}
                        placeholder='Confirmar Senha'
                        ariaDescribedby='Confirmar Senha'
                        refInput={confirmPasswordRef}
                        isValid={isConfirmPasswordFilled}
                    />

                    <WarningInput valueWarning={error} />

                    <Button 
                        type={2}
                        placeholder='Redefinir senha'
                        ariaDescribedby='Redefinir senha'
                        className='btn-custom'
                    />

                    <SuccessInput valueSucess={sucessMsg} />
                </form>

                <div className='info-area'>
                    
                    <p className='title-info-change-password'><i className="bi bi-lock-fill"></i>SUA NOVA SENHA DEVE TER: </p>
                    <p className={`info-change-password ${validateUpperCase ? 'check' : ''}`}>Incluir caracteres maiúsculo e minúsculo</p>
                    <p className={`info-change-password ${validateNumber ? 'check' : ''}`}>Incluir pelo menos 1 número</p>
                    <p className={`info-change-password ${validateSymbol ? 'check' : ''}`}>Incluir pelo menos 1 símbolo</p>
                    <p className={`info-change-password ${validateLength ? 'check' : ''}`}>Ter pelo menos 8 caracteres</p>
                    <p className={`info-change-password ${validateIqual ? 'check' : ''}`}>Ser a mesma em ambos os campos</p>
                </div>

            </DivChangePassword>

        </div>
    )

}