import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { useState, useEffect } from 'react';

// Styles
import { DivCircleGraph } from './styles'

// Config Color
import color from '../../config/colorsGraph.json'

// Utils
import { getWidthHeightWindow } from '../../utils/utilsFunction'

// Components
import { InputSelectCustom } from '../Input';



export function CircleGraph({ title, options, data, typeColor }){
    
  const [ radius, setRadius ] = useState(140);

  const [ selectGraph, setSelectGraph ] = useState(1)

  useEffect(() => {
      const handleResize = () => {
          const { screenWidth } = getWidthHeightWindow();
          if (screenWidth <= 380) {
              setRadius(90);
          } else {
              setRadius(140);
          }
      };

      handleResize();

      window.addEventListener('resize', handleResize);

      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  return(
    <DivCircleGraph className={`${typeColor}`}>
      <div className='titleGraph container'>
        <h4>{title}</h4>
      </div>
      {options && options.length > 0 ? (
          <div className='container'>
            <InputSelectCustom 
              name='selectGraph'
              valueInput={selectGraph}
              setData={setSelectGraph}
              placeholder='Selecione um Bairro'
              ariaDescribedby='Selecione um Bairro'
              options={options}
            />
          </div>
        ) : (
          ''
        )
      }
      <div className='container areaGraph'>
          <div className='graphContainer'>
              <ResponsiveContainer>
                  <PieChart>
                      <Pie
                          data={data[selectGraph-1].data}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          outerRadius={radius}
                          fill="#8884d8"
                          dataKey="value"
                      >
                      {data[selectGraph-1].data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={color.colors[index % color.colors.length]} />
                      ))}
                      </Pie>
                  </PieChart>
              </ResponsiveContainer>
          </div>
          <div className={`subtitle ${typeColor}`}>
              <ul>
                  {data[selectGraph-1].data && data[selectGraph-1].data.map((info, index) => (
                      <li key={index}><div style={{background: color.colors[index] }}></div>{info.percentage}% - {info.name}</li> 
                  ))}
              </ul>
          </div>
      </div>
  </DivCircleGraph>
  )
}