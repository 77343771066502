import styled from "styled-components";
export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 90vh; /* Garante que o container tenha pelo menos a altura total da janela de visualização */
`;

export const Content = styled.div`
    background-color: ${props => props.theme.colors.background};
    min-height: calc(100vh - 163px);
    width: 90%;
    padding: 0 50px;
    margin: 50px auto 0 auto;
    position: relative;

    .paginationContent {
        position: absolute; 
        bottom: 0; 
        background-attachment: fixed;
        width: 90%;
        
    }
`;