import { styled } from 'styled-components';

export const DivRegister = styled.div`
    min-height: calc((100vh - 350px));
    width: 500px;

    .title-form {
        width: 70%;
        font-size: 1.2em;
        color: #${props => props.theme.colors.primary};
        font-weight: 800;
        text-align: center;
        margin: 0px auto;
        margin-top: 25px;
        margin-bottom: 25px;
    }
    
    .btn-custom {
        width: 100%;
        margin-top: 30px
    }

    .btn-custom-white {
        width: 100%;
        margin-top: 10px;
        margin-right: 5px;
        box-sizing: border-box;
    }

    .btn-white {
        background-color: #F4F4F4;
    }

    .area-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .custom-select {
        min-height: 50px;
    }

    @media screen and (max-width: 500px) {
        width: 420px;
    }

    @media screen and (max-width: 470px) {
        width: 100%;
    }

`;