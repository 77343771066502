import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { ThemeProvider } from 'styled-components';

// Theme configurations
import {
  defaultTheme,
  deuteranomalyTheme,
  protonatopyTheme,
  tritanopyTheme
} from '../config/colorBlindnessThemes';

// Service
import ColorBlindnessService from '../services/ColorBlindnessService';

// Utils
import { AuthContext } from './AuthContext';

const themes = {
  Padrao: defaultTheme,
  Deuteranopia: deuteranomalyTheme,
  Protanopia: protonatopyTheme,
  Tritanopia: tritanopyTheme,
};

export const ThemeContext = createContext({
  theme: themes.Padrao,
  updateTheme: () => {}
});

// ThemeContextProvider component
export const ThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = useState('Padrao');
  const { auth } = useContext(AuthContext);

  // Function to update the theme based on user preference
  const updateTheme = useCallback(async () => {
    if (!auth) return;
    try {
      const userColorBlindnessType = await ColorBlindnessService.GetColorBlindness();
      if (userColorBlindnessType == null) {
        setTheme('Padrao');
      } else {
        setTheme(userColorBlindnessType);
      }
    } catch (error) {
      console.error('Failed to fetch color blindness type:', error);
      setTheme('Padrao');
    }
  }, [auth]);

  // Effect to update theme on mount and when user changes
  useEffect(() => {
    updateTheme();
  }, [auth, updateTheme]);

  // Memoized provider value to avoid unnecessary renders
  const providerValue = useMemo(() => ({
    theme: themes[theme],
    updateTheme
  }), [theme, updateTheme]);

  // Render the theme provider with styled-components
  return (
    <ThemeContext.Provider value={providerValue}>
      <ThemeProvider theme={providerValue.theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

// Custom hook to consume the theme context
export const useThemeContext = () => useContext(ThemeContext);
