import { styled } from 'styled-components';

export const SectionFooter = styled.footer`
    background-color:  ${props => props.theme.colors.tertiary};
    height: 60px;
    color: ${props => props.theme.colors.background};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media screen and (max-width: 420px) {
        font-size: .7em;
    }

    @media screen and (max-width: 300px) {
        font-size: .6em;
    }
`