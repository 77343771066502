import React, { useState } from 'react';

// Components

// Styles
import { SidebarArea } from './styles';

export function SideBar({ setSelectedComponent, typeUser }) {

    const [selectedComponent, localSetSelectedComponent] = useState(typeUser === 'default' ? 'ChangeAddress' : 'ChangePassword');

    const handleComponentChange = (component) => {
        localSetSelectedComponent(component);
        setSelectedComponent(component);
    };

    return (
        <SidebarArea>
            <div className="d-flex flex-column flex-shrink-0 bg-white">
                <ul className="nav nav-pills flex-column mb-auto">
                  {typeUser === 'default' ? (
                    <li>
                        <a href="#" className={`nav-link text-black ${selectedComponent === 'ChangeAddress' ? 'active-link' : ''}`} onClick={() => handleComponentChange('ChangeAddress')}>
                            <i className="bi bi-lock-fill"></i>
                            Alterar Endereço
                        </a>
                    </li>
                    ) : (
                      ''
                    )
                  }
                  <li>
                      <a href="#" className={`nav-link text-black ${selectedComponent === 'ChangePassword' ? 'active-link' : ''}`} onClick={() => handleComponentChange('ChangePassword')}>
                          <i className="bi bi-key-fill"></i>
                          Alterar Senha
                      </a>
                  </li>
                  <li>
                      <a href="#" className={`nav-link text-black ${selectedComponent === 'Accessibility' ? 'active-link' : ''}`} onClick={() => handleComponentChange('Accessibility')}>
                          <i className="bi bi-universal-access-circle"></i>
                          Acessibilidade
                      </a>
                  </li>
                </ul>
            </div>
        </SidebarArea>
    )
}
