import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Styles
import { DivCarousel, DivLoading } from "./styles";

// Carousel Library
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Utils
import { getWidthHeightWindow } from '../../utils/utilsFunction'

// Components
import { Loading } from "../Loading";

// Redux
import { getBannersFeed } from "../../slices/bannerSlices";

export function CarouselFeed() {
    const { screenWidth } = getWidthHeightWindow();

    const dispatch = useDispatch()

    const { loadingBanner, bannerMobile, bannerDesktop } = useSelector((state) => state.banner);
    
    useEffect(() => {
        dispatch(getBannersFeed())
    }, [])

    const [ viewCarousel, setViewCarousel ] = useState(screenWidth > 600 ? true : false)

    const [imageHeight, setImageHeight] = useState(getImageHeight());

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        draggable: true,
        autoplay: true,
        autoplaySpeed: 5000
    }

    useEffect(() => {
        const handleResize = () => {
            const { screenWidth } = getWidthHeightWindow();
            if (screenWidth > 600) {
                setViewCarousel(true);
                setImageHeight(getImageHeight())
            } else {
                setViewCarousel(false);
                setImageHeight(null)
            }

        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (viewCarousel && bannerDesktop.length === 0 && !loadingBanner) return
    if (!viewCarousel && bannerDesktop.length === 0 && !loadingBanner) return

    return(
        <>
            { loadingBanner ? (
                <DivLoading style={{ height: imageHeight ? imageHeight : '' }}>
                    <div className="spinner-border text-primary" role="status"></div>
                </DivLoading>
            ) : (
                <DivCarousel style={{ height: imageHeight ? imageHeight : '' }}>
                    <div className="areaSlider">
                        {viewCarousel ? (
                            <Slider {...settings} className="area-img">
                                {bannerDesktop && bannerDesktop.map((image) => (
                                    <div key={image.id} >
                                        <img src={image.image} alt={image.description} />
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <Slider {...settings} className="area-img">
                                {bannerMobile && bannerMobile.map((image) => (
                                    <div key={image.id} >
                                        <img src={image.image} alt={image.description} />
                                    </div>
                                ))}
                            </Slider>
                        )}
                    </div>
                </DivCarousel>
            )}
        </>
    )
}

function getImageHeight() {
    const { screenWidth } = getWidthHeightWindow();

    const imageOriginalWidth = 1054;
    const imageOriginalHeight = 290;

    const imageWidth = screenWidth;
    const aspectRatio = imageOriginalWidth / imageOriginalHeight;
    const calculatedHeight = imageWidth / aspectRatio;

    return calculatedHeight
}
