import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Styles
import { DivRegister } from './styles';

// Components
import { ShowStep } from '../../components/ShowStep'
import { Button } from '../Button';

//Input 
import { Input, InputMask, InputSelect, InputPassword, InputCheckBox, WarningInput, InputRadioButton } from '../Input';

// Utils
import { formatDate } from '../../utils/utilsFunction';
import validator from 'email-validator';

// Redux
import { registerPerson, userValidate } from '../../slices/authSlices';
import { getOptionsGender, getOptionsMaritalStatus, getOptionsYesOrNo } from '../../slices/optionSlices';
import { getOptionsCitys, getOptionsStates, getOptionsCountry } from '../../slices/addressSlices';

// Config
import { schema, fieldsInOrder } from './config';

export function RegisterInformationPcd({handleNextStep, formData}) {

    const [ errorValidation, setErrorValidation ] = useState('')

    const [ firstName, setFirstName ] = useState(formData.firstName ? formData.firstName : '');
    const [ lastName, setLastName ] = useState(formData.lastName ? formData.lastName : '');
    const [ phoneNumber, setPhoneNumber ] = useState(formData.phoneNumber ? formData.phoneNumber : '');
    const [ cpf, setCpf ] = useState(formData.cpf ? formData.cpf : '');
    const [ dateOfBirth, setDateOfBirth ] = useState(formData.dateOfBirth ? formatDate(formData.dateOfBirth) : '');
    const [ maritalStatus, setMaritalStatus ] = useState(formData.maritalStatus ? formData.maritalStatus : '');
    const [ gender, setGender ] = useState(formData.gender ? formData.gender : '');
    const [ email, setEmail ] = useState(formData.email ? formData.email : '');
    const [ password, setPassword ] = useState(formData.password ? formData.password : '');
    const [ confirmPassword, setConfirmPassword ] = useState(formData.confirmPassword ? formData.confirmPassword : '');
    const [ termsUse, setTermsUse ] = useState(formData.termsUse ? formData.termsUse : '');
    const [ isPcd, setIsPcd ] = useState(formData.isPcd ? formData.isPcd : 1)

    const [country, setCountry] = useState('BR');
    const [state, setState] = useState('SC');
    const [city, setCity] = useState("Jaraguá do Sul");

    const filledInputs = {
        firstName: useState(false),
        lastName: useState(false),
        phoneNumber: useState(false),
        cpf: useState(false),
        dateOfBirth: useState(false),
        maritalStatus: useState(false),
        gender: useState(false),
        email: useState(false),
        password: useState(false),
        confirmPassword: useState(false),
        termsUse: useState(false),
        isPcd: useState(false),
        country: useState(false),
        state: useState(false),
        city: useState(false),
    };

    const dataRef = {
        firstName: useRef(null),
        lastName: useRef(null),
        phoneNumber: useRef(null),
        cpf: useRef(null),
        dateOfBirth: useRef(null),
        maritalStatus: useRef(null),
        gender: useRef(null),
        email: useRef(null),
        password: useRef(null),
        confirmPassword: useRef(null),
        termsUse: useRef(null),
        isPcd: useRef(null),
        country: useRef(null),
        state: useRef(null),
        city: useRef(null),
    };

    const [ stateSelected, setStateSelected ] = useState('SC')

    const [ userValidateCpf, setUserValidateCpf ] = useState('')
    const [ userValidateEmail, setUserValidateEmail ] = useState('')
    
    const { loading, error, isRegistered } = useSelector((state) => state.auth);
    const { maritalStatusOptions, genderOptions, yesOrNoOptions } = useSelector((state) => state.option);
    const { countryOptions, statesOptions, citysOptions } = useSelector((state) => state.address);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getOptionsMaritalStatus());
        dispatch(getOptionsGender());
        dispatch(getOptionsYesOrNo());
        dispatch(getOptionsCountry());
        dispatch(getOptionsStates());
        dispatch(getOptionsCitys(state));
    }, [])


    useEffect(() => {

        if (state !== stateSelected) {
            dispatch(getOptionsCitys(state));
            setStateSelected(state);
            setCity('');
        }
        
        setIsPcd(prevData => parseInt(prevData))

    }, [ state,  isPcd]);

    useEffect(() => {
        if (!isRegistered) {
            setUserValidateCpf(cpf)
            setUserValidateEmail(email)
        } else {
            dataRef['cpf'].current.focus();
            filledInputs['cpf'][1](true)
        }

    }, [ isRegistered, loading ])

    const handleNextStepRegister = async (e) => {
        e.preventDefault()
        Object.entries(filledInputs).forEach(([key, [_, setState]]) => setState(false));

        if ((( cpf!==userValidateCpf ) || ( email!==userValidateEmail )) && (cpf !== '' && email !== '') && ((validator.validate(email)))) {

            const dataUser = {
                cpf,
                email
            }
            
            dispatch(userValidate(dataUser));
        }

        if (isRegistered) {
            dataRef['cpf'].current.focus();
            filledInputs['cpf'][1](true)
            return
        }

        formData = {
            firstName,
            lastName,
            phoneNumber: phoneNumber.replace(/[()\s-]/g, ""),
            cpf: cpf.replace(/[.-]/g, ""),
            dateOfBirth,
            maritalStatus: maritalStatus ? maritalStatus : 0,
            gender: gender ? gender : 0,
            email,
            password,
            confirmPassword,
            dateOfBirth: formatDate(dateOfBirth, 1),
            isPcd: isPcd ? isPcd : 1,
        }

        schema
        .validate(formData, { abortEarly: false })
        .then(() => {
            setErrorValidation('')

            if (!isPcd) {

                if (!country) {
                    dataRef['country'].current.focus();
                    return filledInputs['country'][1](true)
                }

                if (!state) {
                    dataRef['state'].current.focus();
                    return filledInputs['state'][1](true)
                }

                if (!city) {
                    dataRef['city'].current.focus();
                    return filledInputs['city'][1](true)
                }

                if (!termsUse) {
                    dataRef['termsUse'].current.focus();
                    filledInputs['termsUse'][1](true)
                    return setErrorValidation('Você deve concordar com os termos de uso!')
                }

                formData = {
                    ...formData,
                    country,
                    state,
                    city
                }

                dispatch(registerPerson(formData))
            } else {

                if (!termsUse) {
                    dataRef['termsUse'].current.focus();
                    filledInputs['termsUse'][1](true)
                    return setErrorValidation('Você deve concordar com os termos de uso!')
                }

                handleNextStep(formData)
            }
        })
        .catch((error) => {
            if (error.name === "ValidationError") {
                setErrorValidation('')

                error.inner.some((validationError) => {
                    const { path, message  } = validationError;

                    if (fieldsInOrder.includes(path)) {

                        setErrorValidation(message)

                        if (path === 'confirmPassword' && !confirmPassword) setErrorValidation('')

                        dataRef[path].current.focus();
                        filledInputs[path][1](true)
                        return true;
                    }
                    
                    return false;
                });
            }
        });
        
    }

    return (
        <DivRegister style={{ minHeight: isPcd ? 'calc((100vh - 200px))' : 'auto' }}>
            <div className='showStep-custom'>
                <ShowStep 
                    stepsModal={isPcd ?
                        [{ title: '' }, { title: '' }, { title: '' }] :
                        []
                    } 
                    stageRegister={0}
                />
            </div>
            <h3 className="title-form">Vamos conhecer um pouco mais sobre você.</h3>
            <form onSubmit={handleNextStepRegister} noValidate>
                <Input 
                    type='text'
                    name='firstName'
                    valueInput={firstName}
                    setData={setFirstName}
                    placeholder='Primeiro Nome'
                    ariaDescribedby='Primeiro Nome'
                    isValid={filledInputs.firstName[0]}
                    refInput={dataRef.firstName}
                />
                <Input 
                    type='text'
                    name='lastName'
                    valueInput={lastName}
                    setData={setLastName}
                    placeholder='Sobrenome'
                    ariaDescribedby='Sobrenome'
                    isValid={filledInputs.lastName[0]}
                    refInput={dataRef.lastName}
                />
                <InputMask 
                    maskInput='(00) 00000-0000'
                    name='phoneNumber'
                    valueInput={phoneNumber}
                    setData={setPhoneNumber}
                    placeholder='Número Telefone'
                    ariaDescribedby='Número Telefone'
                    isValid={filledInputs.phoneNumber[0]}
                    refInput={dataRef.phoneNumber}
                />
                <div className='double-input'>
                    <InputMask 
                        maskInput='000.000.000-00'
                        name='cpf'
                        valueInput={cpf}
                        setData={setCpf}
                        placeholder='CPF'
                        ariaDescribedby='CPF'
                        isValid={filledInputs.cpf[0]}
                        refInput={dataRef.cpf}
                    />
                    <InputMask 
                        maskInput='00/00/0000'
                        name='dateOfBirth'
                        valueInput={dateOfBirth}
                        setData={setDateOfBirth}
                        placeholder='Data Nascimento'
                        ariaDescribedby='Data Nascimento'
                        isValid={filledInputs.dateOfBirth[0]}
                        refInput={dataRef.dateOfBirth}
                    />
                </div>
                <div className='double-input'>
                    <InputSelect 
                        name='maritalStatus'
                        valueInput={maritalStatus}
                        setData={setMaritalStatus}
                        placeholder='Estado Civil'
                        ariaDescribedby='Estado Civil'
                        isValid={filledInputs.maritalStatus[0]}
                        refInput={dataRef.maritalStatus}
                        options={maritalStatusOptions}
                    />
                    <InputSelect 
                        name='gender'
                        valueInput={gender}
                        setData={setGender}
                        placeholder='Gênero'
                        ariaDescribedby='Gênero'
                        isValid={filledInputs.gender[0]}
                        refInput={dataRef.gender}
                        options={genderOptions}
                    />
                </div>
                <Input 
                    type='email'
                    name='email'
                    valueInput={email}
                    setData={setEmail}
                    placeholder='E-mail'
                    ariaDescribedby='E-mail'
                    isValid={filledInputs.email[0]}
                    refInput={dataRef.email}
                />
                <div className='double-input'>
                    <InputPassword 
                        name='password'
                        valueInput={password}
                        setData={setPassword}
                        placeholder='Senha'
                        ariaDescribedby='Senha'
                        isValid={filledInputs.password[0]}
                        refInput={dataRef.password}
                    />
                    <InputPassword 
                        name='confirmPassword'
                        valueInput={confirmPassword}
                        setData={setConfirmPassword}
                        placeholder='Confirmar Senha'
                        ariaDescribedby='Confirmar Senha'
                        isValid={filledInputs.confirmPassword[0]}
                        refInput={dataRef.confirmPassword}
                    />
                </div>
                <InputRadioButton 
                    name='isPcd'
                    valueInput={isPcd}
                    setData={setIsPcd}
                    placeholder='Possui alguma necessidade especial?'
                    ariaDescribedby='Possui alguma necessidade especial?'
                    isValid={filledInputs.isPcd[0]}
                    refInput={dataRef.isPcd}
                    options={yesOrNoOptions}
                />
                {!isPcd ?
                    <>
                        <InputSelect 
                            className='select-custom'
                            name='country'
                            valueInput={country}
                            setData={setCountry}
                            placeholder='País'
                            ariaDescribedby='País'
                            options={countryOptions}
                            isValid={filledInputs.country[0]}
                            refInput={dataRef.country}
                            isDisabled={true}
                        />
                        <InputSelect 
                            className='select-custom'
                            name='state'
                            valueInput={state}
                            setData={setState}
                            placeholder='Estado'
                            ariaDescribedby='Estado'
                            isValid={filledInputs.state[0]}
                            refInput={dataRef.state}
                            options={statesOptions}
                        />
                        <InputSelect 
                            className='select-custom'
                            name='city'
                            valueInput={city}
                            setData={setCity}
                            placeholder='Cidade'
                            ariaDescribedby='Cidade'
                            isValid={filledInputs.city[0]}
                            refInput={dataRef.city}
                            options={citysOptions}
                        />
                    </>
                : ''}
                <InputCheckBox 
                    className='checkbox-custom'
                    name='termsUse'
                    valueInput={termsUse}
                    setData={setTermsUse}
                    placeholder='Eu concordo com os termos de uso'
                    ariaDescribedby='Eu concordo com os termos de uso'
                    isValid={filledInputs.termsUse[0]}
                    refInput={dataRef.termsUse}
                />
                <WarningInput valueWarning={error || errorValidation} />
                <Button
                    type={2}
                    placeholder={isPcd ? 'Continuar Cadastro' : 'Finalizar Cadastro'}
                    ariaDescribedby={isPcd ? 'Continuar Cadastro' : 'Finalizar Cadastro'}
                    className='btn-custom'
                    isLoading={loading}
                />
            </form>
        </DivRegister>
    )
}