

export const defaultTheme = {
    colors: {
        black: '#000000',
        primary: '#000083',
        secondary: '#0645F8',
        tertiary: '#110897',
        quaternary: '#2212AB',
        quinary: '#331BBF',
        senary: '#4424D3',
        septenary: '#0d6efd',
        background: '#ffffff',
        secondBackground: '#F1EFFB',
        tertiaryBackgrond: '#dbdbdb',
        plaholder: '#A89D9D',
        secondPlaceholder: '#999999',
        danger: '#FF0000',
        success: '#008000',
        borders: '#979797'
    }
};

export const protonatopyTheme = {
    colors: {
        black: '#000000', // Preto permanece inalterado
        primary: '#002083', // Adaptado para um azul mais escuro
        secondary: '#0645F8', // Adaptado para um azul-violeta claro
        tertiary: '#002097', // Adaptado para um azul médio
        quaternary: '#0032AB', // Adaptado para um azul levemente mais claro
        quinary: '#0041BF', // Adaptado para um azul claro
        senary: '#0054D3', // Adaptado para um azul muito claro
        septenary: '#0070fd', // Adaptado para um azul-violeta claro, similar ao secondary
        background: '#ffffff', // Branco permanece inalterado
        secondBacground: '#E1F0FB', // Lavanda mais claro, visando melhor contraste
        tertiaryBackgrond: '#afafaf',
        plaholder: '#8B8686', // Cinza-médio para melhor contraste
        secondPlaceholder: '#989DAD',
        danger: '#FF00FF ', // Marrom, visto que vermelho puro pode ser difícil de distinguir
        success: '#008080', // Verde escuro mais intenso para melhor contraste
        borders: '#8B8686'  // Cinza-médio para melhor contraste
    }

};

export const deuteranomalyTheme = {
    colors: {
        black: '#000000',  // Sem alteração, preto permanece preto
        primary: '#000083',  // Pode se tornar um pouco mais azul ou roxo, mas a diferença será mínima
        secondary: '#3251B6',  // Azul ajustado para menos verde
        tertiary: '#3E56B0',  // Azul profundo com menos verde
        quaternary: '#4A5CAD',  // Azul um pouco mais claro, ajustado para Deuteranopia
        quinary: '#5563A9',  // Azul claro, ajustado para menos verde
        senary: '#616AA5',  // Azul claro, mais ajustado para Deuteranopia
        septenary: '#6972A1',  // Azul claro, adaptado para Deuteranopia
        background: '#ffffff',  // Branco permanece branco
        secondBackground: '#EBE8F9', // Fundo leve ajustado para menos verde
        tertiaryBackgrond: '#dbafdb',
        placeholder: '#B2AFAF',  // Cinza adaptado para ser neutro
        secondPlaceholder: '#999999',
        danger: '#E60000',  // Vermelho adaptado para ser mais brilhante
        success: '#009900',  // Verde ajustado para ser mais escuro e menos saturado
        borders: '#8C8C8C'   // Cinza neutro, levemente ajustado
    }

};

export const tritanopyTheme = {
    colors: {
        black: '#000000',  // Sem alteração
        primary: '#7B0086',  // Ajustado para menos azul
        secondary: '#5E82F4',  // Azul mais claro, com menos influência verde/azul
        tertiary: '#7200A1',  // Ajustado para um violeta mais escuro
        quaternary: '#8700BB',  // Roxo mais vibrante
        quinary: '#9B00D6',  // Roxo claro
        senary: '#B000F0',  // Roxo mais claro ainda
        septenary: '#B366F6',  // Lavanda mais suave
        background: '#ffffff',  // Sem alteração
        secondBackground: '#FAF8FE', // Fundo muito claro, ajustado para menos azul
        tertiaryBackgrond: '#dbdbaf',
        placeholder: '#C8C3C3',  // Cinza claro
        secondPlaceholder: '#999999',
        danger: '#FF0000',  // Vermelho pode permanecer, visto que Tritanopia afeta azul e amarelo
        success: '#00A300',  // Verde mais escuro, ajustado para ser distinto
        borders: '#A5A5A5'   // Cinza médio
    }

}