import { styled } from 'styled-components';

export const DivNavBarFeed = styled.nav`
    width: 100%;
    //background-color: #110897;
    background-color:  ${props => props.theme.colors.tertiary};

    nav {
        justify-content: space-between;
    }

    .navbar-toggler {
        display: block;
    }

    .options {
        display: flex;
    }

    .navbar-toggler, .btn-close {
        border: none;
        outline: none;
        box-shadow: 0 0 0 0;
    }

    i {
        font-size: 1.5em;
    }

    .btn-custom {
        background-color: transparent;
        border: none;
        box-shadow: 0 0 0;
        //color: #FFFFFF;
        color:  ${props => props.theme.colors.background}
    }

    .offcanvas {
        width: 300px;
    }

    .logo-allcance {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }

    .offcanvas-body img, .logo-allcance img {
        width: 60%;
    }

    .offcanvas-body a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
        text-decoration: none;
        font-weight: 700;
        font-size: 1.1em;
        color:  ${props => props.theme.colors.tertiary};
    }


    .offcanvas-body ul a img {
        width: 20px;
        margin-right: 10px;
    }

    .offcanvas-body ul {
        width: 100%;
        margin: 0px;
        padding: 0px;
    }

    .offcanvas-body ul li a i {
        font-size: 1.2em;
        width: 20px;
        margin-right: 10px;
        color:  ${props => props.theme.colors.black};
    }


    .offcanvas-body li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        width: 100%;
        padding-left: 20px;
        height: 40px;
    }

    .offcanvas-body li:hover {
        background-color: #F1F0F0;
    }

    @media screen and (max-width: 600px) {
    
        .navbar-brand img {
            width: 70%;
        }

    }

    .division {
        border-bottom: 2px solid #DDDDDD;
        margin-bottom: 10px;
    }

`