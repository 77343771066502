import { styled } from 'styled-components';

export const DivRegister = styled.div`
    min-height: 300px !important;

    .title-form {
        width: 70%;
        font-size: 1.2em;
        color: ${props => props.theme.colors.primary};
        font-weight: 800;
        text-align: center;
        margin: 0px auto;
        margin-top: 25px;
        margin-bottom: 15px;
    }

    .checkbox-custom {
        margin-top: 15px;
    }

    .btn-custom {
        margin-top: 15px;
    }

    .double-input select  {
            margin-top: 15px;
    }

    .select-custom {
        margin-top: 15px;
    }

`