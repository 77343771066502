import React from 'react';
import { BrowserRouter } from 'react-router-dom';

// Routes
import { PrivateRoutes } from './PrivateRoutes';
import { PublicRoutes } from './PublicRoutes';

// Components
import { Footer } from '../components/Footer';

export function Routes() {
  return (
      <BrowserRouter>
          <PrivateRoutes />
          <PublicRoutes />
          <Footer />
      </BrowserRouter>
  );
}
