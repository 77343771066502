import styled from "styled-components";

export const SuggestionsArea = styled.div`
    display: block;
    background-color: ${props => props.theme.colors.background};
    min-height: calc(100vh - 210px);
    width: 100%;
    padding: 0 20px;
    margin: 50px auto 50px auto;

    .text-title {
        font-size: 25px;
        font-weight: bold;
        color: ${props => props.theme.colors.senary};

    }

    .title-area {
        display: flex;
        padding: 5px 0;
        //border-bottom: #979797 solid 1px;
        border-bottom: ${props => props.theme.colors.borders} solid 1px;
        width: 100%;
    }

    .description-area {
        min-height: auto;
        margin-top: 30px;
        text-align: justify;
    }

    .content-descriptions-area {
        font-size: 17px;
        //color: #000083;
        color: ${props => props.theme.colors.primary};
        text-align: start;
    }
    .button-area {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
        border: none;
    }

    .btn-custom {
        width: 200px;
    }

    .input-area {
        height: 148px;
        width: 100%;
        margin-top: 45px;
        border-radius: 15px;
        padding: 15px;
        font-size: 15px;
        resize: none;
        transition: background-color 0.3s, border-color 0.3s;
        border: 1px solid #979797;
        //border: ${props => props.theme.colors.borders} solid 1px;
        outline: none;
    }

    .input-area:focus {
        border-color: #9c9c9c;
        box-shadow: 0 0 0 1px rgba(0, 0, 131, 0.2);
    }

    .total-words{
        font-size: 15px;
        font-weight: bold;
        color: #979797;
        margin-left: 15px;
    }

    @media screen and (max-width: 767px) {
        padding: 0 10px ;
        text-align: center;
    }
`