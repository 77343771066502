import React, { useState, useEffect, useRef } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';

import { DivBarGraph, DivTooltip } from './styles'

import color from '../../config/colorsGraph.json';

// Components
import { InputSelectCustom } from '../Input';

export function BarGraph({ title, options, data, typeColor }) {

  const [ selectGraph, setSelectGraph ] = useState(1)

  const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
          const point = payload[0].payload;
          return (
              <DivTooltip className="custom-tooltip">
                  <p className="label">{`${point.name} : ${point.percentage.toFixed(1)}%`}</p>
                  <p className="desc">{point.description}</p>
              </DivTooltip>
          );
      }
      return null;
  };

  return (
    <DivBarGraph className={`${typeColor}`}>
      <div className='titleGraph container'>
        <h4>{title}</h4>
      </div>
      {options && options.length > 0 ? (
          <div className='container'>
            <InputSelectCustom 
              name='selectGraph'
              valueInput={selectGraph}
              setData={setSelectGraph}
              placeholder='Selecione um Bairro'
              ariaDescribedby='Selecione um Bairro'
              options={options}
            />
          </div>
        ) : (
          ''
        )
      }
      <div className='container areaGraph'>
          <div className='graphContainer'>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={data[selectGraph-1].data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="title" />
                <YAxis tickFormatter={(tick) => Number.isInteger(tick) ? tick : ''} />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Bar dataKey="value">
                  {color.colors.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className={`subtitle ${typeColor}`}>
              <ul>
                  {data[selectGraph-1].data && data[selectGraph-1].data.map((info, index) => (
                      <li key={index}><div style={{background: color.colors[index] }}></div>{info.percentage}% - {info.name.split('[')[0].trim()}</li> 
                  ))}
              </ul>
          </div>
      </div>
    </DivBarGraph>
  )
}
