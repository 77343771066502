import { styled } from 'styled-components';

export const DivCarousel = styled.div`
    width: 100%;
    padding: 0px;

    .slick-track{

        display: flex;
        align-items: center;

    }
    
    .slick-list {
      width: 100%;
    }

    .slick-slide{
      border-radius: 0px 0px 5px 5px;
      height: 225px;
      padding-bottom: 5px;
      overflow: hidden;
      box-sizing: border-box;
      border: 1px solid #D8D8D8;
    }

    .slick-current{
      width: 400px;
      height: 225px;
    }

    .title-card {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 5px 5px 0 0;
      background-color:  ${props => props.theme.colors.senary};
      color: white;
      padding: 10px 15px;
      font-size: 1.2em;
      font-weight: 600;
      margin-bottom: 0;
    }

    .title-card i {
      margin-right: 10px;
    }

    .card{
      border: none;
    }

    .slick-slide .slick-active .slick-center .slick-current {
      width: 30px !important;
    }
    
    .divBody {
        padding: 10px 15px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }

    .name-user {
      color: #000000;
      font-size: 1.2em;
      font-weight: 600;
      margin: 0px;
      background-color: #F8EFEF;
      border-radius: 5px;
      width: 100%;
      padding: 5px 15px;
    }

    .divBody p{
      margin: 0px;
      max-height: 108px;
      overflow: hidden;
      text-align: left;
    }

    .slick-current p{

        max-height: 155px;
        overflow: hidden;

    }


    @media screen and (max-width: 995px) {
      display: none;
    }

`