import { useState } from "react";

// Components Register Pcd
import { RegisterInformationPcd } from "../../components/RegisterInformationPcd";
import { RegisterAddressPcd } from "../../components/RegisterAddressPcd";
import { RegisterTypePcd } from "../../components/RegisterTypePcd";
import { RegisterInstitutionUser } from "../../components/RegisterInstitutionUser";

// Styles
import { DivFormRegister, NavBar } from "./styles";

export function Register({type}) {
    const [step, setStep] = useState(1);

    const [ typeUser, setTypeUser ] = useState(!!type ? type : 1)
    const [ formData, setFormData ] = useState({});

    const handleNextStep = (newData, beforeStep) => {
        setFormData(prevData => ({ ...prevData, ...newData }));

        !beforeStep ? setStep(step + 1) : setStep(step - 1) 

    }

    return(
        <>
            <DivFormRegister>
                <div className="area-register">
                    {getComponentStep(typeUser, step, handleNextStep, formData)}
                </div>
            </DivFormRegister>
        </>        
    )

}

function getComponentStep(typeUser, step, handleNextStep, formData) {

    if (typeUser == 1) {
        switch (step) {
            case 1:
                return <RegisterInformationPcd handleNextStep={handleNextStep} formData={formData} />
            case 2:
                return <RegisterAddressPcd handleNextStep={handleNextStep} formData={formData}/>
            case 3:
                return <RegisterTypePcd formData={formData} handleNextStep={handleNextStep}/>
            default:
                return <h3 style={{textAlign: "center"}}>Tente mais tarde.</h3>
        }
    } else if (typeUser === 2) {
        return <RegisterInstitutionUser />
    } else {
        <h3 style={{textAlign: "center"}}>Tente mais tarde.</h3>
    }

    return;
}