import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import OptionsService from "../services/OptionsServices";

const initialState = {
    errorOption: null,
    successOption: false,
    loadingOption: false,
    maritalStatusOptions: [],
    genderOptions: [],
    yesOrNoOptions: []
};

export const getOptionsMaritalStatus = createAsyncThunk(
    "auth/optionsMaritalStatus",
    async (_, thunkAPI) => {
        try {
            const response = await OptionsService.optionsMaritalStatus()
    
            return response;
        } catch(error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const getOptionsGender = createAsyncThunk(
    "auth/optionsGender",
    async (_, thunkAPI) => {
        try {
            const response = await OptionsService.optionsGender()
    
            return response;
        } catch(error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const getOptionsYesOrNo = createAsyncThunk(
    "auth/optionsYesOrNo",
    async (_, thunkAPI) => {
        try {
            const response = await OptionsService.optionsYesOrNo()
    
            return response;
        } catch(error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const optionSlices = createSlice({
    name: "option",
    initialState,
    reducers: {
        reset: (state) => {
            state.errorOption = null;
            state.successOption = false;
            state.loadingOption = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOptionsMaritalStatus.pending, (state) => {
                state.loadingOption = true;
            })
            .addCase(getOptionsMaritalStatus.fulfilled, (state, action) => {
                state.loadingOption = false;
                state.successOption = true;
                state.errorOption = null;
                state.maritalStatusOptions = action.payload
            })
            .addCase(getOptionsMaritalStatus.rejected, (state, action) => {
                state.loadingOption = false;
                state.errorOption = action.payload;
            })
            .addCase(getOptionsGender.pending, (state) => {
                state.loadingOption = true;
            })
            .addCase(getOptionsGender.fulfilled, (state, action) => {
                state.loadingOption = false;
                state.successOption = true;
                state.errorOption = null;
                state.genderOptions = action.payload
            })
            .addCase(getOptionsGender.rejected, (state, action) => {
                state.loadingOption = false;
                state.errorOption = action.payload;
            })
            .addCase(getOptionsYesOrNo.pending, (state) => {
                state.loadingOption = true;
            })
            .addCase(getOptionsYesOrNo.fulfilled, (state, action) => {
                state.loadingOption = false;
                state.successOption = true;
                state.errorOption = null;
                state.yesOrNoOptions = action.payload
            })
            .addCase(getOptionsYesOrNo.rejected, (state, action) => {
                state.loadingOption = false;
                state.errorOption = action.payload;
            })
    }
});

export const { reset } = optionSlices.actions;
export default optionSlices.reducer;