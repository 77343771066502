import { styled } from 'styled-components';

export const DivConfigInstitution= styled.div`
    min-height: calc( 100vh - 113px );

    h3 {
        margin-top: 30px;
        color: ${props => props.theme.colors.primary};
        font-weight: 700;
        border-bottom: 2px solid #D8D8D8;
        padding-bottom: 20px;
        padding-left: 20px;
    }

    h5 {
        margin-top: 15px;
        color: ${props => props.theme.colors.primary};
        font-weight: 700;
        padding-bottom: 20px;
        padding-left: 20px;
        font-size: 1.3em;
    }

    .area-inputs-config {
      border-radius: 15px;
      box-shadow: 0px 0px 5px #D8D8D8;
      margin-top: 50px;
      padding: 10px;
    }


`