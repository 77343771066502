import { styled } from 'styled-components';

export const DivForgotPassword = styled.div`
    box-sizing: border-box;
    min-height: calc( 100vh - 120px );

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .area-email {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px 20px;
        max-width: 500px;
        min-height: calc( 100vh - 500px );
        margin: 30px auto;
        box-sizing: border-box;
        border-radius: 20px;
        box-shadow: 1px 1px 6px ${props => props.theme.colors.secondBackground};
    }
    
    .area-title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 3;
    }

    img {
        width: 70%;
        margin-top: 20px;
    }

    h3 {
        font-weight: 800;
        color: ${props => props.theme.colors.primary};
        text-align: center;
    }

    .text-email {
        width: 70%;
        text-align: center;
    }

    form {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 4;
    }

    a {
        text-decoration: none;
    }

    .btn-custom {
        width: 100%;
        margin-top: 20px;
    }

    @media screen and (max-width: 525px) {
        .area-email {
            box-shadow: none;
        }
    }

`