import axios from 'axios';
import listOptionsDisability from '../components/RegisterTypePcd/listDisability.json';


async function typeDisabilityOptions() {
    try {
    
        const response = await listOptionsDisability.disability

        return  response;

    } catch (error) {

        throw "Não foi possível carregar as opções!";

    }
}

async function disabilityOptions(typeDisability) {
    try {
        const response = {};

        async function getData(){
            switch (typeDisability) {
                case "1":
                        response.disable = listOptionsDisability.typesDisability.fisica.disable;
                        response.multiple = listOptionsDisability.typesDisability.fisica.multiple;
                        response.optionsDisability = listOptionsDisability.typesDisability.fisica.disability;
                    break;
                case "2":
                        response.disable = listOptionsDisability.typesDisability.visual.disable;
                        response.multiple = listOptionsDisability.typesDisability.visual.multiple;
                        response.optionsDisability = listOptionsDisability.typesDisability.visual.disability;
                    break;
                case "3":
                        response.disable = listOptionsDisability.typesDisability.auditiva.disable;
                        response.multiple = listOptionsDisability.typesDisability.auditiva.multiple;
                        response.optionsDisability = listOptionsDisability.typesDisability.auditiva.disability;
                    break;
                case "4":
                        response.disable = listOptionsDisability.typesDisability.intelectual.disable;
                        response.multiple = listOptionsDisability.typesDisability.intelectual.multiple;
                        response.optionsDisability = listOptionsDisability.typesDisability.intelectual.disability;
                    break;
                case "5":
                        response.disable = listOptionsDisability.typesDisability.surdocegueira.disable;
                        response.multiple = listOptionsDisability.typesDisability.surdocegueira.multiple;
                        response.optionsDisability = listOptionsDisability.typesDisability.surdocegueira.disability;
                    break;
                case "6":
                        response.disable = listOptionsDisability.typesDisability.transtornosGlobais.disable;
                        response.multiple = listOptionsDisability.typesDisability.transtornosGlobais.multiple;
                        response.optionsDisability = listOptionsDisability.typesDisability.transtornosGlobais.disability;
                    break;
                case "7":
                        response.disable = listOptionsDisability.typesDisability.cronicasDegenerativas.disable;
                        response.multiple = listOptionsDisability.typesDisability.cronicasDegenerativas.multiple;
                        response.optionsDisability = listOptionsDisability.typesDisability.cronicasDegenerativas.disability;
                    break;
                case "8":
                        response.disable = listOptionsDisability.typesDisability.sindromeDown.disable;
                        response.multiple = listOptionsDisability.typesDisability.sindromeDown.multiple;
                        response.optionsDisability = [];
                    break;
                case "9":
                        response.disable = listOptionsDisability.typesDisability.multiplo.disable;
                        response.multiple = listOptionsDisability.typesDisability.multiplo.multiple;
                        response.optionsDisability = listOptionsDisability.typesDisability.multiplo.disability;
                    break;
                default:
                        response.disable = true;
                    break;
            }
        }

        await getData();

        response.optionsDisability = response.optionsDisability.map(option => ({
            id: option.id,
            value: option.value,
            label: option.value
        }));

        return response;
    } catch (error) {
        throw "Não foi possível carregar as opções!";
    }
}

const DisabilityService = {
    typeDisabilityOptions,
    disabilityOptions
};

export default DisabilityService;