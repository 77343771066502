import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

// Styles
import { DivRegister } from './styles';

// Inputs
import { InputRadioButton, InputSelect, InputMultiSelect, WarningInput } from '../Input';
import { Button } from '../Button';

// Components
import { ShowStep } from '../ShowStep';

// Services
import DisabilityService from '../../services/DisabilityService';
import OptionsService from '../../services/OptionsServices';

// Redux
import { registerDisabledPerson } from '../../slices/authSlices'

export function RegisterTypePcd({handleNextStep, formData}) {

    const { loading } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [color, setColor] = useState(formData.color ? formData.color : '');
    const [job, setJob] = useState(formData.job ? formData.job : '');
    const [typeDisability, setTypeDisability] = useState(formData.pcdType ? formData.pcdType : '');
    const [disability, setDisability] = useState(formData.pcd ? formData.pcd : '');
    const [disabilityAcquired, setDisabilityAcquired] = useState(formData.pcdAcquired ? formData.pcdAcquired : '');
    const [neededHelp, setNeededHelp] = useState(formData.neededAssistance ? formData.neededAssistance : '');
    const [transport, setTransport] = useState(formData.getTransport ? formData.getTransport : '');
    const [accessibilityTransport, setAccessibilityTransport] = useState(formData.transportAccess ? formData.transportAccess : '');

    const [isRaceFilled, setIsRaceFilled] = useState(false);
    const [isHasJobFilled, setIsHasJobFilled] = useState(false);
    const [isTypeDisabilityFilled, setIsTypeDisabilityFilled] = useState(false);
    const [isDisabilityFilled, setIsDisabilityFilled] = useState(false);
    const [isDisabilityAcquiredFilled, setIsDisabilityAcquiredFilled] = useState(false);
    const [isNeededHelpFilled, setIsNeededHelpFilled] = useState(false);
    const [isTransportFilled, setIsTransportFilled] = useState(false);
    const [isAccessibilityTransportFilled, setIsAccessibilityTransportFilled] = useState(false)

    const raceRef = useRef(null);
    const hasJobRef = useRef(null);
    const typeDisabilityRef = useRef(null);
    const disabilityRef = useRef(null);
    const disabilityAcquiredRef = useRef(null);
    const neededHelpRef = useRef(null);
    const transportRef = useRef(null);
    const accessibilityTransportRef = useRef(null);
;
    const [ optionsYesOrNo, setOptionsYesOrNo ] = useState([]);
    const [ raceOptions, setRaceOptions ] = useState([]);
    const [ typeDisabilityOptions, setTypeDisabilityOptions ] = useState([]);
    const [ disabilityOptions, setDisabilityOptions ] = useState([]);

    const [ selected, setSelected ] = useState([]);
    const [ multiple, setMultiple ] = useState(false);
    const [ disabled, setDisabled ] = useState(false);

    const [ error, setError ] = useState('');

    useEffect(() => {

        const getOptions = async () => {

            try {

                const responseTypeDisability = await DisabilityService.typeDisabilityOptions();
                setTypeDisabilityOptions(responseTypeDisability);

                const responseOptionsRaces = await OptionsService.optionsRaces(); 
                setRaceOptions(responseOptionsRaces);

                const responseYesOrNo = await OptionsService.optionsYesOrNo()
                setOptionsYesOrNo(responseYesOrNo)

            } catch(err) {
                setError(err);
            }

        }

        getOptions();

    }, [])

    useEffect(() => {

        const getOptions = async () => {

            try {

                const idDisability = typeDisability ? typeDisability : '1'

                const responseDisability = await DisabilityService.disabilityOptions(idDisability);
                setDisabilityOptions(responseDisability.optionsDisability)
                setMultiple(responseDisability.multiple)
                setDisabled(responseDisability.disable)

                setSelected([]);
                setDisability('')

            } catch(err) {
                setError(err);
            }

        }

        getOptions();

    }, [typeDisability])

    const validationInputs = () => {
        setError('');

        !color ? setIsRaceFilled(true) : setIsRaceFilled(false);
        if (!color) return raceRef.current.focus();
    
        !job ? setIsHasJobFilled(true) : setIsHasJobFilled(false);
        if (!job) return hasJobRef.current.focus();
    
        !typeDisability ? setIsTypeDisabilityFilled(true) : setIsTypeDisabilityFilled(false);
        if (!typeDisability) return typeDisabilityRef.current.focus();

        if (parseInt(typeDisability) !== 8) {
            !disability&&!multiple ? setIsDisabilityFilled(true) : setIsDisabilityFilled(false);
            if (!disability&&!multiple) return disabilityRef.current.focus();
        
            if (selected.length === 0 && multiple) return setError("Selecione pelo menos um tipo de deficência!");
        }

        !disabilityAcquired ? setIsDisabilityAcquiredFilled(true) : setIsDisabilityAcquiredFilled(false);
        if (!disabilityAcquired) return disabilityAcquiredRef.current.focus();
    
        !transport ? setIsTransportFilled(true) : setIsTransportFilled(false);
        if (!transport) return transportRef.current.focus();

        if (transport === '1') {
            !accessibilityTransport ? setIsAccessibilityTransportFilled(true) : setIsAccessibilityTransportFilled(false);
            if (!accessibilityTransport) return accessibilityTransportRef.current.focus();
        }

        !neededHelp ? setIsNeededHelpFilled(true) : setIsNeededHelpFilled(false);
        if (!neededHelp) return neededHelpRef.current.focus();
    
        return true;
    };

    const handleNextStepRegister = async (e) => {
        e.preventDefault();

        if (loading) return

        formData = {
            ...formData,
            color: color,
            job: job,
            pcdType: typeDisability,
            pcd: multiple ? selected : disability,
            pcdAcquired: disabilityAcquired,
            neededAssistance: neededHelp,
            getTransport: transport,
            transportAccess: accessibilityTransport ? accessibilityTransport : false
        }

        console.log(formData)

        if (!validationInputs()) {
            return
        }

        if (parseInt(typeDisability) === 8) {
            formData.pcd = []
        }

        try {

            dispatch(registerDisabledPerson(formData))

            navigate('/')

        } catch(err) {
            setError(err);
        }

    }

    const handleBeforeStep = (e) => {
        e.preventDefault()
        
        formData = {
            ...formData,
            color: color,
            job: job,
            pcdType: typeDisability,
            pcd: multiple ? selected : disability,
            pcdAcquired: disabilityAcquired,
            neededAssistance: neededHelp,
            getTransport: transport,
            transportAccess: accessibilityTransport
        }

        handleNextStep(formData, 1)
    }

    return (
        <DivRegister>
            <ShowStep 
                stepsModal={[{title: ''}, {title: ''}, {title: ''}]} 
                stageRegister={2}
            />
            <h3 className="title-form">Vamos conhecer um pouco mais sobre você.</h3>
            <form onSubmit={handleNextStepRegister}>
                <InputSelect 
                    name='color'
                    valueInput={color}
                    setData={setColor}
                    placeholder='Raça/Cor'
                    ariaDescribedby='Raça/Cor'
                    refInput={raceRef}
                    options={raceOptions}
                    isValid={isRaceFilled}
                />
                <InputRadioButton 
                    name='job'
                    valueInput={job}
                    setData={setJob}
                    placeholder='Possui Emprego?'
                    ariaDescribedby='Possui Emprego?'
                    refInput={hasJobRef}
                    isValid={isHasJobFilled}
                    options={optionsYesOrNo}
                />
                <InputSelect 
                    name='typeDisability'
                    valueInput={typeDisability}
                    setData={setTypeDisability}
                    placeholder='Tipo Deficiência'
                    ariaDescribedby='Tipo Deficiência'
                    refInput={typeDisabilityRef}
                    options={typeDisabilityOptions}
                    isValid={isTypeDisabilityFilled}
                    className='custom-select'
                />
                {multiple ? (
                        <InputMultiSelect 
                            name={disability}
                            valueInput={selected}
                            setData={setSelected}
                            placeholder='Selecione...'
                            ariaDescribedby='Tipo Deficiência'
                            options={disabilityOptions}
                            className='custom-select'
                        />
                    ) : (
                        <InputSelect 
                            name='disability'
                            valueInput={disability}
                            setData={setDisability}
                            placeholder='Tipo Deficiência'
                            ariaDescribedby='Tipo Deficiência'
                            refInput={disabilityRef}
                            options={disabilityOptions}
                            isValid={isDisabilityFilled}
                            isDisabled={disabled}
                            className='custom-select'
                        />
                    )
                }
                <InputRadioButton 
                    name='disabilityAcquired'
                    valueInput={disabilityAcquired}
                    setData={setDisabilityAcquired}
                    placeholder='Deficência Adquirida?'
                    ariaDescribedby='Deficência Adquirida?'
                    refInput={disabilityAcquiredRef}
                    isValid={isDisabilityAcquiredFilled}
                    options={optionsYesOrNo}
                />
                <InputRadioButton 
                    name='transport'
                    valueInput={transport}
                    setData={setTransport}
                    placeholder='Você utiliza transporte público?'
                    ariaDescribedby='Você utiliza transporte público?'
                    refInput={transportRef}
                    isValid={isTransportFilled}
                    options={optionsYesOrNo}
                />
                {transport === '1' ? (
                    <InputRadioButton 
                        name='accessibilityTransport'
                        valueInput={accessibilityTransport}
                        setData={setAccessibilityTransport}
                        placeholder='Você tem acessibilidade no uso do transporte público?'
                        ariaDescribedby='Você tem acessibilidade no uso do transporte público?'
                        refInput={accessibilityTransportRef}
                        isValid={isAccessibilityTransportFilled}
                        options={optionsYesOrNo}
                    />
                ) : ('')
                }
                <InputRadioButton 
                    name='neededHelp'
                    valueInput={neededHelp}
                    setData={setNeededHelp}
                    placeholder='Precisou de assistência para cadastrar?'
                    ariaDescribedby='Precisou de assistência para cadastrar?'
                    refInput={neededHelpRef}
                    isValid={isNeededHelpFilled}
                    options={optionsYesOrNo}
                />
                <WarningInput valueWarning={error} />
                <div className='area-btn'>
                    <Button 
                        type={2}
                        placeholder='Finalizar Cadastro'
                        ariaDescribedby='Finalizar Cadastro'
                        classDivButton='btn-custom'
                    />
                </div>
            </form>
            <Button 
                type={1}
                placeholder='Voltar'
                ariaDescribedby='Voltar'
                classDivButton='btn-custom-white'
                className='btn-white'
                handleFunction={handleBeforeStep}
                isLoading={loading}
            />
        </DivRegister>
    )

}