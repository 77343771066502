import { styled } from 'styled-components';

export const SidebarArea = styled.div`

    width: 100%;
    max-width: 250px;
    padding-right: 10px;
    border-right: 1px solid ${props => props.theme.colors.borders};

    .nav{
        width: 100%;
    }

    .active-link{
        background-color: ${props => props.theme.colors.secondBackground};
        border-radius: 0;
    }

    .nav-link{
        font-size: 1.1em;
    }

    .bi {
        font-size: 20px;
        margin-right: 10px;
    }

    @media screen and (max-width: 600px) {
        
        max-width: none;
        border: none;
        padding: 0;

        .active-link{
            background-color: transparent;
        }

        .nav-link{
            border-bottom: 1px solid ${props => props.theme.colors.black};
            border-radius: 0;
            margin-bottom: 15px;
        }

    }
    
`