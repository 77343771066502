import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

// Styles
import { DivInstitutions, DivHeader, DivBody } from './styles';

// Redux
import { getHighlightedInstitutions } from '../../slices/institutionSlices';

// Components
import { Loading } from '../Loading';

export function HighlightedInstitutions() {

  const dispatch = useDispatch()

  const { loadingInstitution, highlightedInstitutions } = useSelector((state) => state.institution);

  useEffect(() => {
      dispatch(getHighlightedInstitutions());
  }, [])

  if (highlightedInstitutions.length === 0 && !loadingInstitution) return

  return (

    <DivInstitutions>

      <DivHeader>
        <i className="bi bi-trophy-fill"></i>
        Destaques Institucionais
      </DivHeader>

      <DivBody className='body'>

        {loadingInstitution ? (
          <Loading />
        ) : (
          <ul className='list'>

            {highlightedInstitutions.map((institution, index) => (

              <li key={index} className='item-in-list'>
                <span className='num-in-list'>{index + 1}º</span>
                <img src={ institution.profile_photo ? institution.profile_photo : '/image/user-profile-default.png' } className='img-logo' alt={institution.user_name} />
                <span className='name'>{institution.user_name}</span>
              </li>
              
            ))}

          </ul>
        )}
      </DivBody>
    </DivInstitutions>
  );
}
