import React, { useState, useEffect } from 'react';

//Styles
import { SectionSupporter } from "./styles";

// Image
import LogoComped from './Assets/logo-comped.png'
import LogoJaragua from './Assets/logo-jaragua.png'
import LogoSenai from './Assets/logo-senai.png'

// Carousel Library
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export function Carousel(){
  const [ slidesToShow, setSlidesToShow ] = useState(3);
  const [ autoPlay, setAutoPlay ] = useState(false);

  useEffect(() => {
      function calculateSlidesToShow() {
          if (window.innerWidth > 1100) {
              return 3;
          } else if (window.innerWidth > 800) {
              return 2;
          } else {
              return 1;
          }
      }

      function handleResize() {
          const newSlidesToShow = calculateSlidesToShow();
          if (newSlidesToShow !== slidesToShow) {
              setSlidesToShow(newSlidesToShow);
              setAutoPlay(newSlidesToShow !== 3 ? true : false);
          }
      }

      handleResize();

      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, [slidesToShow]);

  const settings = {
      dots: autoPlay,
      infinite: true,
      speed: 500,
      slidesToScroll: slidesToShow,
      draggable: autoPlay,
      slidesToShow: slidesToShow,
      autoplay: autoPlay,
      autoplaySpeed: 5000
  };

  return(
      <SectionSupporter id="Partners">
          <div className="container">
              <h3 tabIndex={0}>Parceiros</h3>
              <div className="container-logo">
                  <Slider {...settings} className="area-logo">
                      <div>
                          <img tabIndex={0} src={LogoSenai} alt="Logo UniSenai" />
                      </div>
                      <div>
                          <img src={LogoJaragua} alt="Logo Jaraguá do Sul" />
                      </div>
                      <div>
                          <img tabIndex={0} src={LogoComped} alt="Logo Comped" />
                      </div>
                  </Slider>
              </div>
          </div>
      </SectionSupporter>
  );
}
