import apiAuth from '../utils/apiAuth';
import { convertCamelCaseToSnakeCase } from '../utils/utilsFunction';


async function makeRequest(route, dataUser) {
    try {
        const objUser = convertCamelCaseToSnakeCase(dataUser);
        const api = apiAuth();
        const response = await api.post(`/${route}`, objUser);
        return response;
    } catch (error) {
        throw error.response.data.message;
    }
}

const ConfigService = {
    changeAddress: async (dataUser, token) => makeRequest('address/update-address', dataUser, token),
    changePassword: async (dataUser, token) => makeRequest('password/reset-password', dataUser, token),
    changeAccessibilityConfig: async (dataUser, token) => makeRequest('configuration/create', dataUser,token)
};

export default ConfigService;