import { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useSelector, useDispatch } from "react-redux";
import { Outlet } from 'react-router-dom';

// Styles
import { DivNavBarFeed } from './styles';

// Images
import WhiteLogo from './Assets/Images/logo-white.png'
import BlueLogo from './Assets/Images/logo-blue.png'
import DataIcon from './Assets/Images/data-icon.png'
import SuggestionIcon from './Assets/Images/suggestion.png'
import LogOutIcon from './Assets/Images/logout.png'

// Components
import { SearchInput } from '../SearchInput';

// Redux
import { logout, reset } from '../../slices/authSlices'
import persistor from '../../persistor';

export function PrivateNavBar() {

  const { user } = useSelector((state) => state.auth);

  const [isInstitution, setIsInstitution] = useState(user.userType.type === 'instituion' ? true : false)
  const [isAdmin, setIsAdmin] = useState(user.userType.type === 'admin' ? true : false)
  const [isDefault, setIsDefault] = useState(user.userType.type === 'default' ? true : false)

  const dispatch = useDispatch();

  const handleLogOut = async () => {
    dispatch(reset())
    dispatch(logout())
    await persistor.purge();
    document.body.style.overflow = '';
    document.body.style.paddingRight = '0px';
  }

  return (
    <>
      <DivNavBarFeed>
        <nav className="navbar navbar-expand-lg container">
          <HashLink className="navbar-brand" to='/'>
            <img src={WhiteLogo} alt="Logo Allcance" />
          </HashLink>
          <div className='options'>
            <SearchInput />
            <button className="btn-custom" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="Menu Opções" aria-label="Menu Opções">
              <i className="bi bi-list"></i>
            </button>
          </div>
        </nav>
        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
          <div className="offcanvas-header">
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <ul>
              {/** Aprovar sugestões */}
              {(isAdmin &&
                <li>
                  <button className="btn-custom" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-label='Aprovar sugestão'>
                    <HashLink to="/content-manager">
                      <i className="bi bi-check-circle-fill"></i>
                      <text> Aprovar sugestões </text>
                    </HashLink>
                  </button>

                </li>
              )}
              {(isAdmin || isInstitution) &&
                <li>
                  <button className="btn-custom" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-label='Cadastrar Postagem'>
                    <HashLink to="/post-register">
                      <i className="bi bi-file-post-fill"></i>
                      Cadastrar Postagem
                    </HashLink>
                  </button>
                </li>
              }
              <li>
                <button className="btn-custom" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-label='Senso'>
                  <HashLink to="/dashboard" >
                    <img src={DataIcon} alt="Censo" />
                    Censo
                  </HashLink>
                </button>
              </li>

              <li className='division'>
                <button className="btn-custom" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-label='Sugestão'>
                  <HashLink to="/suggestion">
                    <img src={SuggestionIcon} alt="Sugestão" />
                    Sugestão
                  </HashLink>
                </button>
              </li>
              {isAdmin &&
                <li>
                  <button className="btn-custom" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-label='Configurar Instituição'>
                    <HashLink to="/configuration-institution">
                      <i className="bi bi-person-fill-gear"></i>
                      Configuração Instituição
                    </HashLink>
                  </button>
                </li>
              }
              <li className='division'>
                <button className="btn-custom" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-label='Configurações'>
                  <HashLink to="/user-panel">
                    <i className="bi bi-gear-fill"></i>
                    Configurações
                  </HashLink>
                </button>
              </li>
              <li onClick={handleLogOut}>
                <HashLink>
                  <img src={LogOutIcon} alt="Sair" />
                  Sair
                </HashLink>
              </li>
            </ul>
          </div>
          <button className="btn-custom" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-label='Sair'>
            <HashLink to="/" className='logo-allcance'>
              <img src={BlueLogo} alt="Logo Allcance" />
            </HashLink>
          </button>
        </div>
      </DivNavBarFeed>
      <Outlet />
    </>
  )

}