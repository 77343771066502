import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Styles
import { DivCarousel, DivCard } from "./styles";

// Carousel Library
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Redux
import { getSuggestion } from "../../slices/suggestionSlices";

// Components
import { Loading } from "../Loading";

export function CarouselSuggestions() {

  const dispatch = useDispatch();

  const { loadingSuggestion, suggestion } = useSelector((state) => state.suggestion);

  useEffect(() => {
      dispatch(getSuggestion())
  }, [])

  const settings = {
    className: "center",
    slidesToShow: 1,
    infinite: true,
    speed: 200,
    autoplay: true,
    autoplaySpeed: 5000,
    centerPadding: "0px",
    arrows: false,
    draggable: false
  };
  
  if (suggestion.length === 0 && !loadingSuggestion) return

  return(
      <DivCarousel className="slider-container container">

        <div className="title-card">
        <i className="bi bi-lightbulb-fill"></i>
          Sugestões
        </div>

        {loadingSuggestion ? (
          <Loading />
        ) : (
          <Slider {...settings}>
            {suggestion.map((suggestion, index) => (
                <div className="card" key={index}>
                    <div className='divBody'>
                      <p className="name-user">{suggestion.user}</p>
                      <br />
                      <p>{suggestion.content}</p>
                    </div>
                </div>
            ))}
          </Slider>
        )}
      </DivCarousel>
  );
}