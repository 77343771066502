import React, { useState } from 'react';

// Components
import { IMaskInput } from 'react-imask';
import { MultiSelect } from "react-multi-select-component";
import Select from 'react-select'

// Styles
import {
  DivInput,
  DivSelect,
  DivPassword,
  DivCheckBox,
  DivWarning,
  DivSuccess,
  DivRadioButton,
  DivMultiSelect,
  DivTextArea,
  DivFile,
  DivSelectCustom,
  DivSelectSearch
} from './styles';

export function Input({ type, name, valueInput, setData, placeholder, ariaDescribedby, refInput, isValid, className, isDisabled }) {

  return (
    <DivInput className={`form-floating mb-1 ${className}`}>
      <input
        type={type}
        className={`form-control ${isValid ? 'is-invalid' : ''}`}
        value={valueInput}
        id={name}
        placeholder={placeholder}
        name={name}
        onChange={(e) => setData(e.target.value)}
        autoComplete='off'
        aria-describedby={ariaDescribedby}
        ref={refInput}
        disabled={isDisabled}
      />
      <label htmlFor={name}>{placeholder}</label>
    </DivInput>
  );
}

export function InputMask({ maskInput, name, valueInput, setData, placeholder, ariaDescribedby, refInput, isValid, onBlur, isDisabled }) {

  return (
    <DivInput className={`form-floating mb-1 ${isDisabled ? 'placeholder-custom' : ''}`}>
      <IMaskInput
        style={{ backgroundColor: isDisabled ? 'rgb(233, 236, 239)' : '' }}
        mask={maskInput}
        value={valueInput}
        className={`form-control ${isValid ? 'is-invalid' : ''}`}
        name={name}
        id={name}
        placeholder={placeholder}
        autoComplete="off"
        aria-describedby={ariaDescribedby}
        inputRef={refInput}
        onAccept={(e) => setData(e)}
        onBlur={onBlur}
        disabled={isDisabled}
      />
      <label
        htmlFor={name}
      >{placeholder}</label>
    </DivInput>
  )
}

export function InputSelect({ name, valueInput, setData, placeholder, ariaDescribedby, refInput, options, isValid, isDisabled, className }) {

  return (
    <DivSelect className={`input-group mb-2 ${className}`}>
      <select
        className={`form-select ${isValid ? 'is-invalid' : ''}`}
        value={valueInput}
        name={name}
        onChange={(e) => setData(e.target.value)}
        aria-describedby={ariaDescribedby}
        ref={refInput}
        disabled={isDisabled}
      >
        <option hidden>{placeholder}</option>
        {options && options.map((option) => (
          <option
            value={option.id}
            key={option.id}
          >
            {option.value}
          </option>
        ))}
      </select>
    </DivSelect>
  )

}

export function InputPassword({ name, valueInput, setData, placeholder, ariaDescribedby, refInput, isValid }) {

  const [viewPassword, setViewPassword] = useState(true)

  const handleViewPassword = () => {
    setViewPassword(!viewPassword)
  }

  return (
    <DivPassword className='form-floating mb-1'>
      <input
        type={viewPassword ? 'password' : 'text'}
        className={`form-control ${isValid ? 'is-invalid' : ''}`}
        id={name}
        name={name}
        value={valueInput}
        placeholder={placeholder}
        onChange={(e) => setData(e.target.value)}
        autoComplete='off'
        aria-describedby={ariaDescribedby}
        ref={refInput}
      />
      <label htmlFor={name}>{placeholder}</label>
      <i className={`lnr-eye bi ${viewPassword ? 'bi-eye-slash-fill' : 'bi-eye-fill'}`} onClick={handleViewPassword} />
    </DivPassword>
  )

}

export function InputCheckBox({ name, valueInput, setData, placeholder, ariaDescribedby, refInput, isValid, className }) {

  return (
    <DivCheckBox className={`form-check mb-2 ${className}`}>
      <input
        className={`form-check-input ${isValid ? 'is-invalid' : ''}`}
        type="checkbox"
        value={valueInput}
        onChange={(e) => setData(e.target.checked)}
        id={name}
        name={name}
        aria-describedby={ariaDescribedby}
        ref={refInput}
      />
      <label className="form-check-label" htmlFor={name}>
        {placeholder}
      </label>
    </DivCheckBox>
  )

}

export function WarningInput({ valueWarning, flagCenter }) {

  let objStyle = {}

  if (flagCenter) {
    objStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }

  return (

    <DivWarning style={objStyle}>
      {valueWarning ? valueWarning : ''}
    </DivWarning>

  )

}

export function SuccessInput({ valueSucess }) {

  return (

    <DivSuccess>
      {valueSucess ? valueSucess : ''}
    </DivSuccess>

  )

}

export function InputRadioButton({ name, valueInput, setData, placeholder, ariaDescribedby, refInput, isValid, options, className }) {

  return (
    <DivRadioButton className={`${className}`}>
      <label>{placeholder}</label>
      <div className='area-input'>
        {options && options.map((option) => (
          <label className='label-radio' key={option.id}>
            <input
              name={name}
              type="radio"
              className={`form-check-input ${isValid ? 'is-invalid' : ''}`}
              value={option.id}
              defaultChecked={valueInput}
              aria-describedby={ariaDescribedby}
              onClick={(e) => setData(e.target.value)}
              ref={refInput}
            />
            <span>{option.value}</span>
          </label>
        ))}
      </div>
    </DivRadioButton>
  )

}

export function InputMultiSelect({ name, valueInput, setData, placeholder, ariaDescribedby, options, isValid, className }) {

  return (
    <DivMultiSelect className={className}>
      <MultiSelect
        options={options}
        value={valueInput}
        onChange={setData}
        labelledBy={ariaDescribedby}
        placeholder={placeholder}
        name={name}
        overrideStrings={{
          search: 'Buscar',
          selectAll: 'Selecionar tudo',
          noOptions: 'Opção Não Encontrada!',
          selectSomeItems: 'Tipo Deficiência',
          selectAllFiltered: 'Selecionar todos (filtrado)',
          allItemsAreSelected: "Todos os itens selecionados.",
          clearSearch: "Limpar Busca",
          clearSelected: "Limpar Selecionados",
        }}
        className={`mb-1 ${isValid ? 'is-invalid' : ''}`}
      />
    </DivMultiSelect>

  )

}

export function TextArea({ name, valueInput, setData, placeholder, ariaDescribedby, refInput, isValid, rows, max, min, className }) {

  return (
    <DivTextArea className={`form-floating mb-1 ${className}`}>
      <textarea
        id={name}
        placeholder={placeholder}
        value={valueInput}
        onChange={(e) => setData(e.target.value)}
        ref={refInput}
        aria-describedby={ariaDescribedby}
        className={`form-control ${isValid ? 'is-invalid' : ''}`}
        maxLength={max}
        rows={rows}
        minLength={min}
        style={{ height: `calc(1.5em * ${rows})` }}
      />
      <label htmlFor={name}>{placeholder}</label>
    </DivTextArea>
  )
}

export function FileInput({ name, onChange, placeholder, ariaDescribedby, refInput, isValid, className }) {
  return (
    <DivFile className={`${className}`}>
      <label htmlFor={name}>{placeholder}</label>
      <input
        type="file"
        id={name}
        onChange={onChange}
        ref={refInput}
        aria-describedby={ariaDescribedby}
        className={`form-control ${isValid ? 'is-invalid' : ''}`}
        accept='image/jpeg, image/png'
      />
    </DivFile>
  );
}

export function InputSelectCustom({ name, valueInput, setData, placeholder, ariaDescribedby, refInput, options, isValid, isDisabled, className }) {

  return (
    <DivSelectCustom className={`input-group mb-2 ${className}`}>
      <select
        className={`form-select ${isValid ? 'is-invalid' : ''}`}
        value={valueInput}
        name={name}
        onChange={(e) => setData(e.target.value)}
        aria-describedby={ariaDescribedby}
        ref={refInput}
        disabled={isDisabled}
      >
        <option hidden>{placeholder}</option>
        {options && options.map((option) => (
          <option
            value={option.id}
            key={option.id}
          >
            {option.value}
          </option>
        ))}
      </select>
    </DivSelectCustom>
  )

}

// obj: { label: 'value', value: 'value'}
export function InputSelectSearch({ options, placeholder, onChange }) {

  return (
    <DivSelectSearch className={`input-group mb-2`}>
      <Select 
        options={options} 
        placeholder={placeholder}
        noOptionsMessage={() => 'Nenhuma opção disponível'}
        loadingMessage={() => 'Carregando...'}
        onChange={onChange}
      />
    </DivSelectSearch>
  )

}