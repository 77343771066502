import { styled } from 'styled-components';

/* HOME PAGE */
export const DivHomePage = styled.div`

    /* Banner */
    .section-banner {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        min-height: 100svh;
        margin-top: -52.5px;
        background-image: url('./image/banner-home-page.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .area-banner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .show-second-text {
        display: none;
    }

    .register-button-responsive {
        margin-top: 20px;
        display: none;
    }

    .register-button-responsive button {
        background-color: ${props => props.theme.colors.senary};
    }

    .register-button-responsive button:hover {
        background-color: ${props => props.theme.colors.quinary};
    }

    .line-break {
        margin-bottom: 20px;
    }

    @media screen and (max-width: 770px) {

    .register-button-responsive {
        display: block;
    }


    .section-banner {
        padding-top: 80px;
    }

    .area-banner {
        justify-content: center;
        flex-direction: column;
    }

    .show-first-text {
        display: none;
    }

    .show-second-text {
        margin-top: 100px;
        margin-bottom: 50px;
        display: block;
    }

}

    /* Area About Us */

    .section-about{
        width: 100%;
        min-height: 200px;
    }

    .section-about {
        padding: 30px 0px;
        background-color: ${props => props.theme.colors.tertiary};
        background-image: url('./image/image-about.png');
        background-repeat: no-repeat;
        background-position: right top;
        background-size: auto 100%;
    }

    .section-about h3 {
        margin: 0px;
        padding: 0px;
        font-size: 1.5em;
        color: ${props => props.theme.colors.background};
        font-weight: 700;
        margin-bottom: 30px;
    }

    .text-about {
        width: 100%;
        max-width: 500px;
    }

    .text-about p {
        color: ${props => props.theme.colors.background};
        font-size: 1.1em;
    }

    @media screen and (max-width: 995px) {
        
        .text-about {
            max-width: 100%;
        }

        .section-about {
            background-image: none;
        }

    }

`
/* LOGIN */
export const DivLogin = styled.div`
    background-color: ${props => props.theme.colors.background};
    max-width: 400px;
    max-height: 650px;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 30px;

    .area-img {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        width: 80%;
        margin-bottom: 10px;
    }

    p {
        margin: 0px;
        line-height: 1;
        color: ${props => props.theme.colors.primary};
    }

    p:nth-child(1) {
        font-family: "Jura", sans-serif;
        font-size: 3em;
    }

    p:nth-child(2) {
        font-family: "Russo One";
        margin-bottom: 15px;
        font-size: 3em;
    }
    
    .forgot-password {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 15px;
    }

    .message-custom {
        margin-bottom: 10px;
    }

    .forgot-password a {
        text-decoration: none;
        color: ${props => props.theme.colors.secondary};
        font-weight: 600;
        font-size: .9em;
    }

    .custom-eye {
        top: 40%;
        margin-right: 20px;
    }

    @media screen and (max-width: 1000px) {
        p:nth-child(1), p:nth-child(2) {
            font-size: 2.3em;
        }
    }

    @media screen and (max-width: 320px) {
        p:nth-child(1), p:nth-child(2) {
            font-size: 1.7em;
        }
    }

`

/* TEXT BANNER */
export const DivTextBanner = styled.div`
    font-size: 14px;
    margin-right: 10px;

    span {
        background-color: transparent;
    }

    p span {
        display: inline-block;
        font-size: 6em;
        color: ${props => props.theme.colors.background};
        line-height: 1;
        margin-bottom: 10px;
    }

    .line {
        display: inline-block;
        border: none;
        background-color: ${props => props.theme.colors.background};
        opacity: 1;
        height: 4px;
        width: 200px;
        vertical-align: middle;
    }

    p {
        font-size: 14px;
    }

    p span:nth-child(1) {
        font-family: "Karma", sans-serif;
        font-size: 5.9em;
    }

    p span:nth-child(3) {
        font-family: "Karma", sans-serif;
        font-size: 5.9em;
    }

    p span:nth-child(5) {
        font-family: "Lexend", sans-serif;
        font-size: 5.8em;
        margin-bottom: 60px;
    }

    .help-text {
        color: ${props => props.theme.colors.background};
        font-size: 3.2em;
        font-family: "Lexend", sans-serif;
    }

    .btn-register {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn-custom-register {
        width: 200px;
    }

    // Responsividade
    @media screen and (max-width: 1400px) {
        font-size: 13px;

        p {
            font-size: 13px;
        }

        .line  {
            width: 170px;
        }

    }

    @media screen and (max-width: 1300px) {
        font-size: 12px;

        p {
            font-size: 12px;
        }

        .line  {
            width: 140px;
        }
    }

    @media screen and (max-width: 1200px) {
        font-size: 10px;

        p {
            font-size: 10px;
        }
    }

    @media screen and (max-width: 510px) {
        font-size: 9px;

        .line  {
            width: 110px;
            height: 3px;
        }

        p {
            font-size: 8px;
        }
    }

    @media screen and (max-width: 510px) {
        font-size: 7.3px;

        .line  {
            width: 100px;
            height: 3px;
        }

        p {
            font-size: 7.3px;
        }
    }

    @media screen and (max-width: 320px) {
        font-size: 5px;

        .line  {
            width: 75px;
            height: 3px;
        }

        p {
            font-size: 5px;
        }

        .btn-text-register{
            font-size: 1.2em;
        }
    }
`