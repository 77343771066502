import { useState, useRef, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useSelector, useDispatch } from "react-redux";

// Styles
import { DivPostRegister, DivLoading } from './styles';

// Components
import { Input, TextArea, FileInput, WarningInput, InputSelect } from '../../components/Input';
import { Button } from '../../components/Button'
import { Post } from '../../components/Post';

// Service
import FeedService from '../../services/FeedService';

// Reduxe
import { getPostsIntitution, reset } from '../../slices/postsSlices';

export function PostRegister() {

  const dispatch = useDispatch()

  const { loadingPosts, posts } = useSelector((state) => state.posts);
  const { user } = useSelector((state) => state.auth);

  const [ nameInstitution, setNameInstitution ] = useState(user.userType.name ? user.userType.name : '')

  const [ title, setTitle ] = useState('')
  const [ description, setDescription ] = useState('')
  const [ image, setImage ] = useState('')
  const [ typePost, setTypePost ] = useState('')

  const [ isTitleFilled, setIsTitleFilled ] = useState(false)
  const [ isDescriptionFilled, setIsDescriptionFilled ] = useState(false)
  const [ isImageFilled, setIsImageFilled ] = useState(false)
  const [ isTypePost, setIsTypePost ] = useState(false)

  const titleRef = useRef(null)
  const descriptionRef = useRef(null)
  const imageRef = useRef(null)
  const typePostRef = useRef(null)

  const [ error, setError ] = useState('')

  useEffect(() => {

    dispatch(getPostsIntitution(nameInstitution));

    dispatch(reset());

  }, [])

  const handleRegisterPost = async (e) => {
      e.preventDefault()

      setError('')

      !title ? setIsTitleFilled(true) : setIsTitleFilled(false)
      if (!title) return titleRef.current.focus();

      !description ? setIsDescriptionFilled(true) : setIsDescriptionFilled(false)
      if (!description) return descriptionRef.current.focus();

      try {

          const formData = new FormData();
          formData.append('image', image)

          const data = {
              is_event: true, 
              event_date: null,
              event_time: null,
              event_location: null,
              title,
              description,
              formData
          }

          const responsePost = await FeedService.registerPost(data)

      }catch(err) {
          console.log(err)
      }
  }

  function handleFileChange(e) {
      setError('')
      setIsImageFilled('')

      const file = e.target.files[0];

      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
          setError('Arquivo precisa ser JPGE ou PNG!')
          setIsImageFilled(true)
          setImage('')
          return
      }

      if (file.size > 5242880) {
          setError('Arquivo precisa ter no máximo 5Mb!')
          setIsImageFilled(true)
          setImage('')
          return
      }

      setImage(file)
  }

  return (
      <DivPostRegister className='container'>
          <div className='area-info-institucion'>
              <div className='img-institucion'>
                  <img src='/image/user-profile-default.png' alt="Imagem Instituição" />
                  <h4>{nameInstitution}</h4>
              </div>
              <div className='area-institucion' style={{ display: 'none' }}>
                  <h5><i className="bi bi-info-circle-fill"></i>Informações:</h5>
                  <div className='info'>
                      <p>    
                          123, Avenida das Indústrias 
                          Galpão 5, Parque Industrial Sul -
                          Jaraguá do Sul / SC
                      </p>
                  </div>
              </div>
              <div className='area-info-post' style={{display: 'none'}}>
                  <h5><i className="bi bi-postcard-fill"></i>Postagens:</h5>
                  <div className='info'>
                      <ul>
                          <li>Total Vagas de Emprego: <br /><span>5</span></li>
                      </ul>
                      <ul>
                          <li>Total Postagens: <br /><span>5</span></li>
                      </ul>
                      <ul>
                          <li>Total Publicações: <br /><span>5</span></li>
                      </ul>
                  </div>
              </div>
              <div className='options' style={{ display: 'none' }}>
                  <h5><i className="bi bi-gear-fill"></i>Opções:</h5>
                  <ul>
                      <li>
                          <HashLink to='/'>Visualizar Solicitações</HashLink>
                      </li>
                  </ul>
              </div>
          </div>
          <div  className='area-register-post'>
            <h3>Cadestre uma postagem</h3>
            <form onSubmit={handleRegisterPost}>
                <InputSelect 
                    name='type-post'
                    valueInput={typePost}
                    setData={setTypePost}
                    placeholder='Tipo Postagem'
                    ariaDescribedby='Tipo Postagem'
                    refInput={typePostRef}
                    options={[
                        {id: 1, value: 'Postagem'},
                        {id: 2, value: 'Vaga Emprego'}
                    ]}
                    isValid={isTypePost}
                />
                <Input 
                    type='text'
                    name='title'
                    valueInput={title}
                    setData={setTitle}
                    placeholder='Título'
                    ariaDescribedby='Título'
                    refInput={titleRef}
                    isValid={isTitleFilled}
                />
                <TextArea 
                    name='description'
                    valueInput={description}
                    setData={setDescription}
                    placeholder='Descrição'
                    ariaDescribedby='Descrição'
                    refInput={descriptionRef}
                    isValid={isDescriptionFilled}
                    rows={8}
                    max={999}
                    min={10}
                />
                <FileInput 
                    name='image'
                    placeholder='Anexar Imagem'
                    ariaDescribedby='Anexar Imagem'
                    refInput={imageRef}
                    isValid={isImageFilled}
                    onChange={handleFileChange}
                    className='btn-file-custom'
                />
                <WarningInput valueWarning={error} />
                <div className='area-btn'>
                    <Button 
                        type={2} 
                        placeholder='Salvar Publicação'
                        ariaDescribedby='Salvar Publicação'
                        className='btn-custom'
                    />
                </div>
            </form>
            <div className='area-posts'>
              {loadingPosts ? (
                <DivLoading>
                  <div className="spinner-border text-primary" role="status"></div>
                </DivLoading>
                ) : (
                    <>
                        { posts.length > 0 && !loadingPosts ? (
                            <Post publications={posts} />
                        ) : (
                            <div className='message-posts'>
                                <p>Nenhuma postagem encontrada!</p>
                            </div>
                        )}
                    </>
                )}
            </div>
          </div>  
      </DivPostRegister>
  )

}