import axios from 'axios';

// Jsons
import listState from '../components/RegisterAddressPcd/listState.json';

const API_URL = process.env.REACT_APP_API_URL;
const API_URL_IBGE = process.env.REACT_APP_API_IBGE;
const API_URL_CEP = process.env.REACT_APP_API_CEP

async function country() {
    try {

        const countryResponste = [
            {
                id: 'BR',
                value: 'Brasil'
            }
        ]

        return countryResponste;
        
    } catch (error) {
        throw { message: "Não foi possível carregar as opções!" };
    }
}

async function cep(cep) {
    try {
        const response = await axios.get(`${API_URL_CEP}/${cep}/json/`);
        if (response.data.erro) throw "Error";
        return response.data;
    } catch (error) {
        throw "CEP informado é inválido!";
    }
}

async function states() {
    try {
        const response = await listState.state
        
        return  response;
    } catch (error) {
        throw { message: "Não foi possível carregar as opções!" };
    }
}

async function citys(UF) {
    try {  
        const response = await axios.get(`${API_URL_IBGE}/${UF}/municipios`);
        const options = response.data.map(municipio => ({
            id: municipio.nome,
            value: municipio.nome
        }));
        
        return  options;
    } catch (error) {
        throw { message: "Não foi possível carregar as opções!" };
    }
}

const AddressService = {
    citys,
    states,
    cep,
    country
};


export default AddressService;