import React, { useState, useRef, useEffect } from 'react';

// Styles
import { DivSearch } from './styles';

export function SearchInput() {

    const [ isSearch, setIsSearch ] = useState(false);
    const [ search, setSearch ] = useState('');

    const searchRef = useRef(null);

    const handleSearch = () => {

        setIsSearch(!isSearch);

    }

    const handleBlurSearch = () => {
        setIsSearch(false);
    }

    useEffect(() => {
        if (isSearch) {
            searchRef.current.focus();
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

    }, [isSearch]);

    const handleSearchText = async (e) => {
        e.preventDefault();

        console.log("pesquisando...")
    }

  return (
    <DivSearch>
        <form className='search-navbar' onSubmit={handleSearchText}>
            <div className='input-group'>
                <input type="text" className="form-control" placeholder="Podemos te ajudar a procurar?" aria-label="Podemos te ajudar a procurar?" aria-describedby="button-addon2" ref={searchRef} onBlur={handleBlurSearch}/>
                <button className="btn btn-custom" type="button"><i className="bi bi-search"></i></button>
            </div>
        </form>
        <button className='button-search' onClick={handleSearch}>
            <i className="bi bi-search"></i>
        </button>
        {isSearch && 
            <>
                <div className='area-search'>
                    <form onSubmit={handleSearchText}>
                        <div className='input-group'>
                            <input type="text" className="form-control" placeholder="Podemos te ajudar a procurar?" aria-label="Podemos te ajudar a procurar?" aria-describedby="button-addon2" ref={searchRef} onBlur={handleBlurSearch}/>
                            <button className="btn btn-outline-secondary" type="button" onClick={handleSearch}>Cancelar</button>
                        </div>
                    </form>
                </div>
                <div className='overlay'></div>
            </>
        }
    </DivSearch>
  );
}