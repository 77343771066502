import { useState, useRef } from 'react'


// Components
import { Button } from '../../components/Button';

//styles
import { SuggestionsArea } from './styles'

// Service
import SuggestionService from '../../services/SuggestionService';

import { SuccessInput, WarningInput } from '../../components/Input'

export function Suggestion() {
    const words = 250;
    
    const [suggestion, setSuggestion] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [totalWords, setTotalWords] = useState(words);

    const suggestionRef = useRef(null)

    const handleSendSuggestion = async () => {
        if (!suggestion) {
            setError("Informe uma sugestão para fazer fazer o envio!");
            suggestionRef.current.focus();
            setTimeout(() => {
                setError('');
            }, 4500);
            return
        }

        try {
            await SuggestionService.RegisterSuggestion(suggestion)
            setError('');
            setSuccess('Sua sugestão foi enviada com sucesso!');
            setSuggestion('');
            setTimeout(() => {
                setSuccess('');
            }, 4500)
        } catch (err) {
            const errorMessage = err.message;
            setError(errorMessage);
            setTimeout(() => {
                setSuccess('');
            }, 4500)

        }

    };


    return (
        <SuggestionsArea className='container'>
            <div className='area-suggestion'>
                <div className='title-area'>
                    <h3 className='text-title'> Sugestões </h3>
                </div>
                <div className='description-area'>
                    <p className='content-descriptions-area'> Gostaríamos de convidar você a compartilhar sua opinião sobre nossa plataforma e sobre a acessibilidade na região. Sua contribuição é extremamente valiosa para entendermos as necessidades e desafios enfrentados para aprimorarmos ainda mais.
                    </p>
                </div>
                <div>
                    <textarea className='input-area'
                        id='input-area-focused'
                        placeholder=' Digite uma Sugestão...'
                        value={suggestion}
                        maxLength={words}
                        onChange={(e) => {
                            const len = e.target.value.length;
                            setSuggestion(e.target.value)
                            setError('');
                            setTotalWords(words-len);

                            
                        }
                        }
                        ref={suggestionRef}
                    />

                    <span className='total-words'>  {`Faltam ${totalWords} caracteres` } </span>
                   
                    <WarningInput valueWarning={error} />
                    <SuccessInput valueSucess={success} />
                    <div className='button-area'>
                        <Button
                            type={2}
                            placeholder='Enviar Sugestão'
                            handleFunction={handleSendSuggestion}
                            classDivButton='btn-custom'
                            ariaDescribedby='Enviar Sugestão'
                        />

                    </div>
                </div>
            </div>
        </SuggestionsArea>
    )
}