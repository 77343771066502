// Validation Email
export function isEmailValid(email) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
}

// Validation Password
export function validatePassword(password, confirmPassword) {

  let message = ""

  if (password.length < 8) {
    message = "A senha deve ter no mínimo 8 caracteres."

    return message;
  }

  if (!/[A-Z]/.test(password)) {
    message = "A senha deve conter pelo menos uma letra maiúscula."

    return message;
  }

  if (!/\d/.test(password)) {
    message = "A senha deve conter pelo menos um número."

    return message;
  }

  if (!/[!@#$%]/.test(password)) {
    message = "A senha deve incluir pelo menos um dos caracteres especiais: @, #, $, %"

    return message;
  }

  if (password !== confirmPassword) {
    message = "As senhas precisam ser iguais!"

    return message
  }

  return message;
}

// Validation Numeric
export function isNumeric(input) {
    return /^\d+$/.test(input);
}

// Validation CPF
export function validateCpf(strCPF) {
    var Soma, i;
    var Resto;
    Soma = 0;
    if (strCPF === "00000000000") return false;

    for (i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

      if ((Resto === 10) || (Resto === 11))  Resto = 0;
      if (Resto !== parseInt(strCPF.substring(9, 10)) ) return false;

    Soma = 0;
      for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto === 10) || (Resto === 11))  Resto = 0;
      if (Resto !== parseInt(strCPF.substring(10, 11) ) ) return false;
      return true;
}

// Validation Atributte have a data
export function isObjectComplete(formData, ignoredKeys = []) {
  for (const key in formData) {

    if (!ignoredKeys.includes(key)) {
      const value = formData[key];

      if ((typeof value === 'string' && value.trim() === '') || (typeof value === 'boolean' && value === false)) {
        return false;
      }
    }
  }
  return true;
}