import { styled } from 'styled-components';

export const SectionSupporter = styled.section`
    max-height: 1000px;
    min-height: 600px;
    padding-top: 60px;

    .container-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 400px;
    }

    .slick-arrow, .slick-next, .slick-next:before, .slick-prev {
        //background-color: #000083;
        background-color: ${props => props.theme.colors.primary};
        display: none !important;
    }

    div.slick-arrow.slick-next {
        display: none !important;
    }

    div[data-role="none"].slick-next {
        display: none !important;
    }

    div.slick-arrow.slick-next {
        display: none !important;
    }

    .area-logo{
        display: flex;
        height: 100%;
        width: 80%;
    }

    .area-logo div {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    .area-logo img {
        width: 80%;
    }

    h3 {
        font-size: 1.5em;
        font-weight: 800;
        //color: #000083;
        color: ${props => props.theme.colors.primary}
    }

    .slick-list{
        width: 100%;
    }

    @media screen and (max-width: 420px) {
        min-height: 400px;

        .container-logo {
            height: 200px;
        }
    }

    @media screen and (max-width: 320px) {
        padding-top: 30px;
    }
`