import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { useSelector } from 'react-redux';

// Styles
import { DivPost, DivHeader, DivBody, DivFooter } from './styles';

export function Post({ publications, isRedirect }) {

  const { user } = useSelector((state) => state.auth);

  return (
    <div style={{flex: 2}}>
      {publications.map((publication, index) => (
        <DivPost key={index}>

            <DivHeader>
              <HashLink to={isRedirect ? `/profile-institution/${publication.cnpj}` : ''}>
                <img 
                  src={publication.profile_photo ? publication.profile_photo : '/image/user-profile-default.png' } 
                  alt={`Imagem relativa a logo de ${publication.institution_name}`} 
                  className='header-img'
                />
              </HashLink>
              <div className='header-info'>
                <HashLink 
                  to={isRedirect ? 
                    user.userType.cnpj === publication.cnpj ? '/post-register' : `/profile-institution/${publication.cnpj}` 
                    : 
                    ''
                  } 
                  className='info-title'
                >
                    {publication.institution_name}
                </HashLink>
                <span className='info-date'>{publication.time}</span>
              </div>
            </DivHeader>

            <DivBody className='text'>
              {publication.is_event == 1 && (
                <div>
                  <span>📍 <b>Local:</b> {publication.event_location}</span> <br /> 
                  <span>🗓️ <b>Data:</b> {publication.event_date}</span> <br />
                  <span>🕒 <b>Horário:</b> {publication.event_time}</span> <br /><br />
                </div>
              )}

              {publication.description}

              {publication.image != null && (
                <div>
                  <br />
                  <img src={publication.image} alt={`Imagem relativa a publicação: ${publication.title}.`}/>
                </div>
              )}
            </DivBody>

            <DivFooter>
              <button type="button" className='btn btn-outline-primary'>
                <i className="bi bi-share-fill"></i>
                <span className='btn-text'>Compartilhar</span>
              </button >
            </DivFooter>

        </DivPost>
      ))}
    </div>
  );
}