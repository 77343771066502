import { styled } from 'styled-components';

export const DivCircleGraph = styled.div`
    min-height: 80vh;
    padding: 0px;

    .titleGraph {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: ${props => props.theme.colors.senary};
        height: 40px;
        padding-left: 20px;
    }

    .titleGraph h4 {
        font-size: 1.3em;
        margin: 0px;
        font-weight: 700;
        color: ${props => props.theme.colors.background};
    }

    .titleGraph {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: ${props => props.theme.colors.senary};
      height: 40px;
      padding-left: 20px;
      border-radius: 10px 10px 0px 0px;
    }

    .container {
      padding: 0px;
    }

    .titleGraph h4 {
        font-size: 1.3em;
        margin: 0px;
        font-weight: 700;
        padding-left: 20px;
        color: #FFFFFF;
    }

    .areaGraph {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        width: 100%;
        min-height: 80vh;
    }

    .graphContainer {
        height: 300px;
        width: 300px;
    }

    .subtitle {
        margin-left: 50px;
        min-width: 200px;
    }

    .subtitle li {
        display: flex;
        justify-content: flex-start;
        margin-top: 5px;
        align-items: center;
        font-size: 1.2em;
        font-weight: 600;
    }

    .subtitle div {
        margin-right: 10px;
        border-radius: 25px;
        height: 20px;
        width: 20px;
    }


    .second-color.subtitle {
        color: ${props => props.theme.colors.background};
    }

    @media screen and (max-width: 991px) {
        .subtitle {
            max-width: 350px;
        }
    }

    @media screen and (max-width: 770px) {
        .areaGraph {
            flex-direction: column;
        }

        .subtitle {
            margin-top: 15px;
            margin-left: 0px;
        }
    }

    @media screen and (max-width: 380px) {
        .titleGraph h4 {
            font-size: 1.1em;
        }

        .subtitle li {
            font-size: .9em;
        }
    }
`