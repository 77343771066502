import apiAuth from '../utils/apiAuth';
import apiBasic from '../utils/apiBasic'

const API_URL = process.env.REACT_APP_API_URL

async function getPosts(cnpj){
    try{
        const api = apiAuth();

        const response = await api.get('/feeds/posts');

        return response;
    } catch(error){
        throw error.response.data;
    }
}

async function getPostsIntitution(nameInstitution){
  try{
      const api = apiAuth();

      const response = await api.get(`/feeds/my-posts/${nameInstitution}`);

      return response;
  } catch(error){
      throw error.response.data;
  }
}

async function registerPost(data) {
    try {

        const api = apiAuth();
        const response = await api.post('/feeds/create-new-post', data);

        return response

    } catch(err) {
        console.log(err)
        throw err
    }
}

async function getHighlightedInstitutions(){
    try{
        const api = apiAuth();
        const response = await api.get('/feeds/ranking');

        return response;
    } catch(error){
        throw error.response.data.error;
    }
}

async function getSuggestions(){
    try{

        const api = apiAuth();

        const response = await api.get(`${API_URL}/suggestions/approved`);

        return response;

    } catch(error){
        throw error.response.data;
    }
}

async function getBannersFeed(){
    try{

        const images = [
            {
                id: 1,
                image: 'image/img11.png',
                description: "Campanha Allcance nos dias 28, 29 e 30 em Jaraguá do Sul."
            },
            {
                id: 2,
                image: 'image/img13.png',
                description: "Campanha AMA pedágio digital nos dias 04 até 29 de abril."
            },
        ]
    
        const imagesMobile = [
            {
                id: 1,
                image: 'image/img1Mobile.png',
                description: "Campanha Allcance nos dias 28, 29 e 30 em Jaraguá do Sul."
            },
            {
                id: 2,
                image: 'image/img2Mobile.png',
                description: "Campanha AMA pedágio digital nos dias 04 até 29 de abril."
            }
        ]

        return { images, imagesMobile };

    } catch(error){
        throw error.response.data;
    }
}

async function getPostsInstitution(institutionName){
    try{
        const api = apiAuth();

        const response = await api.get(`/feeds/my-posts/${institutionName}`);

        return response;
    } catch(error){
        throw error.response.data.error;
    }
}

const FeedService = {
    getPosts,
    getHighlightedInstitutions,
    getSuggestions,
    getBannersFeed,
    registerPost,
    getPostsInstitution,
    getPostsIntitution
};

export default FeedService;