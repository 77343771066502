import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FeedService from "../services/FeedService";

const initialState = {
    errorSuggestion: null,
    successSuggestion: false,
    loadingSuggestion: false,
    suggestion: []
};

export const getSuggestion = createAsyncThunk(
    "auth/getSuggestion",
    async (_, thunkAPI) => {
        try {
            const response = await FeedService.getSuggestions();

            return response.data.suggestions;
        } catch(error) {
            return thunkAPI.rejectWithValue(error.error);
        }
    }
);

const suggestionSlice = createSlice({
    name: "suggestion",
    initialState,
    reducers: {
        reset: (state) => {
            state.errorSuggestion = null;
            state.successSuggestion = false;
            state.loadingSuggestion = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSuggestion.pending, (state) => {
                state.loadingSuggestion = true;
            })
            .addCase(getSuggestion.fulfilled, (state, action) => {
                state.loadingSuggestion = false;
                state.successSuggestion = true;
                state.errorSuggestion = null;
                state.suggestion = action.payload;
            })
            .addCase(getSuggestion.rejected, (state, action) => {
                state.loadingSuggestion = false;
                state.errorSuggestion = action.payload;
            })
    }
});

export const { reset } = suggestionSlice.actions;
export default suggestionSlice.reducer;
