import { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useSelector, useDispatch } from "react-redux";

// Styles
import { DivFeed, DivOptions, PostsAndInstitutions, DivLoading } from './styles';

// Components
import { CarouselFeed } from '../../components/CarouselFeed';
import { Post } from '../../components/Post';
import { HighlightedInstitutions } from '../../components/HighlightedInstitutions';
import { CarouselSuggestions } from '../../components/CarouselSuggestions';

// Reduxe
import { getPosts } from '../../slices/postsSlices';

export function Feed() {

  const dispatch = useDispatch()

  const { loadingPosts, posts } = useSelector((state) => state.posts);

  useEffect(() => {
    dispatch(getPosts());
  }, [])

  return (
    <DivFeed>
      <CarouselFeed />

      <DivOptions className='container'>
        <div className='area-options'>
          <HashLink to="/dashboard" className='option'>
            <img src='/image/data.png' alt="Acessar aba de CENSO" />
            <h4>CENSO</h4>
          </HashLink>
          <HashLink to="/suggestion" className='option internal'>
            <img src='/image/suggestion.png' alt="Sugestões" />
            <h4>SUGESTÕES</h4>
          </HashLink>
          <HashLink to="/post-register" className='option'>
            <img src='/image/supporters.png' alt="Apoiadores" />
            <h4>APOIADORES</h4>
          </HashLink>
        </div>
      </DivOptions>

      <PostsAndInstitutions className='container'>

        {loadingPosts ? (
          <DivLoading>
            <div className="spinner-border text-primary" role="status"></div>
          </DivLoading>
        ) : (
          <>
            {posts.length > 0 && !loadingPosts ? (
              <Post publications={posts} isRedirect={true} />
            ) : (
              <div className='message-posts'>
                <p>Nenhuma postagem encontrada!</p>
              </div>
            )}
          </>
        )}

        <div className='data-right'>
          <HighlightedInstitutions />
          <CarouselSuggestions />
        </div>

      </PostsAndInstitutions >

    </DivFeed>
  )

}