import { useState, useEffect } from 'react';

// Styles
import { DivGraph } from './styles'

// Components
import { CircleGraph } from '../../components/CircleGraph';
import { BarGraph } from '../../components/BarGraph';
import { WarningInput } from '../../components/Input';
import { Loading } from '../../components/Loading';
import { InputSelectCustom } from '../../components/Input';

// Service
import DashboardService from '../../services/DashboardService'

import dataJSON from './arquivo.json'

export function Dashboard() {

    const [data, setData] = useState(dataJSON.data)
    const [erro, setErro] = useState('')

    const [isLoading, setIsLoading] = useState(false);

    const [scrollPosition, setScrollPosition] = useState(0);
    const [showScrollButton, setShowScrollButton] = useState(false);

    useEffect(() => {
      return
        const getDashboardData = async () => {
            try {
                const dataDashboard = await DashboardService.dashboardData()

                setData(dataDashboard)

                setIsLoading(false)
            } catch(err){
                setErro(err)
                setIsLoading(false)
            }
        }
        getDashboardData()
    }, [])

    useEffect(() => {
      const handleScroll = () => {
          const position = window.scrollY;
          setScrollPosition(position);

          if (position > 800) {
              setShowScrollButton(true);
          } else {
              setShowScrollButton(false);
          }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

  return (
    <DivGraph>
        {isLoading ? (
            <Loading />
        ) : (
          data.map((value, index) => (
            value.datas[0].data && value.datas[0].data.length > 6 ? (
              <div key={index} className='graph' style={{backgroundColor: index%2===1 ? 'rgb(36, 22, 65)' : ''}}>
                <BarGraph  
                  title={value.mainTitle} 
                  options={value.options} 
                  data={value.datas} 
                  typeColor={index%2===1 ? 'second-color' : ''} 
                />
              </div>
            ) : (
              <div key={index} className='graph' style={{backgroundColor: index%2===1 ? 'rgb(36, 22, 65)' : ''}}>
                <CircleGraph 
                  title={value.mainTitle} 
                  options={value.options} 
                  data={value.datas} 
                  typeColor={index%2===1 ? 'second-color' : ''}
                />
              </div>
            )
          ))
        )}
        {erro ? (
          <WarningInput valueWarning={erro} flagCenter={1} />
        ) : (
          ''
        )}
        {showScrollButton ? (
          <div className='btn-topper-page' onClick={scrollToTop}>
            Voltar ao início
          </div>
        ): (
          ''
        )
        }
    </DivGraph>
  )
}