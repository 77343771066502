import { styled } from 'styled-components';

export const DivPost = styled.div`
    
  box-shadow: 0px 0px 5px #D8D8D8;
  border-radius: 10px;
  padding: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  @media screen and (max-width: 995px) {
    margin-right: 0px;
  }

`

export const DivHeader = styled.div`
    
    border-bottom: 1px solid #D8D8D8;
    padding: 10px;
    display: flex;

    .header-img{
        width: 75px;
        height: 75px;
        object-fit: cover;
        border-radius: 100%;
        margin: 0 20px 5px 0;
        border: 1px solid #D8D8D8;
    }

    .header-info{
        padding: 10px 0 15px;
        display: flex;
        flex-direction: column;
        
    }

    .info-title{
        font-size: 1.3em;
        font-weight: 800;
        text-decoration: none;
        color: #000000;
        cursor: pointer;
    }

    .info-title:hover {
      color: #000083;
    } 

    .info-date{
        font-size: .9em;
    }

    @media screen and (max-width: 600px) {

        .header-img{
            width: 50px;
            height: 50px;
        }
        
        .info-title{
            font-size: 1.1em;
            font-weight: 700;
        }

        .info-date{
            font-size: .8em;
        }

    }
   
`

export const DivBody = styled.div`

    border-bottom: 1px solid #D8D8D8;
    padding: 10px;

    .text{
        padding-top: 15px;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    @media screen and (max-width: 600px) {
        
        padding: 20px 10px 15px 5px;

    }
    
`

export const DivFooter = styled.div`
    
    padding: 10px;

    .bi{
        margin-right: 10px;
    }

    @media screen and (max-width: 500px) {
        
        .btn-text{
            display: none;
        }

        .bi{
            margin-right: 0;
        }

    }
    
`