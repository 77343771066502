import * as yup from 'yup';

// Utils
import { validateCpf } from '../../utils/validationFunction';
import { validateDate } from '../../utils/utilsFunction';
import validator from 'email-validator';

const schema = yup.object().shape({
  firstName: yup
    .string()
    .max(20, '')
    .min(1, '')
    .required(),
  lastName: yup
    .string()
    .max(20, '')
    .min(1, '')
    .required(),
  phoneNumber: yup
    .string()
    .min(11, '')
    .max(11, '')
    .required(),
  cpf: yup
    .string()
    .min(11, '')
    .max(11, '')
    .required()
    .test('custom-cpf', '', (cpf) => {
      if (!validateCpf(cpf)) {
        throw new yup.ValidationError('CPF Informado é inválido!', cpf, 'cpf');
      }

      return true
    }),
  dateOfBirth: yup
    .string()
    .min(10, '')
    .max(10, '')
    .required()
    .test('custom-dateOfBirth', '', (dateOfBirth) => {

      if (!validateDate(dateOfBirth)) {
        throw new yup.ValidationError('Data informada é inválida!', dateOfBirth, 'dateOfBirth');
      }

      return true
    }),
  maritalStatus: yup
    .number()
    .oneOf([ 1, 2, 3, 4, 5 ], '') // 1 - Solteiro(a) / 2 - Casado(a) / 3 - Divorciado(a) / 4 - Viúvo(a) / 5 - Separado(a)
    .required(),
  gender: yup
    .number()
    .oneOf([ 1, 2 ], '') // 1 - Masculino / 2 - Feminino
    .required(),
  email: yup
    .string()
    .min(1, '')
    .max(50, '')
    .required()
    .email('Digite um e-mail válido!')
    .test('custom-email', '', (email) => {

      if (!validator.validate(email)) {
        throw new yup.ValidationError('Informe um e-mail válido!', email, 'email');
      }

      return true
    }),
  password: yup
    .string()
    .min(1, '')
    .required()
    .test('custom-password', '', (password) => {

      if (password.length < 8) {
        throw new yup.ValidationError('A senha deve ter pelo menos 8 caracteres!', password, 'password');
      }

      return true
    })
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*]).{8,}$/,
      'A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial (!@#$%^&*)!'
    ),
  confirmPassword: yup
    .string()
    .min(1, '')
    .required()
    .oneOf([yup.ref('password')], 'As senhas devem coincidir'),
  isPcd: yup
    .number()
    .oneOf([ 0, 1 ], '') // 1 - Não / 2 - Sim
    .required()
});

const fieldsInOrder = [
  'firstName',
  'lastName',
  'phoneNumber',
  'cpf',
  'dateOfBirth',
  'maritalStatus',
  'gender',
  'email',
  'password',
  'confirmPassword',
  'isPcd'
];

export { schema, fieldsInOrder };