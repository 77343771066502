import { styled } from 'styled-components';

export const DivPostRegister = styled.div`
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    min-height: calc( 100vh - 172px );

    /* Options */
    .area-info-institucion {
        width: 250px;
        margin-right: 20px;
        box-sizing: border-box;
    }

    .img-institucion, .area-institucion, .area-info-post, .options {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 15px 0px;
        background-color: ${props => props.theme.colors.secondBackground};
        border-radius: 25px;
        margin-bottom: 10px;
    }

    .img-institucion img {
        width: 80%;
        border-radius: 50%;
        margin-bottom: 20px;
    }

    .img-institucion h4 {
        font-size: 1.5em;
        font-weight: 700;
        color: ${props => props.theme.colors.primary};
        text-align: center;
    }

    h5 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        font-weight: 700;
        font-size: 1.1em;
        color: ${props => props.theme.colors.primary};
    }

    .info {
        background-color: ${props => props.theme.colors.background};
        border-radius: 25px;
        padding: 10px;
        width: 100%;
    }

    .info p {
        font-size: .9em;
        font-weight: 500;
    }

    .area-institucion, .area-info-post, .options {
        padding: 15px 15px;
    }

    i {
        margin-right: 5px;
        color: ${props => props.theme.colors.primary};
        font-size: 1.1em;
    }

    ul {
        width: 100%;
        margin: 0px;
        padding: 0px;
    }

    ul li {
        width: 100%;
        list-style: none;
        border-bottom: 1px solid ${props => props.theme.colors.secondBackground};
    }

    .area-info-post ul li span {
        margin-left: 10px;
        color: ${props => props.theme.colors.tertiaryBackground};
    }
    
    .options ul li a {
        text-decoration: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        background-color: ${props => props.theme.colors.background};
        color: ${props => props.theme.colors.black};
        padding: 5px 20px;
        font-weight: 600;
        border-radius: 10px;
    }

    .options ul li a:hover {
        background-color: ${props => props.theme.colors.secondBackground};
    }

    /* Posts */
    .area-register-post {
        flex: 4;
    }

    .area-register-post h3 {
        font-weight: 600;
        font-size: 1.5em;
    }

    .area-register-post form {
        width: 100%;
        border-bottom: 3px solid #F8EFEF;
        padding-bottom: 20px;
    }

    .btn-file-custom {
        margin-top: 10px;
    }

    .area-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn-custom {
        margin-top: 30px;
    }

    .area-posts {
      margin-top: 20px;
    }

    .message-posts {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .message-posts p {
        text-align: center;
        font-size: 1.5em;
        font-weight: 600;
        padding: 30px 0px;
        width: 100%;
    }
`

export const DivLoading = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
`