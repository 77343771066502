import { styled } from 'styled-components';

export const Title = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 25px 20px 25px;

    i {
        font-size: 22px;
        color: #000083;
        margin-right: 30px;
        cursor: pointer;
    }

    h3 {
        font-size: 1.5em;
        color: #000083;
        font-weight: 800;
        margin: 0px;

        font-size: 1.5em;
        color:  ${props => props.theme.colors.primary};
        font-weight: 800;
        margin: 0px;
    }

`

export const DivRegister = styled.div`

    border: 1px solid #D8D8D8;
    border-radius: 25px;
    margin: 0 25px;
    padding: 25px;

    .select-custom {
        margin-top: 15px;
    }

    .btn-custom {
        width: 250px;
    }

    .input-number {
        width: 30%;
    }

    @media screen and (max-width: 600px) {

        border: none;
        padding: 0px;
        margin: 10px;

        .btn-custom {
            width: 100%;
        }

    }

    @media screen and (max-width: 420px) {

        .input-number {
            width: 100%;
        }

    }

`