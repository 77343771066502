import { styled } from 'styled-components';

export const DivRegister = styled.div`
    min-height: calc((100vh - 310px));

    .title-form {
        width: 70%;
        font-size: 1.2em;
        color: ${props => props.theme.colors.primary};
        font-weight: 800;
        text-align: center;
        margin: 0px auto;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .select-custom {
        margin-top: 15px;
    }

    .btn-custom {
        width: 100%;
        margin-top: 30px
    }

    .btn-custom-white {
        width: 100%;
        margin-top: 10px;
        margin-right: 5px;
        box-sizing: border-box;
    }

    .btn-white {
        background-color: #${props => props.theme.colors.secondBackground};
    }

    .input-number {
        width: 30%;
    }

    .area-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: 420px) {

        .input-number {
            width: 100%;
        }

    }

`