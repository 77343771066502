import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Private Pages
import { Feed } from '../pages/Feed';
import { Suggestion } from '../pages/Suggestion';
import { UserPanel } from '../pages/UserPanel';
import { Dashboard } from '../pages/Dashboard';
import { PostRegister } from '../pages/PostRegister';
import { ConfigurationInstitution } from '../pages/ConfigurationInstitution';
import { ContentManager } from '../pages/ContentManager';
import { ProfileInstitution } from '../pages/ProfileIntitution/ProfileInstitution';

// Components
import { PrivateNavBar } from '../components/PrivateNavBar';

export function PrivateRoutes() {

    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    const { isAuthenticated } = useSelector((state) => state.auth);
    if (!isAuthenticated) return

    return (
        <Routes>
            <Route path='/' element={<PrivateNavBar />}>
                <Route exact index element={<Feed />} />
                <Route exact path="suggestion" element={<Suggestion />} />
                <Route exact path="user-panel" element={<UserPanel />} />
                <Route exact path="dashboard" element={<Dashboard />} />
                <Route exact path="post-register" element={<PostRegister />} />
                <Route exact path="configuration-institution" element={<ConfigurationInstitution />} />
                <Route exact path="content-manager" element={<ContentManager />} />
                <Route exact path="profile-institution/:idInstitution" element={<ProfileInstitution />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Route>
    </Routes>
  );
}
