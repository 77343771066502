import { styled } from 'styled-components';

/* HOME PAGE FEED */

export const DivFeed = styled.div`
    min-height: 90vh;
    margin-bottom: 30px;
`

export const DivOptions = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 3px solid #F8EFEF;

    .area-options {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 600px;
        margin-bottom: 25px;
    }

    .option {
      flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 5px;
        text-decoration: none;
    }

    .option:hover {
        background-color: ${props => props.theme.colors.secondBackground};
        border-radius: 15px;
        cursor: pointer;
    }

    .option h4 {
        color: ${props => props.theme.colors.senary};
        font-size: 1em;
        font-weight: 700;
    }

    .option img {
        width: 60%;
        height: 100%;
    }

    @media screen and (max-width: 930px) {
        .area-options {
            min-width: 550px;
        }
    }

    @media screen and (max-width: 600px) {
        display: none;
    }

`

export const PostsAndInstitutions = styled.div`
  display: flex;

  .data-right {
    position: sticky;
    top: 25px;
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 250px;
    height: fit-content;
  }

  .message-posts {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .message-posts p {
      text-align: center;
      font-size: 1.5em;
      font-weight: 600;
      padding: 30px 0px;
      width: 100%;
  }

  @media screen and (max-width: 995px) {
      
    flex-direction: column-reverse;

    .data-right {
      position: initial;
      max-width: auto;
    }

  }

  @media screen and (max-width: 600px) {
      
      .message-posts p {
          font-size: 1.2em;
      }

  }
`

export const DivLoading = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
`