import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

// Styles
import { DivConfigInstitution } from './styles'

// Inputs
import { InputSelectSearch, Input, InputMask } from '../../components/Input';

// Redux
import { getAllInstitution, getInstitution } from '../../slices/institutionSlices';

export function ConfigurationInstitution() {

  const { institutionList, institution, loadingInstitution } = useSelector((state) => state.institution);

  const [ institutionName, setInstitutionName ] = useState('')
  const [ institutionSelectedName, setInstitutionSelectedName ] = useState('valueInit')
  const [ cnpj, setCnpj ] = useState('');
  const [ telephone, setTelephone ] = useState('');
  const [ email, setEmail ] = useState('');

  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(getAllInstitution())
  }, [])

  const handleSelectedOption = (option) => {
    setInstitutionName(option.value);
  }

  useEffect(() => {

    console.log(institutionName)
    console.log(institutionSelectedName)

    if (institutionName && institutionName !== institutionSelectedName) {
      dispatch(getInstitution(institutionName));

      console.log("Aqui")

      setInstitutionSelectedName(institutionName)
    }
  }, [ institutionName ])

  useEffect(() => {

    if (!loadingInstitution && institution) {
      setCnpj(institution?.cnpj || '');
      setTelephone(institution?.telephone || '');
      setEmail(institution?.email || '');
    }

  }, [ loadingInstitution, institution ])

  return (
    <DivConfigInstitution className='container'>
      <h3>Configuração Instituição</h3>
      <div className='filter-institution'>
        <h5>Buscar Instituição</h5>
        <div className='area-inputs'>
          <InputSelectSearch 
            options={institutionList.name}
            placeholder='Selecione uma instituição' 
            onChange={handleSelectedOption}
          />
        </div>
      </div>
      <div className='area-inputs-config'>
        <h5 className='title-custom'>Dados Instituição:</h5>
        <div>
          <form>
            <InputMask
              maskInput='00.000.000/0000-00'
              name='cnpj'
              valueInput={cnpj}
              setData={setCnpj}
              placeholder='CNPJ'
              ariaDescribedby='CNPJ'
              isDisabled={true}
            />
            <InputMask
              maskInput='(00) 00000-0000'
              name='telephone'
              valueInput={telephone}
              setData={setTelephone}
              placeholder='Telefone'
              ariaDescribedby='Telefone'
              isDisabled={true}
            />
            <Input
              type='email'
              name='email'
              valueInput={email}
              setData={setEmail}
              placeholder='E-mail'
              ariaDescribedby='E-mail'
              isDisabled={true}
            />
          </form>
        </div>
      </div>
    </DivConfigInstitution>
  )

}