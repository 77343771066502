import { useState, useRef, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

// Styles
import { DivLogin, DivTextBanner, DivHomePage } from './styles';

// Components
import { Carousel } from '../../components/Carousel'
import { Button } from '../../components/Button';

// Input
import { Input, InputPassword, WarningInput } from '../../components/Input';

// Images
import ImageLogin from './Assets/images/image-login.png'

// Utils
import { isEmailValid } from '../../utils/validationFunction';

// Redux
import { login, reset } from '../../slices/authSlices'

export function Login() {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => state.auth);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isEmailFilled, setIsEmailFilled] = useState('')
  const [isPasswordFilled, setIsPasswordFilled] = useState('')

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const handleRegister = (e) => {
    e.preventDefault()

    navigate('/register')
  }

  const handleLogin = (e) => {
    e.preventDefault();

    setIsEmailFilled(false);
    setIsPasswordFilled(false);

    if (!email && !password) {
      setIsEmailFilled(true);
      setIsPasswordFilled(true);
      emailRef.current.focus();
    }

    if (!email) {
      setIsEmailFilled(true);
      return emailRef.current.focus();
    } else {
      if (!isEmailValid(email)) {
        setIsEmailFilled(true);
        return emailRef.current.focus();
      }

      setIsEmailFilled(false);
    }

    !password ? setIsPasswordFilled(true) : setIsPasswordFilled(false);
    if (!password) {
      return passwordRef.current.focus();
    }

    const userData = {
      email,
      password
    }

    if (loading) return

    dispatch(login(userData));
  }

  return (
    <DivHomePage>
      <section className='section-banner'>
        <div className='area-banner container'>
          <DivTextBanner className='show-first-text'>
            <p tabIndex={0}>
              <span>Transformando</span>
              <br />
              <span>dados em <span className="line" /></span>
              <br />
              <span>acessibilidade</span>
            </p>
            <p tabIndex={0} className="white-text help-text">Nos ajude nessa jornada!</p>
            <form onSubmit={handleRegister} className="btn-register">
              <Button
                type={1}
                placeholder='Cadastre-se'
                ariaDescribedby='Cadastre-se'
                className='btn-custom-register'
              />
            </form>
          </DivTextBanner>

          <DivLogin className='area-login'>
            <div className='area-img'>
              <img src={ImageLogin} alt="Bem Vindos" />
            </div>
            <div className="col s12">
              <p tabIndex={0}>OLÁ,</p>
              <p tabIndex={0}>BEM-VINDO!</p>
            </div>
            <form onSubmit={handleLogin} noValidate>
              <Input
                type='email'
                name='email'
                valueInput={email}
                setData={setEmail}
                placeholder='E-mail'
                ariaDescribedby='Email'
                refInput={emailRef}
                isValid={isEmailFilled}
              />
              <InputPassword
                name='password'
                valueInput={password}
                setData={setPassword}
                placeholder='Senha'
                ariaDescribedby='Senha'
                refInput={passwordRef}
                isValid={isPasswordFilled}
              />
              <WarningInput valueWarning={error} />
              <div className="forgot-password">
                <NavLink to="/forgot-password">Esqueceu sua senha?</NavLink>
              </div>
              <Button
                type={2}
                placeholder='Entrar'
                ariaDescribedby='Entrar'
                isLoading={loading}
              />
            </form>
            <div className='register-button-responsive'>
              <div className='line-break'></div>
              <Button
                type={2}
                placeholder='Cadastre-se'
                ariaDescribedby='Cadastre-se'
                handleFunction={handleRegister}
              />
            </div>
          </DivLogin>

          <DivTextBanner className='show-second-text'>
            <p tabIndex={0}>
              <span>Transformando</span>
              <br />
              <span>dados em <span className="line" /></span>
              <br />
              <span>acessibilidade</span>
            </p>
            <p tabIndex={0} className="white-text help-text">Nos ajude nessa jornada!</p>
          </DivTextBanner>
        </div>
      </section>
      <section className='section-about' id='AboutUs'>
        <div className='container'>
          <h3 tabIndex={0} className='container'>Quem Somos</h3>
          <div className='text-about'>
            <p tabIndex={0}>
              Somos uma plataforma dedicada à coleta
              de dados de pessoas com deficiência (PCD),
              o que nos permite mapear e obter informações
              valiosas para instituições de ensino e outras
              organizações interessadas em promover
              acessibilidade e inclusão.
              <br />
              <br />
              Através de nossa plataforma, proporcionamos
              um espaço seguro, fácil e confidencial para
              a coleta de informações e compreensão de
              suas necessidades e demandas,
              possibilitando o desenvolvimento de
              políticas, programas e serviços mais
              adequados e inclusivos.
            </p>
          </div>
        </div>
      </section>
      <Carousel />
    </DivHomePage>
  )

}