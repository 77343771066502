import { styled } from 'styled-components';

export const DivRegister = styled.nav`
    min-height: calc((100vh - 400px));

    .title-form {
        width: 70%;
        font-size: 1.2em;
        color: ${props => props.theme.colors.primary};
        font-weight: 800;
        text-align: center;
        margin: 0px auto;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .btn-custom {
        margin-top: 20px;
    }
`