import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useLocation, Outlet } from 'react-router-dom';

// Styles
import { NavHeader, NavBar } from './styles';

// Images
import WhiteLogo from './Assets/Images/logo-white.png'
import BlueLogo from './Assets/Images/logo-blue.png'

export function PublicNavBar() {

    const location = useLocation();
    const [ pathName, setPathName ] = useState(location.pathname);

    useEffect(() => {
        setPathName(location.pathname);
    }, [location.pathname]);

    if (pathName === '/') {
        return (
            <>
                <NavHeader className="navbar navbar-expand-lg container">
                    <HashLink className="navbar-brand" to='/'>
                        <img src={WhiteLogo} alt="Logo Allcance" />
                    </HashLink>
                    <div className="navbar-options" aria-label="Menu Opções">
                        <HashLink className="nav-item nav-link" smooth to='/#AboutUs'>QUEM SOMOS</HashLink>
                        <HashLink className="nav-item nav-link" smooth to='/#Partners'>PARCEIROS</HashLink>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarOffcanvasLg" aria-controls="Menu Opções" aria-label="Menu Opções">
                        <i className="bi bi-list"></i>
                    </button>
                    <div className="offcanvas offcanvas-end" id="navbarOffcanvasLg" aria-labelledby="Opções">
                        <div className="offcanvas-header">
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Fechar Menu Opções"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <HashLink className="nav-link" aria-current="page" smooth href="/#AboutUs">QUEM SOMOS</HashLink>

                                </li>
                            </ul>
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <HashLink className="nav-link" aria-current="page" smooth href="/#Partners">PARCEIROS</HashLink>

                                </li>
                            </ul>
                            <HashLink to="/" className='logo-allcance'>
                                <img src={BlueLogo} alt="Logo Allcance" />
                            </HashLink>
                        </div>
                    </div>
                </NavHeader>
                <Outlet />
            </>
        )
    } else {
        return (
            <>
                <NavBar>
                    <div className="container">
                        <div className='area-header'>
                            <HashLink className='back-link' to="/">
                                <img src={WhiteLogo} alt="Allcance" />
                            </HashLink>
                            <HashLink className='back-link text-back' to="/" >VOLTAR</HashLink>
                        </div>
                    </div>
                </NavBar>
                <Outlet />
            </>
        )
    }

}