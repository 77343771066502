import { styled } from 'styled-components';

export const DivSearch = styled.div`
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .button-search {
        box-shadow: none;
        background-color: transparent;
        border: none;
        display: none;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, .5);
        opacity: 1;
        transition: opacity 0.3s ease;
        pointer-events: none;
        z-index: 1499;
    }

    i {
        color: ${props => props.theme.colors.background};
        cursor: pointer;
        font-size: 17px;
    }

    .area-search {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 10px;
        box-sizing: border-box;
        position: absolute;
        top: 70px;
        left: 50%;
        transform: translateX(-50%);
        background-color: ${props => props.theme.colors.background};
        width: 50vw;
        height: 70px;
        z-index: 1500;
        border-radius: 5px;
    }

    form {
        width: 100%;
    } 

    .form-control,
    .btn-outline-secondary {
        font-size: 1em;
        height: 50px;
    }

    .input-group {
        width: 100%;
    }

    .search-navbar {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .search-navbar input,
    .search-navbar button {
        height: 25px;
    }

    .search-navbar input {
        width: 35vw;
        border-radius: 15px;
        font-size: .9em;
    }

    .search-navbar button i {
        color: ${props => props.theme.colors.secondPlaceholder};
        font-size: .9em;
    }

    .search-navbar button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 15px;
        background-color: ${props => props.theme.colors.background};
       

    }

    .search-navbar button:hover {
        
        background-color: ${props => props.theme.colors.quartenary};
    }

    @media screen and (max-width: 700px) {
        
        .area-search {
            width: 80vw;
            height: 70px;
        }

        .form-control,
        .btn-outline-secondary {
            font-size: .9em;
        }

    }

    @media screen and (max-width: 650px) {

        .button-search {
            display: block;
        }

        .search-navbar {
            display: none;
        }

        .area-search {
            border-radius: 0px;
            width: 100vw;
            height: 100vh;
            top: 0px;
            background-color: rgba(0, 0, 0, .5);
            opacity: 1;
            transition: opacity 0.3s ease;
        }

        .btn-outline-secondary {   
            background-color: ${props => props.theme.colors.background};
            color: #${props => props.theme.colors.placeholder};
        }

        .btn-outline-secondary:hover {
            background-color: ${props => props.theme.colors.placeholder};
            --bs-btn-hover-color: ${props => props.theme.colors.placeholder};
            color: ${props => props.theme.colors.background};
        
        }

        .input-group {
            margin-top: 70px;
        }

    }
`