import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, createTransform } from 'redux-persist';
import storage from "redux-persist/lib/storage";
import { combineReducers } from 'redux';
import Cookies from 'js-cookie';

// Slices
import authReducer from "./slices/authSlices";
import optionReducer from "./slices/optionSlices";
import addressReduces from './slices/addressSlices'
import postsReduces from "./slices/postsSlices";
import suggestionReduces from "./slices/suggestionSlices";
import institutionReduces from "./slices/institutionSlices";
import bannerReduces from "./slices/bannerSlices";

const authTransform = createTransform(
  (inboundState) => {
    const { isAuthenticated, ...rest } = inboundState;
    return rest;
  },
  (outboundState) => {
    const token = Cookies.get('token');
    return { ...outboundState, isAuthenticated: !!token };
  },
  { whitelist: ['auth'] }
);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
  transforms: [authTransform]
}

const rootReducer = combineReducers({
  auth: authReducer,
  option: optionReducer,
  address: addressReduces,
  posts: postsReduces,  
  suggestion: suggestionReduces,
  institution: institutionReduces,
  banner: bannerReduces
  })


const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
});

export default store;