import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Public Pages
import { Login } from '../pages/Login';
import { Register } from '../pages/Register';
import { ForgotPassword } from '../pages/ForgotPassword';
import { ChangePassword } from '../pages/ChangePassword';

// Components
import { PublicNavBar } from '../components/PublicNavBar/PublicNavBar';

export function PublicRoutes() {

  const { isAuthenticated } = useSelector((state) => state.auth);
  if (isAuthenticated) return

  return (
    <Routes>
        <Route path="/" element={<PublicNavBar />}>
          <Route exact index element={<Login />} />
          <Route exact path="register" element={<Register />} />
          <Route exact path="forgot-password" element={<ForgotPassword />} />
          <Route exact path="change-password" element={<ChangePassword />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
    </Routes>
  );
}
