import React, { useState, useEffect, useContext } from "react";

// Components
import { Button } from "../Button";
import {
  InputRadioButton,
  InputSelect,
  WarningInput,
  SuccessInput,
} from "../Input";

// Styles
import { DivAccessibility, Title } from "./styles";

//Services
import OptionsService from "../../services/OptionsServices";
import ConfigService from "../../services/ConfigService";

// Utils
import { getWidthHeightWindow } from "../../utils/utilsFunction";

// context
import { AuthContext } from "../../context/AuthContext";


export function Accessibility({ handleGoBack }) {
  const [optionsYesOrNo, setOptionsYesOrNo] = useState([]);
  const [optionsColorBlindness, setOptionsColorBlindness] = useState([]);
  const [optionsTextSize, setOptionsTextSize] = useState([]);

  const [singLanguage, setSingLanguage] = useState(0);
  const [textSizeId, setTextSizeId] = useState('');
  const [colorBlindnessId, setColorBlindnessId] = useState('');

  const [sucessMsg, setSucessMsg] = useState('');
  const [error, setError] = useState('');
  const { screenWidth } = getWidthHeightWindow();
  const { authUser } = useContext(AuthContext);

  useEffect(() => {
    const getOptions = async () => {

      try {
        const responseYesOrNo = await OptionsService.optionsYesOrNo();
        setOptionsYesOrNo(responseYesOrNo);

        const config = await OptionsService.getConfigOptions();

        setOptionsTextSize(config.sizes || [] );
        setOptionsColorBlindness(config.colors || []);

      } catch (error) {
        setError(error);
      }
    };

    getOptions();
  }, []);

  const saveChanges = async (e) => {
    e.preventDefault();

    //modifica os dados para enviar à função
    const colorBlindness = optionsColorBlindness[colorBlindnessId].value;
    const textSize = optionsTextSize[textSizeId].value;

    let formData = {
      colorBlindness,
      textSize,
    };

    try {
      const response = await ConfigService.changeAccessibilityConfig(
        formData,
        authUser
      );
      setSucessMsg(response.data.message);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <>
      <Title>
        {screenWidth && screenWidth < 600 && (
          <i
            className="bi bi-arrow-left-circle-fill"
            onClick={handleGoBack}
          ></i>
        )}
        <h3>Acessibilidade</h3>
      </Title>

      <DivAccessibility>
        <form className="inputs-area" onSubmit={saveChanges}>
          <InputRadioButton
            name="singLanguage"
            value={singLanguage}
            valueInput={0}
            setData={setSingLanguage}
            placeholder="Você precisa de ajuda com linguagem de sinais?"
            ariaDescribedby="Você precisa de ajuda com linguagem de sinais?"
            options={optionsYesOrNo}
          />

          <InputSelect
            className="input-select-custom"
            name="isColorblind"
            valueInput={colorBlindnessId}
            setData={setColorBlindnessId}
            placeholder="Possui Daltonismo?"
            ariaDescribedby="Possui Daltonismo?"
            options={optionsColorBlindness}
          />

          <InputSelect
            className="input-select-custom"
            name="textSize"
            valueInput={textSizeId}
            setData={setTextSizeId}
            placeholder="Tamanho do Texto:"
            ariaDescribedby="Tamanho do Texto"
            options={optionsTextSize}
          />

          <WarningInput valueWarning={error} />
          <SuccessInput valueSucess={sucessMsg} />

          <Button
            type={2}
            placeholder="Salvar"
            ariaDescribedby="Salvar"
            className="btn-custom"
            handleFunction={saveChanges}
          />
        </form>
      </DivAccessibility>
    </>
  );
}