// Validate Date
export function validateDate(dateValidation) {

    if (dateValidation.length !== 10) return false

    const [yearParam, monthParam, dayParam] = dateValidation.split('-');

    const dateCurrent = new Date();
    
    var dayCurrent = dateCurrent.getDate();
    var monthCurrent = dateCurrent.getMonth() + 1;
    var yearCurrent = dateCurrent.getFullYear(); 

    dayCurrent = dayCurrent < 10 ? "0" + dayCurrent : dayCurrent;
    monthCurrent = monthCurrent < 10 ? "0" + monthCurrent : monthCurrent;

    const date=`${yearCurrent}-${monthCurrent}-${dayCurrent}`
    const dateParam=`${yearParam}-${monthParam}-${dayParam}`

    if (dateParam > date) {
        return false;
    }

    if (yearParam < 1900 || (monthParam > 12 || monthParam <= 0)) {
        return false;
    }

    return true;
}

// Format Date
// Flag 1 = Formated 00/00/0000 -> 0000-00-00
// Flag 1 = Formated 0000-00-00 -> 00/00/0000
export function formatDate(dateString, flag) {
    if (dateString === '') return ''

    if (dateString.length !== 10) return ''

    if (flag) {
        const [day, month, year] = dateString.split('/');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    } else {
        const [year, month, day] = dateString.split('-');
        return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
    } 
}

// Convert an object's camelCase keys to snake_case keys
export function convertCamelCaseToSnakeCase(inputObjectInCamelCase) {
    const snakeCaseObject = {};

    for (const key in inputObjectInCamelCase) {
        if (Object.prototype.hasOwnProperty.call(inputObjectInCamelCase, key)) {
            const snakeCaseKey = key.replace(/([a-z0-9])([A-Z])/g, '$1_$2').toLowerCase();
            snakeCaseObject[snakeCaseKey] = inputObjectInCamelCase[key];
          }
    }

    return snakeCaseObject;
}

// Get Page Width and Height
export function getWidthHeightWindow() {
    const screenWidth = window.innerWidth;
    const screenheight = window.innerHeight;

    return { screenWidth, screenheight };
}