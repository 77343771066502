import { useState, useRef } from "react";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from 'react-router-dom'

// Inputs
import { InputPassword, WarningInput } from '../../components/Input'
import { Button } from '../../components/Button'

// Styles
import { DivForgotPassword } from "./styles";

// Utils
import { validatePassword } from "../../utils/validationFunction";

export function ChangePassword() {

    const navigate = useNavigate();

    const [ error, setError ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');

    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);

    const [ isPasswordFilled, setIsPasswordFilled ] = useState(false);
    const [ isConfirmPasswordFilled, setIsConfirmPasswordFilled ] = useState(false);

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setError('');

        !password ? setIsPasswordFilled(true) : setIsPasswordFilled(false);
        if (!password) return passwordRef.current.focus();

        !confirmPassword ? setIsConfirmPasswordFilled(true) : setIsConfirmPasswordFilled(false);
        if (!confirmPassword) return confirmPasswordRef.current.focus();

        let message = validatePassword(password, confirmPassword)
        if (message !== "") {
            setError(message);
            setIsConfirmPasswordFilled(true);
            setIsPasswordFilled(true);
            passwordRef.current.focus();
            return  
        }

        navigate('/change-password')
    }

    return (
        <DivForgotPassword>
            <div className="area-email">
                <div className="area-title">
                    <h3>Redefinir senha</h3>
                    <p className="text-email">Vamos redefinir sua senha, informe uma senha nova.</p>
                </div>
                <form onSubmit={handleForgotPassword} noValidate>
                    <InputPassword 
                        name='password'
                        valueInput={password}
                        setData={setPassword}
                        placeholder='Senha'
                        ariaDescribedby='Senha'
                        refInput={passwordRef}
                        isValid={isPasswordFilled}
                    />
                    <InputPassword 
                        name='confirmPassword'
                        valueInput={confirmPassword}
                        setData={setConfirmPassword}
                        placeholder='Confirmar Senha'
                        ariaDescribedby='Confirmar Senha'
                        refInput={confirmPasswordRef}
                        isValid={isConfirmPasswordFilled}
                    />
                    <WarningInput valueWarning={error} />
                    <Button 
                        type={2}
                        placeholder='Redefinir senha'
                        ariaDescribedby='Redefinir senha'
                        classDivButton='btn-custom'
                    />
                </form>
                <p>Lembrou a senha? <HashLink to='/'>Faça Login!</HashLink></p>
            </div>
        </DivForgotPassword>
    )

}