import React, { useState } from 'react';

// Styles
import { DivButton } from './styles';

export function Button({type, placeholder, handleFunction, className, ariaDescribedby, isLoading , classDivButton, btnRef}) {

  let classButton = {className}

  if (type === 1) {
    classButton = `btn btn-primary btn-lg ${className}`
  } 

  if (type === 2) {
    classButton = `btn btn-secondary btn-lg ${className}`
  } 
 

  return (
    <DivButton className={classDivButton}>
      <button 
        className={classButton} 
        aria-label={ariaDescribedby}
        onClick={handleFunction}
        ref={btnRef}
      >
        {isLoading ? (
          <span className="spinner-border spinner-border-sm loading-custom" role="status" aria-hidden="true"></span>
        ):(
          ''
        )}
        {placeholder}
      </button>
    </DivButton>
  );
}