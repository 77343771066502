import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FeedService from "../services/FeedService";
import AuthService from "../services/AuthService";

const initialState = {
  errorInstitution: null,
  successInstitution: false,
  loadingInstitution: false,
  highlightedInstitutions: [],
  institution: null,
  institutionList: []
};

export const getHighlightedInstitutions = createAsyncThunk(
  "auth/getHighlightedInstitutions",
  async (_, thunkAPI) => {
    try {
      const response = await FeedService.getHighlightedInstitutions();

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.error);
    }
  }
);

export const getInstitution = createAsyncThunk(
  "auth/getInstitution",
  async (cnpj, thunkAPI) => {
    try {

      const response = await AuthService.getInstitution(cnpj);

      return response.institution;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.error);
    }
  }
);

export const getAllInstitution = createAsyncThunk(
  "auth/getAllInstitution",
  async (_, thunkAPI) => {
    try {

      const response = await AuthService.getAllInstitution();

      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error.error);
    }
  }
);

const institutionSlice = createSlice({
  name: "institution",
  initialState,
  reducers: {
    reset: (state) => {
      state.errorInstitution = null;
      state.successInstitution = false;
      state.loadingInstitution = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHighlightedInstitutions.pending, (state) => {
        state.loadingInstitution = true;
      })
      .addCase(getHighlightedInstitutions.fulfilled, (state, action) => {
        state.loadingInstitution = false;
        state.successInstitution = true;
        state.errorInstitution = null;
        state.highlightedInstitutions = action.payload;
      })
      .addCase(getHighlightedInstitutions.rejected, (state, action) => {
        state.loadingInstitution = false;
        state.errorInstitution = action.payload;
      })
      .addCase(getInstitution.pending, (state) => {
        state.loadingInstitution = true;
      })
      .addCase(getInstitution.fulfilled, (state, action) => {
        state.loadingInstitution = false;
        state.successInstitution = true;
        state.errorInstitution = null;
        state.institution = action.payload;
      })
      .addCase(getInstitution.rejected, (state, action) => {
        state.loadingInstitution = false;
        state.errorInstitution = action.payload;
      })
      .addCase(getAllInstitution.pending, (state) => {
        state.loadingInstitution = true;
      })
      .addCase(getAllInstitution.fulfilled, (state, action) => {
        state.loadingInstitution = false;
        state.successInstitution = true;
        state.errorInstitution = null;
        state.institutionList = action.payload;
      })
      .addCase(getAllInstitution.rejected, (state, action) => {
        state.loadingInstitution = false;
        state.errorInstitution = action.payload;
      });
  }
});

export const { reset } = institutionSlice.actions;
export default institutionSlice.reducer;
