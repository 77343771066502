import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AddressService from "../services/AddressService";

const initialState = {
  errorAddress: null,
  successAddress: false,
  loadingAddress: false,
  countryOptions: [],
  statesOptions: [],
  citysOptions: [],
  dataCep: '',
  loadingDataCep: false
};

export const getOptionsCountry = createAsyncThunk(
  "auth/optionsCountry",
  async (_, thunkAPI) => {
    try {
      const response = await AddressService.country();

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getOptionsStates = createAsyncThunk(
  "auth/optionsStates",
  async (_, thunkAPI) => {
    try {
      const response = await AddressService.states();

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getOptionsCitys = createAsyncThunk(
  "auth/optionsCitys",
  async (uf, thunkAPI) => {
    try {

      const response = await AddressService.citys(uf);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getCep = createAsyncThunk(
  "auth/cep",
  async (cep, thunkAPI) => {
    try {

      const response = await AddressService.cep(cep);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const addressSlices = createSlice({
  name: "address",
  initialState,
  reducers: {
    reset: (state) => {
      state.errorAddress = null;
      state.successAddress = false;
      state.loadingAddress = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOptionsCountry.pending, (state) => {
        state.loadingAddress = true;
      })
      .addCase(getOptionsCountry.fulfilled, (state, action) => {
        state.loadingAddress = false;
        state.successAddress = true;
        state.errorAddress = null;
        state.countryOptions = action.payload
      })
      .addCase(getOptionsCountry.rejected, (state, action) => {
        state.loadingAddress = false;
        state.errorAddress = action.payload;
      })
      .addCase(getOptionsStates.pending, (state) => {
        state.loadingAddress = true;
      })
      .addCase(getOptionsStates.fulfilled, (state, action) => {
        state.loadingAddress = false;
        state.successAddress = true;
        state.errorAddress = null;
        state.statesOptions = action.payload
      })
      .addCase(getOptionsStates.rejected, (state, action) => {
        state.loadingAddress = false;
        state.errorAddress = action.payload;
      })
      .addCase(getOptionsCitys.pending, (state) => {
        state.loadingAddress = true;
      })
      .addCase(getOptionsCitys.fulfilled, (state, action) => {
        state.loadingAddress = false;
        state.successAddress = true;
        state.errorAddress = null;
        state.citysOptions = action.payload
      })
      .addCase(getOptionsCitys.rejected, (state, action) => {
        state.loadingAddress = false;
        state.errorAddress = action.payload;
      })
      .addCase(getCep.pending, (state) => {
        state.loadingDataCep = true;
      })
      .addCase(getCep.fulfilled, (state, action) => {
        state.loadingDataCep = false;
        state.successAddress = true;
        state.errorAddress = null;
        state.dataCep = action.payload
      })
      .addCase(getCep.rejected, (state, action) => {
        state.loadingDataCep = false;
        state.errorAddress = action.payload;
      })
  }
});

export const { reset } = addressSlices.actions;
export default addressSlices.reducer;