import Stepper from 'react-stepper-horizontal';

export function ShowStep({stepsModal, stageRegister}){

    return(

        <Stepper 
            steps={stepsModal}
            activeStep={stageRegister}
            size={40} 
            defaultColor="#A39DFF" 
            activeColor="#000083" 
            completeBorderColor="#000083"
            completeColor="#000083"
            activeBorderColor="#000083"
            
        />

    )

}