import styled from "styled-components";

export const HeaderDescription = styled.header`
    margin-bottom: 25px;
    h3 {
        color: ${props => props.theme.colors.senary};
        font-size: 25px;
        font-weight: bold;
        padding-bottom: 15px;
        border-bottom: 1px ${props => props.theme.colors.borders} solid;
        width: 99%;
    }
`;

export const TableContents = styled.table`
    width: 100%;
    margin-top: 15px;
    th, td {
        text-align: left;
        padding: 5px;
        font-weight: bold;
    }

    thead tr {
        border-bottom: 3px solid ${props => props.theme.colors.primary};
    }

    tbody tr {
        border-bottom: 2px solid ${props => props.theme.colors.senary};
    }

    tbody tr:hover {
        background-color: ${props => props.theme.colors.secondBackground};
    }

    @media only screen and (max-width: 768px) {
        th, td {
            font-size: 14px;
        }
    }

    .content-column {
        width: 40%;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .content-wrapper {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .show-more-button {
        margin-left: 5px;
        white-space: nowrap;
    }

    @media (max-width: 768px) {
        .content-column {
            width: 60%;
            max-width: 100%;
        }

        .content-wrapper {
            flex-wrap: wrap;
            white-space: normal;
        }

        .show-more-button {
            margin-top: 5px;
            margin-left: 0;
        }
    }

    .date-column {
        width: 15%;
    }

    .user-column {
        width: 20%;
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        background-color: transparent;
        width: 100%;
    }

    .buttons button {
        margin-left: 5px;
    }

    .noSuggestion {
        font-size: 30px;
        font-weight: bold;
        color: ${props => props.theme.colors.primary};
        align-items: center;
        align-content: center;
        text-align: center;
        margin-top: 50px;
    }
`;

export const PaginatorContent = styled.div`
    display: flex;
    justify-content: space-evenly;
    padding: 0 15px;
    background-color: ${props => props.theme.colors.senary};

    button {
        margin: 0 5px;
        font-size: 16px;
        padding: 10px;
        font-weight: bold;
        background: none;
        border: none;
        text-decoration: underline;
        color: ${props => props.theme.colors.background};
    }

    @media only screen and (max-width: 768px) {
        button {
            font-size: 14px;
            padding: 8px;
        }
    }
`;
