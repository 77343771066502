import { styled } from 'styled-components';

export const NavHeader = styled.nav`
    
    display: flex;
    justify-content: space-between;
    background-color: transparent;

    .navbar-toggler, .navbar-collapse, .offcanvas-end {
        display: none;
    }

    .navbar-brand {
        flex: 1;
    }

    .navbar-options {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }

    .navbar-options a {
        //color: #FFFFFF;
        color: ${props => props.theme.colors.background};
        font-weight: 800;
    }

    .navbar-options a:first-child {
        margin-right: 40px;
    }

    .nav-link.active {
        //color: #FFFFFF;
        color:  ${props => props.theme.colors.background}
    }

    i {
        font-size: 1.3em;
    }

    /* Menu Mobile */

    .bi-list {
        //color: #FFFFFF;
        color:  ${props => props.theme.colors.background}
    }

    .navbar-toggler, .btn-close {
        border: none;
        outline: none;
        box-shadow: 0 0 0 0;
    }

    #navbarOffcanvasLg {
        width: 250px;
    }

    .offcanvas-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: calc( 100vh - 45px );
        padding: 0px;
    }

    .offcanvas-body a {
        text-decoration: none;
        font-weight: 700;
        font-size: 1.1em;
        color:  ${props => props.theme.colors.tertiary}
    }

    .offcanvas-body ul {
        width: 100%;
    }

    .offcanvas-body li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        width: 100%;
        padding-left: 20px;
        height: 40px;
    }

    .logo-allcance {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }

    .offcanvas-body li:hover {
        background-color: #DDDDDD;
    }

    .offcanvas-body img {
        width: 60%;
    }

    @media screen and (max-width: 770px) {
        .navbar-options {
            display: none;
        }

        .navbar-toggler, .navbar-collapse, .offcanvas-end {
            display: block;
        }

        .nav-link.active {
            //color: #110897;
            color:  ${props => props.theme.colors.tertiary}
        }
        
    }
`

export const NavBar = styled.nav`
    //background-color: #000083;
    background-color:  ${props => props.theme.colors.primary};
    height: 60px;
    //color: #FFF;
    color:  ${props => props.theme.colors.background};
    width: 100%;

    .area-header {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .area-header img {
        cursor: pointer;
    }

    .back-link {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2em;
        font-weight: 700;
        cursor: pointer;
        text-decoration: none;
        //color: #FFFFFF;
        color:  ${props => props.theme.colors.background};
    }

    @media screen and (max-width: 600px) {
    
        .navbar-brand img {
            width: 70%;
        }

    }  

    @media screen and (max-width: 535px) {
        .text-back {
            display: none;
        }

        .area-header {
            justify-content: center;
        }
    }
`