import { convertCamelCaseToSnakeCase } from '../utils/utilsFunction';
import apiAuth from '../utils/apiAuth';
import apiBasic from '../utils/apiBasic';

async function login(email, password) {
  try {
    const userReq = {
      email,
      password
    }

    const response = await apiBasic.post('/login', userReq);

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}


async function logout() {
  try {
    const api = apiAuth();

    const response = await api.get(`/logout`);

    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function register(dataUser) {
  try {
    let disabilityArray = [];
    if (!Array.isArray(dataUser.pcd)) {
      disabilityArray.push(dataUser.pcd);
      dataUser.pcd = disabilityArray;
    } else {
      const pcdCodes = dataUser.pcd.map(item => item.id)
      dataUser.pcd = pcdCodes;
    }

    const objUser = convertCamelCaseToSnakeCase(dataUser);

    const response = await apiBasic.post('/user-pcd', objUser);

    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function registerPerson(dataUser) {
  try {
    const objUser = convertCamelCaseToSnakeCase(dataUser);

    const response = await apiBasic.post('/user-standar', objUser);

    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function registerInstitutionUser(dataUser) {
  try {
    const objUser = convertCamelCaseToSnakeCase(dataUser);

    const response = await apiBasic.post('/user-admin', objUser);

    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function userValidate(dataUser) {
  try {
    const response = await apiBasic.post('/check-user-register', dataUser);

    return response;
  } catch (error) {
    throw error.response.data.message;
  }
}

async function typeUser() {
  try {
    const api = apiAuth();
    const response = await api.get('/get-user');

    return response.data;
  } catch (error) {
    throw { error: error.response.data.message };
  }
}

async function getInstitution(dataInstitution) {
  try {
    const api = apiAuth();

    const response = await api.get(`/institution/get/${dataInstitution}`);

    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function getAllInstitution() {
  try {
    const api = apiAuth();
    const response = await api.get(`/institution/get-all`);

    const cnpjList = response.data.cnpj.map((cnpj, index) => {
      return {
        label: cnpj,
        value: cnpj
      }
    })

    const nameList = response.data.name.map((name, index) => {
      return {
        label: name,
        value: name
      }
    })
    
    return {
      cnpj: cnpjList,
      name: nameList
    };
  } catch (error) {
    throw error.response.data.error;
  }
}

async function updateAddressUser(dataUser) {
  try {

    const objUser = convertCamelCaseToSnakeCase(dataUser);

    const api = apiAuth();

    const response = await api.put('address/update-address', objUser);

    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

const AuthService = {
  login,
  register,
  registerPerson,
  registerInstitutionUser,
  userValidate,
  typeUser,
  getInstitution,
  logout,
  updateAddressUser,
  getAllInstitution
};

export default AuthService;