import { styled } from 'styled-components';

export const DivGraph = styled.div`
  min-height: calc(100vh - 112.5px);

  .graph {
    padding-top: 20px;
  }

  .btn-topper-page {
    cursor: pointer;
    position: fixed;
    z-index: 2;
    width: 150px;
    height: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    background-color: #000083;
    color: #FFC300;
    right: 30px;
    bottom: 30px;
    border: 1px solid #FFC300;
  }

  .btn-topper-page:hover {
    background-color: #0606A4;
  }

`