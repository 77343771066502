import { styled } from 'styled-components';

export const DivInput = styled.div`
    width: 100%;

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .form-check-input.is-invalid~.form-check-label {
        color: ${props => props.theme.colors.tertiary};
    }

    .form-control.is-invalid, .was-validated .form-control:invalid {
        border-bottom: 1px solid ${props => props.theme.colors.tertiary};;
    }

    input:focus + label {
        color: ${props => props.theme.colors.tertiary} !important;
        font-weight: 700;
    }

    input{
        font-weight: 700;
        outline: none;
        border: none;
        box-shadow: 0 0 0 0;
        border-bottom: 1px solid ${props => props.theme.colors.tertiary};
        border-radius: 0px;
        background-color: ${props => props.theme.colors.background};
    } 

    label, ::placeholder {
        font-weight: 700;
        color: ${props => props.theme.colors.secondPlaceholder};
    }

    input:focus {
        outline: none;
        border: none;
        box-shadow: none !important;
        border-bottom: 2px solid ${props => props.theme.colors.tertiary};;
        background-color: ${props => props.theme.colors.background};
    }
`

export const DivSelect = styled.div`
    width: 100%;

    label, ::placeholder {
        font-weight: 700;
        //color: #999999;
        color: ${props => props.theme.colors.secondBackground};
    }

    select {
        color: #4424D3;
        outline: none;
        border: none;
        box-shadow: none !important;
        //border-bottom: 1px solid #110897;
        border-bottom: 1px solid ${props => props.theme.colors.tertiary};
        border-radius: 0px;
        //background-color: #FFFFFF;
        background-color: ${props => props.theme.colors.background};
        font-weight: 700;
    } 

    .form-select.is-invalid, .was-validated .form-select:invalid {
        //border-bottom: 1px solid #110897;
        border-bottom: 1px solid ${props => props.theme.colors.tertiary};
    }

    .form-select:disabled {
        //background-color: #F3F3F3;
        background-color: ${props => props.theme.colors.secondBackground};
    }

`

export const DivPassword = styled.div`
    width: 100%;

    .form-control.is-invalid, .was-validated .form-control:invalid {
        background-position: calc(98% - 35px);
    }

    .form-check-input.is-invalid~.form-check-label {
       // color: #110897;
        color: ${props => props.theme.colors.tertiary}
    }

    .form-control.is-invalid, .was-validated .form-control:invalid {
        //border-bottom: 1px solid #110897;
        border-bottom: 1px solid ${props => props.theme.colors.tertiary};
    }

    input:focus + label {
        //color: #110897 !important;
        color: ${props => props.theme.colors.tertiary} !important;
        font-weight: 700;
    }

    input{
        font-weight: 700;
        outline: none;
        border: none;
        box-shadow: 0 0 0 0;
        //border-bottom: 1px solid #110897;
        border-bottom: 1px solid ${props => props.theme.colors.tertiary};
        border-radius: 0px;
        //background-color: #FFFFFF;
        background-color: ${props => props.theme.colors.background};
    } 

    input:focus {
        outline: none;
        border: none;
        box-shadow: none !important;
        //border-bottom: 2px solid #110897;
        border-bottom: 2px solid ${props => props.theme.colors.tertiary};
        background-color: #FFFFFF;
        background-color: ${props => props.theme.colors.background};
    }

    .lnr-eye {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: 1.4em;
    }

    .lnr-eye.bi-eye-fill {
        //color: #4424D3;
       color: ${props => props.theme.colors.senary}
    }

    .lnr-eye.bi-eye-slash-fill {
       //color: #4424D3;
        color: ${props => props.theme.colors.senary};
    }

    label, ::placeholder {
        font-weight: 700;
        //color: #999999;
        color: ${props => props.theme.colors.secondPlaceholder};
    }

`

export const DivCheckBox = styled.div`
    width: 100%;

    .form-check-input.is-invalid, .was-validated .form-check-input:invalid {
        //border: 1px solid #110897 !important;
        border: 1px solid ${props => props.theme.colors.tertiary} !important;
    }

    .form-check-input.is-invalid:checked {
        //background-color: #0d6efd !important;
        background-color: ${props => props.theme.colors.septenary};
    }

    .form-check-input.is-invalid:focus {
        //box-shadow: 1px 1px 5px #110897 !important;
        box-shadow: 1px 1px 5px ${props => props.theme.colors.tertiary} !important;;
    }

    .form-check-input.is-invalid~.form-check-label {
        //color: #110897;
        color: ${props => props.theme.colors.tertiary};
    }

    label {
        margin-left: 5px;
        font-size: 1em;
        font-weight: 700;
        //color: #110897;
        color: ${props => props.theme.colors.tertiary} ;
    }
`

export const DivWarning = styled.div`

    width: 100%;
    //color: #FF0000;
    color: ${props => props.theme.colors.danger};
    font-weight: 600;
    font-size: .9em;
    min-height: 20px;

`
export const DivSuccess = styled.div`
    width: 100%;
    //color: #008000;
    color: ${props => props.theme.colors.success};
    font-weight: 600;
    font-size: .9em;
    min-height: 20px;
`;

export const DivRadioButton = styled.div`
    min-height: 70px;
    display: flex;
    flex-direction: column;
    //border-bottom: 1px solid #110897;
    border-bottom: 1px solid ${props => props.theme.colors.tertiary};
    padding-top: 5px;
    width: 100%;

    label {
        font-size: 1em;
        //color: #4424D3;
        color: ${props => props.theme.colors.senary};
        font-weight: 700;
    }

    div {
        margin-top: 8px;
    }

    .area-input {
        display: flex;

    }

    .label-radio {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 30px;

        [type="radio"]:checked+span:after {
            //background-color: #4424D3;
            background-color: ${props => props.theme.colors.senary};
        }

        [type="radio"]:not(:checked)+span:before, [type="radio"]:checked+span:after{
            //border: 1px solid #4424D3;
            border: 1px solid ${props => props.theme.colors.senary};
        }
    }

    .label-radio input {
        margin-right: 10px;
    }

    .form-check-input.is-invalid:checked, .was-validated .form-check-input:invalid:checked {
        //background-color: #4424D3;
        //box-shadow: 1px 1px 5px #4424D3;
        background-color: ${props => props.theme.colors.senary};
        box-shadow: 1px 1px 5px ${props => props.theme.colors.senary};
    }
`

export const DivMultiSelect = styled.div`
    width: 100%;
    min-height: 58px !important;

    .dropdown-heading-value {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .dropdown-container {
        border: none;
        //border-bottom: 1px solid #110897;
        border-bottom: 1px solid ${props => props.theme.colors.tertiary};
        border-radius: 0px;
        outline: none;
        box-shadow: none !important;
        min-height: 45px;
        }

    .dropdown-heading span {
        font-size: 1em;
        //color: #000000;
        color: ${props => props.theme.colors.black};
        font-weight: 700;
        white-space: wrap;
    }
`

export const DivTextArea = styled.div`
    
    textarea {
        outline: none;
        border: none;
        box-shadow: none;
        border-bottom: 1px solid ${props => props.theme.colors.tertiary};
        resize: none;
        border-radius: 0px;
    }

    label, ::placeholder {
        font-weight: 700;
        color: ${props => props.theme.colors.placeholder};
    }

    textarea:focus + label {
        color: ${props => props.theme.colors.tertiary} !important;
        font-weight: 700;
    }

    textarea:focus {
        outline: none;
        border: none;
        box-shadow: none !important;
        border-bottom: 2px solid ${props => props.theme.colors.tertiary};
        background-color: ${props => props.theme.colors.background};
    }
`

export const DivFile = styled.div`

    input {
        outline: none;
        border: none;
        box-shadow: none;
        border-bottom: 1px solid ${props => props.theme.colors.tertiary};
        border-radius: 0px;
    }

    label {
        color: ${props => props.theme.colors.tertiary} !important;
        font-weight: 700;
        margin-bottom: 5px;
    }

    input:focus {
        outline: none;
        border: none;
        box-shadow: none !important;
        border-bottom: 2px solid ${props => props.theme.colors.tertiary};
        background-color: ${props => props.theme.colors.background};
    }
`

export const DivSelectCustom = styled.div`
  width: 100%;
  margin: 10px 0px;

  label, ::placeholder {
      font-weight: 700;
      color: #999999;
  }

  select {
      color: #4424D3;
      outline: none;
      border: none;
      box-shadow: none !important;
      border: 1px solid ${props => props.theme.colors.tertiary};
      border-radius: 0px 0px 10px 10px;
      background-color: ${props => props.theme.colors.background};
      font-weight: 700;
      cursor: pointer;
  } 

  .form-select.is-invalid, .was-validated .form-select:invalid {
      border-bottom: 1px solid ${props => props.theme.colors.tertiary};
  }

  .form-select:disabled {
      background-color: #F3F3F3;
  }
`

export const DivSelectSearch = styled.div`
  width: 100%;

  .css-b62m3t-container {
    width: 100%;
    border: none;
  }
  
  div {
    border: none;
    border-radius: 0px;
  }

  .css-t3ipsp-control {
    outline: none;
    border: none;
    border-bottom: 1px solid ${props => props.theme.colors.tertiary};
    box-shadow: none;
  }

  .css-t3ipsp-control {
    border-bottom: 1px solid ${props => props.theme.colors.tertiary};
  }

  .css-13cymwt-control, .css-13cymwt-control:hover {
    border-bottom: 1px solid ${props => props.theme.colors.tertiary};
  }
`