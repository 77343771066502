import { styled } from 'styled-components';

export const DivButton = styled.div`

    .btn {
        width: 100%;
        font-size: 1em;
    }

    .loading-custom {
        margin-right: 10px;
    }

    .btn-primary {
       // background-color: #FFFFFF;
       background-color: ${props => props.theme.colors.background};
        border: none;
        font-weight: 800;
        //color: #110897;
        color: ${props => props.theme.colors.tertiary};
        //--bs-btn-hover-bg: #000083;
        --bs-btn-hover-bg: ${props => props.theme.colors.primary};
    }

    .btn-primary:hover, .btn.btn-primary.btn-lg:active {
        //background-color: #dbdbdb;
       background-color: ${props => props.theme.colors.tertiaryBackgrond};
        //color: #110897;
        color: ${props => props.theme.colors.tertiary};
        --bs-btn-focus-shadow-rg: none;
    }

    .btn-secondary {
       // background-color: #110897;
        background-color: ${props => props.theme.colors.tertiary};
        border: none;
        font-weight: 800;
        //color: #FFFFFF;
        color: ${props => props.theme.colors.backgound};
        //--bs-btn-hover-bg: #000083;
        --bs-btn-hover-bg: ${props => props.theme.colors.primary}
    }

    .btn-secondary:hover, 
    .btn-secondary.btn-lg:active {
        //background-color: #000083;
        background-color: ${props => props.theme.colors.primary};
        //color: #FFFFFF;
        color: ${props => props.theme.colors.backgound};
        --bs-btn-focus-shadow-rg: none;
    }
`